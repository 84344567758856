import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'

export const AnalyticsLandingPage = (params?: Record<string, string>) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    screen: 'home sortezinha',
    product: 'sortezinha',
    flow: 'prateleira',
    ...params,
  })
}
