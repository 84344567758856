import { Loading } from '@interco/inter-ui/components/Loading'

import { Container, Content, Icon } from './ListDescription.styles'
import { IListDescriptionProps } from './ListDescription.types'

export const ListDescription = ({
  margin,
  iconLeft,
  iconRight,
  borderBottom,
  children,
  withBorder,
  borderColor = 'var(--gray200)',
  isLoading,
  small,
  ...props
}: IListDescriptionProps) => (
  <Container
    $margin={margin}
    $borderBottom={borderBottom}
    $withBorder={withBorder}
    $borderColor={borderColor}
    $isLoading={isLoading}
    small={small}
    {...props}
  >
    <>
      {iconLeft && (
        <Icon $left data-testid="list-desc-left">
          {iconLeft}
        </Icon>
      )}

      <Content>{children}</Content>

      {iconRight &&
        (isLoading ? (
          <Loading width={24} height={24} />
        ) : (
          <Icon $right data-testid="list-desc-right">
            {iconRight}
          </Icon>
        ))}
    </>
  </Container>
)
