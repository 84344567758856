export interface IFaqData {
  id: number
  question: string
  answer?: string
  list?: string[]
  multipleAnswer?: {
    answer: string
    list?: string[]
  }[]
}

export const faqData: IFaqData[] = [
  {
    id: 1,
    question: 'O que é a Sortezinha Inter?',
    answer:
      'A Sortezinha Inter é uma opção de título de capitalização, na modalidade popular, oferecida pela Brasilcap. Ao adquirir uma Sortezinha Inter pelo valor total de R$3,00, você tem chances de ganhar prêmios e ainda resgatar 50% deste valor, atualizado pela TR (Taxa Referencial) ao final da vigência de 12 meses. São 175 prêmios instantâneos de R$100,00 e 6 prêmios de R$7.000,00 em sorteios especiais a cada 200 mil títulos.',
  },
  {
    id: 2,
    question: 'Como comprar uma Sortezinha Inter?',
    answer:
      'Para comprar sua Sortezinha Inter, basta acessar a opção "Sortezinha", que aparece para você no menu "Exclusivos Inter". Você também pode adquirir ao realizar uma recarga de celular no nosso Super App, ativando sua Sortezinha durante esse processo. Após a finalização da compra da sua recarga, você poderá acessar sua Sortezinha Inter.',
  },
  {
    id: 3,
    question: 'Como vejo as informações da Sortezinha Inter que comprei?',
    answer:
      'Você pode visualizar todas as informações do seu título acessando a opção “Sortezinha”, que se encontra no menu "Exclusivos Inter".',
  },
  {
    id: 4,
    question: 'Como saber se ganhei o prêmio instantâneo?',
    answer:
      'Basta encontrar três valores iguais de R$ 100,00 na sua Sortezinha Inter! Assim que isso acontecer, você poderá resgatar o prêmio, que será depositado em sua conta Inter. Além disso, você também pode encontrar essa informação em "Sortezinha", que aparece para você no Menu "Exclusivos Inter".',
  },
  {
    id: 5,
    question: 'Quantos números da sorte eu possuo?',
    answer:
      'Cada título da Sortezinha Inter possui um número da sorte, que concorrerá aos seis sorteios especiais.',
  },
  {
    id: 6,
    question: 'Como saber se ganhei em um sorteio?',
    answer:
      'Você concorre aos 6 sorteios bimestrais com o seu número da sorte. Caso seja contemplado em algum sorteio, nós te avisaremos! Você também pode acompanhar tudo sobre os sorteios acessando a opção “Sortezinha”, que se encontra no menu "Exclusivos Inter". ',
  },
  {
    id: 7,
    question: 'Como é realizado o Sorteio Especial?',
    multipleAnswer: [
      {
        answer:
          'Os sorteios bimestrais são realizados ao longo de 12 meses, com base no resultado da Loteria Federal do Brasil (LFB).',
      },
      {
        answer:
          'As datas dos sorteios são definidas com base na data de compra da sua Sortezinha Inter.',
      },
      {
        answer:
          'O primeiro sorteio bimestral ocorrerá no primeiro sábado do mês subsequente à data da sua compra. Após esse primeiro sorteio, você continuará concorrendo no primeiro sábado do 3º, 5º, 7º, 9º e 11º mês subsequente à data da sua compra.',
      },
      {
        answer:
          'Caso o sorteio do concurso da Loteria Federal do Brasil não seja realizado na data prevista, nem na imediata que a substitua, será considerada, para fins dos sorteios bimestrais, a primeira extração subsequente realizada pela Caixa Econômica Federal, desde que não coincidente com uma data já prevista para sorteio.',
      },
      {
        answer:
          'Caso seu número seja contemplado, nós te avisaremos! Para entender mais sobre como é feita a correspondência do número da sorte e o número sorteado pela loteria, acesse as Condições Gerais, no detalhe do seu título de capitalização, no meu “Sortezinha”.',
      },
    ],
  },
  {
    id: 8,
    question: 'Como faço para receber meu prêmio instantâneo?',
    answer:
      'Ao raspar sua Sortezinha Inter, você saberá na hora se foi contemplado e poderá solicitar o resgate. Nós depositaremos em sua conta Inter imediatamente. Além disso, você pode acompanhar todas as informações sobre sorteios acessando a opção “Sortezinha”, que se encontra no menu "Exclusivos Inter".',
  },
  {
    id: 9,
    question: 'Como faço para receber meu prêmio bimestral?',
    answer:
      'Caso você seja contemplado em um dos sorteios bimestrais, a gente te avisa! Assim, você poderá solicitar o resgate e nós depositaremos em sua conta Inter em até 15 dias corridos, a partir da data que você solicitou. Além disso, você pode acompanhar todas as informações sobre sorteios acessando a opção “Sortezinha”, que se encontra no menu "Exclusivos Inter".',
  },
  {
    id: 10,
    question: 'Quais são os meios de comunicação do Inter?',
    answer:
      'Caso você seja contemplado em um dos sorteios bimestrais, a gente te avisa! Assim, você poderá solicitar o resgate e nós depositaremos em sua conta Inter em até 15 dias corridos, a partir da data que você solicitou. Além disso, você pode acompanhar todas as informações sobre sorteios acessando a opção “Sortezinha”, que se encontra no menu "Exclusivos Inter".',
  },
  {
    id: 11,
    question: 'O que são as Condições Gerais?',
    multipleAnswer: [
      {
        answer:
          'As Condições Gerais estabelecem as normas referentes à Sortezinha Inter, incluindo os direitos e obrigações do subscritor/titular e da Sociedade de Capitalização (BrasilCap).',
      },
      {
        answer:
          'Nas Condições Gerais do seu título, você encontrará informações como o plano registrado na SUSEP, a vigência do título, os percentuais de contribuição destinados à capitalização, as regras do sorteio realizado pela Loteria Federal do Brasil, critério de atualização de valores e outras informações.',
      },
    ],
  },
  {
    id: 12,
    question: 'Como vejo as Condições Gerais da Sortezinha Inter?',
    multipleAnswer: [
      {
        answer: '1. Para clientes que possuem uma Sortezinha:',
        list: [
          '- Acesse as Condições Gerais em “Sortezinha”, localizado no meu "Exclusivos Inter"',
          '- Dentro dos detalhes da sua Sortezinha, você encontrará os documentos, incluindo as Condições Gerais.',
        ],
      },
      {
        answer: '2. Para clientes que ainda não possuem a Sortezinha Inter:',
        list: [
          '- Leia as Condições Gerais acessando o link fornecido antes de contratar o produto.',
        ],
      },
    ],
  },
  {
    id: 13,
    question: 'Qual o prazo de vigência da Sortezinha Inter?',
    answer:
      'A vigência da Sortezinha Inter é de 12 meses, e ao final você resgata o cashback correspondente a 50% do valor pago, atualizado pela TR (Taxa Referencial), de forma automática na sua conta Inter.',
  },
  {
    id: 14,
    question: 'Existe alguma restrição de idade para participar da Sortezinha Inter?',
    answer:
      'A venda da Sortezinha Inter está disponível apenas para maiores de 18 anos, completos na data da compra.',
  },
  {
    id: 15,
    question: 'Como posso entrar em contato com o suporte ao cliente?',
    multipleAnswer: [
      {
        answer:
          'Você pode tirar suas dúvidas através do nosso chat no Super App. Além disso, é possível entrar em contato por:',
      },
      {
        answer: 'Central de Relacionamento:',
      },
      {
        answer: '3003 4070 (capitais e regiões metropolitanas)',
      },
      {
        answer: '0800 940 0007 (demais localidades)',
      },
      {
        answer: '0800 979 7099 (deficiente de fala e audição)',
      },
      {
        answer: 'SAC: 0800 940 9999',
      },
      {
        answer: 'Ouvidoria Banco Inter: 0800 940 7772',
      },
      {
        answer: 'Ouvidoria Brasilcap: 0800 729 3478',
      },
    ],
  },
  {
    id: 16,
    question: 'O valor dos prêmios será deduzido de imposto?',
    answer:
      'O valor informado já é o valor líquido que você receberá em caso de contemplação. O valor bruto de cada premiação (sem a dedução de eventuais impostos/taxas) está disponível nas  Condições Gerais do Produto.',
  },
  {
    id: 17,
    question: 'Como faço para cancelar a Sortezinha?',
    answer:
      'Como a Sortezinha é composta por uma premiação instantânea, não há possibilidade de cancelamento.',
  },
  {
    id: 18,
    question: 'No fluxo da recarga: Como vejo as informações da Sortezinha Inter que comprei',
    answer:
      'Você pode visualizar todas as informações do seu título acessando a opção “Sortezinha”, no canto superior direito no menu da Recarga.',
  },
  {
    id: 19,
    question:
      'No fluxo da recarga: Ativei o Recarga Programada, a Sortezinha Inter também será programada?',
    answer:
      'Você pode comprar uma Sortezinha Inter com a Recarga Programada ativada, mas receberá apenas uma Sortezinha Inter. Ela estará disponível logo após a compra da primeira recarga.',
  },
]
