import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import { defaultError } from 'common/mocks/ResponseError'
import BaseBridge from 'config/bridge/BaseBridge'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'

const handleError = (errorDetails: ErrorDetails) => {
  useBoundState.getState().setHasError(errorDetails)
}

export const handleProcessingErrors = (errorStatus: string) => {
  if (errorStatus && errorStatus === 'ERROR_CREDIT') {
    const errorDetails: ErrorDetails = {
      title: 'Solicitação não aprovada',
      subTitle: 'Verifique o limite disponível em seu cartão.',
      route: TypesRoutes.REQUEST_GO_BACK,
    }

    BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_ACESSO, {
      ref_figma: '3',
      screen: 'erro limite',
      flow: 'raspadinha',
      content_action: 'dado carregado',
      action_id: 'solicitação não aprovada',
    })

    handleError(errorDetails)
  }

  if (errorStatus && errorStatus === 'ERROR_DEBIT') {
    const errorDetails: ErrorDetails = {
      title: 'Saldo insuficiente',
      subTitle: 'Não foi possível concluir a solicitação.',
      route: TypesRoutes.REQUEST_GO_BACK,
    }

    BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_ACESSO, {
      ref_figma: '3',
      screen: 'erro saldo',
      flow: 'raspadinha',
      content_action: 'dado carregado',
      action_id: 'saldo insuficiente',
    })

    handleError(errorDetails)
  }

  if (errorStatus && errorStatus === 'ERROR') {
    BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_ACESSO, {
      ref_figma: '6',
      screen: 'erro genérico',
      flow: 'raspadinha',
      content_action: 'dado carregado',
      action_id: 'houve um erro por aqui',
    })

    handleError({ ...defaultError, route: TypesRoutes.REQUEST_GO_BACK })
  }
}
