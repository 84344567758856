import { PageDirections } from 'common/enums/pageDirections'
import { StateCreator } from 'zustand'

import { PageTransitionState } from './types'

export const createPageTransitionSlice: StateCreator<
  PageTransitionState,
  [],
  [],
  PageTransitionState
> = (set) => ({
  direction: PageDirections.FORWARD,

  setPageTransitionDirection: (direction) => set({ direction }),
})
