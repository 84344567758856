import styled, { keyframes } from 'styled-components'

const animatePolygon = keyframes`
  0%{
    transform: scaleY(0);
  }
  75%{
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
`

export const ResultContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 500;
  overflow: scroll;

  display: flex;
  background-color: var(--modal-backdrop-background-color);
  opacity: 0;
  transition: All 0.5s ease-in-out;

  &.animated {
    opacity: 1;
  }
`

export const ResultContent = styled.div`
  position: relative;
  width: 100%;
  margin-top: auto;
  padding: 0 24px 24px;
  overflow: hidden;

  span {
    display: block;
    margin-top: 8px;
  }

  button {
    margin-top: 24px;
    transition-delay: 0.3s;
  }

  h1,
  span,
  button {
    opacity: 0;
    transform: translate3d(0, 200%, 0);
    transition: All 0.5s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 294px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: var(--neutral-theme);
    transition: All 0.5s ease-in-out;
    transform-origin: bottom;
  }

  &.animated {
    &:after {
      animation: ${animatePolygon} 0.5s linear forwards;
    }

    div {
      opacity: 1;
      transform: scale(1);
    }

    svg {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1);
    }

    h1,
    span,
    button {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`
export const Scratchcard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 200px;
  margin: auto;
  margin-bottom: 32px;

  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDE2MCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNDguNDY4IDUuOTIwNjVlLTA2QzE1NC44MzcgNi4xOTcxOGUtMDYgMTYwIDUuMTI4MzcgMTYwIDExLjQ1NDVMMTYwIDE4OC41NDVDMTYwIDE5NC44NzIgMTU0LjgzNyAyMDAgMTQ4LjQ2OCAyMDBIMTA3LjQ3NkMxMDEuODY0IDE5My4xNzMgOTIuMDQxNSAxODguNjQ4IDgwLjg2MTUgMTg4LjY0OEM2OS42ODE2IDE4OC42NDggNTkuODU4NiAxOTMuMTczIDU0LjI0NjkgMjAwSDExLjUzMTVDNS4xNjI4NSAyMDAgLTIuNzgzODRlLTA3IDE5NC44NzIgMCAxODguNTQ2TDEuMTY0NzRlLTA1IDExLjQ1NDVDMS4xOTI1N2UtMDUgNS4xMjgzOCA1LjE2Mjg1IC0yLjc2NTI1ZS0wNyAxMS41MzE1IDBMNTQuMjQ2OSAyLjM2ODI2ZS0wNkM1OS44NTg2IDYuODI3MDggNjkuNjgxNiAxMS4zNTIyIDgwLjg2MTUgMTEuMzUyMkM5Mi4wNDE1IDExLjM1MjIgMTAxLjg2NSA2LjgyNzA4IDEwNy40NzYgNC43MzY1MmUtMDZMMTQ4LjQ2OCA1LjkyMDY1ZS0wNloiIGZpbGw9IiNGQUI1NzMiLz4KPC9zdmc+Cg==')
    no-repeat top left;
  background-size: contain;

  opacity: 0;
  transform: scale(0);
  transition: All 0.5s ease-in-out;
  transform-origin: bottom;

  svg {
    width: 96px;
    height: 114px;
    opacity: 0;
    transition: All 0.5s ease-in-out;
    transform: translate3d(0, 100%, 0) scale(0);
    transition-delay: 0.2s;
  }
`
