import { Container } from './Separator.styles'
import { ISeparatorProps } from './Separator.types'

export const Separator = ({
  type = 'solid',
  color = 'var(--gray200)',
  borderWidth = '8px',
  margin,
  ...props
}: ISeparatorProps) => (
  <Container
    $borderWidth={borderWidth}
    type={type}
    color={color}
    $margin={margin}
    role="separator"
    {...props}
  />
)
