import React from 'react'

import { defaultError } from 'common/mocks/ResponseError'
import BaseBridge from 'config/bridge/BaseBridge'
import { FallbackProps } from 'react-error-boundary'

import { ErrorPage } from './ErrorPage/ErrorPage'

export const FallbackError: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const goBack = () => BaseBridge.requestGoBack()

  const page = (
    <ErrorPage
      title={defaultError.title}
      subTitle={defaultError.subTitle}
      errorIcon={defaultError.icon}
      goHome={goBack}
      done={resetErrorBoundary}
    />
  )

  return <>{page}</>
}
