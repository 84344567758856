import { StateCreator } from 'zustand'

import { ErrorDetails, ErrorState } from './types'

export const createErrorSlice: StateCreator<ErrorState, [], [], ErrorState> = (set) => ({
  hasError: false,
  details: {} as ErrorDetails,

  setHasError: (details) => set(() => ({ hasError: true, details })),
  resetError: () => set(() => ({ hasError: false, details: {} as ErrorDetails })),
})
