import styled from 'styled-components'

// Test de commit

/**
 * Div principal.
 */
export const Container = styled.div<{
  $margin?: string
  $borderBottom?: boolean
  $withBorder?: boolean
  $borderColor?: string
  small?: boolean
  $isLoading?: boolean
}>`
  display: flex;
  align-items: center;
  ${(props) => props.$margin && `margin: ${props.$margin};`}
  ${(props) =>
    props.$borderBottom &&
    `${props.small ? 'padding-bottom: 8px' : 'padding-bottom: 16px'}; border-bottom: 1px solid ${
      props.$borderColor
    }`};
  ${(props) =>
    props.$withBorder &&
    `${props.small ? 'padding: 8px' : 'padding: 16px'}; border-radius: 8px; border: 1px solid ${
      props.$borderColor
    }`};

  ${(props) => props.$isLoading && `pointer-events: none;`}
`

/**
 * Ícones exibidos nas laterais.
 */
export const Icon = styled.div<{
  $left?: boolean
  $right?: boolean
}>`
  display: flex;
  ${(props) => props.$left && `margin-right: 16px;`}
  ${(props) => props.$right && `margin-left: 16px;`}
`

/**
 * Conteúdo central.
 */
export const Content = styled.div`
  width: 100%;
`
