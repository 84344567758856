import styled, { keyframes } from 'styled-components'

/**
 * Animações dos ícones
 */
const AnimatedSuccess = keyframes`
 0% {
    opacity: 0;
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(0, 170, 79, 0.7);
	}

	70% {
    opacity: 1;
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
    opacity: 1;
    transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`
const AnimatedAlert = keyframes`
 0% {
    opacity: 0;
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(255, 199, 0, 0.7);
	}

	70% {
    opacity: 1;
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
    opacity: 1;
    transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`
const AnimatedError = keyframes`
 0% {
    opacity: 0;
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(255, 98, 39, 0.7);
	}

	70% {
    opacity: 1;
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
    opacity: 1;
    transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`

/**
 * Ícones exibidos para as telas de sucesso, erro e alerta
 */
export const Sign = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  border-radius: 50%;
  opacity: 0;
`

export const SuccessSign = styled(Sign)`
  animation: ${AnimatedSuccess} 2s 0.3s forwards;
  background-color: var(--success500);
`
export const AlertSign = styled(Sign)`
  animation: ${AnimatedAlert} 2s 0.3s forwards;
  background-color: var(--alert500);
`
export const ErrorSign = styled(Sign)`
  animation: ${AnimatedError} 2s 0.3s forwards;
  background-color: var(--error500);
`
