import styled, { css } from 'styled-components'

import { VariantProps } from './CustomAccordion'

export const Summary = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Details = styled.div<{ $maxHeightWhenClosed?: string }>`
  max-height: ${({ $maxHeightWhenClosed }) => $maxHeightWhenClosed || '0px'};
  overflow: hidden;
  transition: max-height ease-in-out ${300}ms;
  color: var(--accordion-details-color);
`

export const DetailPanel = styled.div<{ variant: VariantProps }>`
  margin: 16px 0;
  ${({ variant }) => css`
    ${variant === 'containerized' && 'border-top: 1px dashed var(--gray200); padding-top: 12px;'}
  `}
`

export const Container = styled.div<{ variant: VariantProps }>`
  background: var(--accordion-container-background-color);
  border-radius: var(--accordion-container-border-radius);
  ${({ variant }) => css`
    ${variant === 'default'
      ? 'border-bottom: 1px solid var(--gray200); border-radius: 0px;'
      : 'border: 1px solid var(--gray200) ;'}
  `}
`

export const CustomLabelContainer = styled.div`
  cursor: pointer;
`
