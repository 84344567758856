import { useCallback, useEffect } from 'react'

import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { Separator } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import useBoundState from 'store'
import { capitalizeWords, maskCPF } from 'utils/commons'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Grid } from '@interco/inter-ui/components/Grid'
import { Text } from '@interco/inter-ui/components/Text'
import { uuid } from '@interco/lib-util'

import { ShimmerRegistrationForm } from './RegistrationForm.shimmer'
import * as S from './RegistrationForm.styles'

interface IDetails {
  title: string
  value: string
}

export const RegistrationForm = () => {
  const history = useHistory()

  const tickets = useBoundState((state) => state.tickets)
  const registrationForm = useBoundState((state) => state.registrationForm)
  const getRegistrationForm = useBoundState((state) => state.getRegistrationForm)

  const HAS_TICKETS_STATE = Object.keys(tickets).length !== 0

  const handleBack = useCallback(() => {
    HAS_TICKETS_STATE ? history.goBack() : BaseBridge.requestGoBack()
  }, [HAS_TICKETS_STATE, history])

  useNavBar({
    navbarTitle: PageTitles.REGISTRATION_FORM,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
    backListener: handleBack,
  })

  useEffect(() => {
    if (Object.keys(registrationForm).length === 0) {
      getRegistrationForm()
    }
  }, [])

  const personalData: IDetails[] = [
    {
      title: 'Nome Completo:',
      value: capitalizeWords(registrationForm.fullName),
    },
    {
      title: 'Nome social:',
      value: '',
    },
    {
      title: 'CPF:',
      value: maskCPF(registrationForm.cpf),
    },
    {
      title: 'Data de Nascimento:',
      value: registrationForm.birthDate,
    },
    {
      title: 'Endereço:',
      value: capitalizeWords(registrationForm.address),
    },
    {
      title: 'Bairro:',
      value: capitalizeWords(registrationForm.neighborhood),
    },
    {
      title: 'Cidade:',
      value: capitalizeWords(registrationForm.city),
    },
    {
      title: 'Estado:',
      value: registrationForm.state,
    },
    {
      title: 'CEP:',
      value: registrationForm.zipCode,
    },
    {
      title: 'Telefone:',
      value: registrationForm.phone,
    },
  ]

  const capitalizationData: IDetails[] = [
    {
      title: 'Denominação Comercial:',
      value: 'Sortezinha Inter',
    },
    {
      title: 'Modalidade:',
      value: 'POPULAR',
    },
    {
      title: 'Processo SUSEP:',
      value: '15414.642059/2024-61',
    },
    {
      title: 'Prazo de Vigência:',
      value: '12 Meses',
    },
    {
      title: 'Capital Mínimo:',
      value: 'R$10,00',
    },
    {
      title: 'Tamanho da Série:',
      value: '200.000',
    },
    {
      title: 'Valor do título:',
      value: 'R$3,00',
    },
  ]

  const renderPersonal = (
    <Grid data-testid="grid-scratch-cards" templateColumns="repeat(2, 1fr)" gap="8px">
      {personalData.map((item) => (
        <Flex key={uuid()} direction="column" alignItems="flex-start">
          <Text variant="caption-1">{item.title}</Text>
          <Text variant="caption-1" bold colorWeight={500}>
            {item.value}
          </Text>
        </Flex>
      ))}
    </Grid>
  )

  const renderCapitalization = (
    <>
      <Grid data-testid="grid-scratch-cards" templateColumns="repeat(2, 1fr)" gap="8px">
        {capitalizationData.map((item) => (
          <Flex key={uuid()} direction="column" justifyContent="flex-start">
            <Text variant="caption-1">{item.title}</Text>
            <Text variant="caption-1" bold colorWeight={500}>
              {item.value}
            </Text>
          </Flex>
        ))}
      </Grid>

      <Flex direction="column" justifyContent="flex-start" style={{ marginTop: '16px' }}>
        <Text variant="caption-1">Probabilidade de contemplação em cada sorteio:</Text>
        <Text variant="caption-1" bold colorWeight={500}>
          Mod1 – 0,0875% / Mod2 - 0,0005%
        </Text>
      </Flex>

      <Flex direction="column" justifyContent="flex-start" style={{ marginTop: '16px' }}>
        <Text variant="caption-1">Percentual de resgate após o final da vigência:</Text>
        <Text variant="caption-1" bold colorWeight={500}>
          50,0000%
        </Text>
      </Flex>
    </>
  )

  return Object.keys(registrationForm).length === 0 ? (
    <ShimmerRegistrationForm />
  ) : (
    <S.Container>
      <S.Header>
        <Text variant="headline-h3" as="h3" semiBold>
          Título de capitalização
        </Text>
        {renderPersonal}

        <Separator borderWidth="1px" type="dashed" margin="16px 0" />

        <Text variant="caption-1" as="p">
          O título de capitalização disponível para aquisição, com suas respectivas características
          essenciais, está descrito no quadro abaixo:
        </Text>

        {renderCapitalization}
      </S.Header>

      <S.Body>
        <Text variant="body-3" bold colorWeight={500}>
          I - Atualização de Valores
        </Text>
        <S.List>
          <Text variant="caption-1" as="li">
            O capital formado neste título será atualizado pela Taxa Referencial (TR), conforme
            definido na Lei 8177/1991, de 1 de março de 1991.
          </Text>
          <Text variant="caption-1" as="li">
            A Provisão Matemática para Capitalização será constituída por um percentual da
            contribuição única, conforme tabela de quotas presente nas Condições Gerais, atualizada
            mensalmente na data de aniversário, pela Taxa Referencial (TR) e capitalizada à taxa de
            juros do plano, gerando o valor de resgate do Título.
          </Text>
          <Text variant="caption-1" as="li">
            O valor do prêmio de sorteio será colocado à disposição do(s) Titular(es) após a data de
            sua realização e atualizado a partir da data do sorteio até a data do efetivo pagamento
            pelo índice de atualização da Provisão Matemática para Capitalização.
          </Text>
          <Text variant="caption-1" as="li">
            O valor de resgate será atualizado pela Taxa Referencial (TR) a partir da:
          </Text>
          <S.List>
            <Text variant="caption-1" as="li">
              Data do término de sua vigência até a data do efetivo pagamento, nos casos de resgate
              total.
            </Text>
            <Text variant="caption-1" as="li">
              Data de cancelamento ou data de sua solicitação até o efetivo pagamento nos casos de
              cancelamento do Título ou solicitação de resgate antecipado.
            </Text>
          </S.List>
        </S.List>

        <Text variant="body-3" bold colorWeight={500}>
          II - Estou ciente que:
        </Text>
        <S.List>
          <Text variant="caption-1" as="li">
            O aceite desta proposta está vinculado à disponibilidade de séries emitidas pela
            Brasilcap Capitalização S.A. até o momento do pagamento do título;
          </Text>
          <Text variant="caption-1" as="li">
            A vigência do título se inicia na data do 1º pagamento ou pagamento único;
          </Text>
          <Text variant="caption-1" as="li">
            Cabe ao titular manter, rigorosamente, seus dados cadastrais atualizados, principalmente
            o endereço residencial/sede social e de correspondência completos, para que a Brasilcap
            Capitalização S.A. possa efetuar as comunicações legais necessárias;
          </Text>
          <Text variant="caption-1" as="li">
            Ao titular devem ser pagos os valores de sorteio e resgate;
          </Text>
          <Text variant="caption-1" as="li">
            É proibida a venda de Título de Capitalização a menores de dezesseis anos;
          </Text>
          <Text variant="caption-1" as="li">
            A contratação do(s) título(s) de capitalização implica automática adesão às suas
            Condições Gerais;
          </Text>
          <Text variant="caption-1" as="li">
            Os valores de resgate e de sorteio deverão ser pleiteados pelo titular dentro do prazo
            prescricional, conforme legislação vigente;
          </Text>
          <Text variant="caption-1" as="li">
            A aprovação deste título pela SUSEP não implica, por parte da Autarquia, incentivo ou
            recomendação a sua aquisição, representando exclusivamente sua adequação às normas em
            vigor;
          </Text>
          <Text variant="caption-1" as="li">
            A ficha de cadastro é disponibilizada na seção do Sortezinha Inter;
          </Text>
          <Text variant="caption-1" as="li">
            As Condições Gerais deste produto protocolizadas pela sociedade/entidade junto à SUSEP
            poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número
            de processo constante do título de capitalização/ficha de cadastro;
          </Text>
          <Text variant="caption-1" as="li">
            O consumidor poderá consultar a situação cadastral de seu corretor de capitalização no
            sítio eletrônico www.susep.gov.br, por meio do número de seu registro na SUSEP, nome
            completo, CNPJ ou CPF, quando a venda tiver sido intermediada por corretor de
            capitalização.
          </Text>
        </S.List>

        <Text variant="body-3" bold colorWeight={500}>
          III - Através deste instrumento, COMO CLIENTE, declaro
        </Text>
        <S.List>
          <Text variant="caption-1" as="li">
            Para todos os fins e efeitos, que tenho conhecimento das Condições Gerais que regem o(s)
            título(s) de capitalização Sortezinha Inter, da Brasilcap Capitalização S.A.
          </Text>
          <Text variant="caption-1" as="li">
            Informo ainda que me responsabilizo pela veracidade e atualização de todos os dados aqui
            repassados e pela eventual necessidade de atualização de todos os dados aqui repassados.
          </Text>
          <Text variant="caption-1" as="li">
            Estou ciente de que o montante correspondente 14,2% do valor líquido pago à Brasilcap
            Capitalização S/A decorrente da venda do presente produto é referente a intermediação
            realizada pelo(a) INTER DIGITAL CORRETORA E CONSULTORIA DE SEGUROS S.A. (Registro de
            Corretor nº 202037072), não havendo qualquer tipo de exclusividade por parte do
            intermediário para comercialização desse produto e nem participação acionária na
            Brasilcap Capitalização S.A.
          </Text>
        </S.List>
      </S.Body>

      <Separator borderWidth="1px" type="dashed" margin="16px 0" />

      <S.Footer>
        <Text variant="caption-1" bold colorWeight={500}>
          A Brasilcap Capitalização S. A. mantém sua Política de Privacidade e Proteção de Dados
          disponível para consulta em www.brasilcap.com.br.
        </Text>

        <Text variant="caption-1">
          Local e Data: ___________ de ___________ de _______
          <i>(contratação realizada por meios remotos)</i>
        </Text>

        <Text variant="caption-1">
          <strong>Central de Atendimento e SAC da Brasilcap:</strong> 0800 729 0800 <br />
          <strong>Ouvidoria Brasilcap:</strong> 0800 729 3478 <br />
          <strong>Deficiente Auditivo e de Fala:</strong> www.brasilcap.com.br (na seção fale
          conosco)
        </Text>

        <Text variant="caption-1">
          <strong>Empresa de Capitalização:</strong> Brasilcap Capitalização S.A. CNPJ
          15.138.043/0001-05 <br />
          Av. República do Chile, 330/7º andar – Centro, Edifício Ventura – Rio de Janeiro/RJ - CEP:
          20.031-170
        </Text>
      </S.Footer>
    </S.Container>
  )
}
