import { PageDirections } from 'common/enums/pageDirections'
import BaseBridge from 'config/bridge/BaseBridge'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { Icons } from 'utils/icons'

import { AppBar } from '@interco/inter-ui/components/AppBar'

import { ContentNavbar } from './Navbar.styles'

export const Navbar = () => {
  const history = useHistory()

  const title = useBoundState((state) => state.title)
  const actionIcons = useBoundState((state) => state.actionIcons)

  const setPageTransitionDirection = useBoundState((state) => state.setPageTransitionDirection)
  const backListener = useBoundState((state) => state.backListener)
  const backTicName = useBoundState((state) => state.backTicName)
  const setBackTicName = useBoundState((state) => state.setBackTicName)
  const backButtonCallback = useBoundState((state) => state.backButtonCallback)
  const helpButtonCallback = useBoundState((state) => state.helpButtonCallback)

  const onClickedBack = () => {
    BaseBridge.ticWb({
      name: backTicName || 'go_back_btn',
      module: 'capitalizacao',
      action: 'go_back',
      params: {},
    })
    setBackTicName(undefined)

    setPageTransitionDirection(PageDirections.BACKWARD)

    if (backListener) {
      backListener()
    }

    if (backButtonCallback) {
      backButtonCallback()
    }

    setTimeout(() => {
      setPageTransitionDirection(PageDirections.FORWARD)
    }, 550)
  }

  const onClickedFaq = () => {
    if (helpButtonCallback) {
      helpButtonCallback()
    }

    BaseBridge.ticWb({
      name: 'faq_btn',
      module: 'capitalizacao',
      action: 'enter_faq',
      params: {},
    })

    history.push(TypesRoutes.FAQ)
  }

  const renderBackBtn = (
    <Icons.ArrowLeft
      data-testid="back-button"
      color="var(--primary500)"
      width={24}
      height={24}
      onClick={onClickedBack}
    />
  )

  const renderHomeBtn = (
    <Icons.Home
      data-testid="home-button"
      color="var(--primary500)"
      width={24}
      height={24}
      onClick={() => BaseBridge.requestGoBack()}
    />
  )

  const renderFaqBtn = (
    <Icons.Help
      data-testid="faq-button"
      color="var(--primary500)"
      width={24}
      height={24}
      onClick={onClickedFaq}
    />
  )

  const navIcons: { [actionIcons: string]: JSX.Element } = {
    HOME: renderHomeBtn,
    FAQ: renderFaqBtn,
    BACK: renderBackBtn,
  }

  return (
    <ContentNavbar>
      <AppBar
        hideLeftIcon={!actionIcons.leftIcon}
        leftIcon={actionIcons.leftIcon && navIcons[actionIcons.leftIcon]}
        rightIcon={actionIcons.rightIcon && navIcons[actionIcons.rightIcon]}
      >
        {title}
      </AppBar>
    </ContentNavbar>
  )
}
