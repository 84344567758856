import styled from 'styled-components'

export const ScratchContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px dashed #f5a151;
  overflow: hidden;
`

export const ScratchGift = styled.div<{
  $hasFinished: boolean
  $isAwarded: boolean
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 128px;
  height: 128px;
  background-color: var(--auxiliary-coconut-100);
  transition: all 0.5s;

  ${(props) => props.$hasFinished && `filter: grayscale(1);`}
  ${(props) => props.$isAwarded && `filter: none;`}

  svg {
    width: 47px;
    height: 58px;
    margin: 16px 0;
  }
`

export const ScratchCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
`
