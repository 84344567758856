import { Separator } from 'components'
import styled from 'styled-components'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Grid } from '@interco/inter-ui/components/Grid'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import { uuid } from '@interco/lib-util'

const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  padding: 24px;
  overflow: hidden;
`

export const ShimmerRegistrationForm = () => (
  <ShimmerContainer>
    <Flex direction="column" alignItems="flex-start">
      <Skeleton width="60%" height="20px" style={{ marginBottom: '16px' }} />
    </Flex>

    <Grid templateColumns="repeat(2, 1fr)" gap="8px">
      {Array.from({ length: 10 }).map(() => (
        <Flex key={uuid()} direction="column" alignItems="flex-start">
          <Skeleton width="64%" height="16px" style={{ marginBottom: '4px' }} />
          <Skeleton width="80%" height="16px" />
        </Flex>
      ))}
    </Grid>

    <Separator borderWidth="1px" type="dashed" margin="16px 0" />

    <Flex direction="column" alignItems="flex-start">
      <Skeleton width="100%" height="16px" style={{ marginBottom: '4px' }} />
      <Skeleton width="80%" height="16px" style={{ marginBottom: '4px' }} />
      <Skeleton width="16%" height="16px" style={{ marginBottom: '16px' }} />
    </Flex>

    <Grid templateColumns="repeat(2, 1fr)" gap="8px" style={{ marginBottom: '16px' }}>
      {Array.from({ length: 10 }).map(() => (
        <Flex key={uuid()} direction="column" alignItems="flex-start">
          <Skeleton width="64%" height="16px" style={{ marginBottom: '4px' }} />
          <Skeleton width="32%" height="16px" />
        </Flex>
      ))}
    </Grid>

    {Array.from({ length: 2 }).map(() => (
      <Flex key={uuid()} direction="column" alignItems="flex-start">
        <Skeleton width="100%" height="16px" style={{ marginBottom: '4px' }} />
        <Skeleton width="80%" height="16px" style={{ marginBottom: '16px' }} />
      </Flex>
    ))}

    <Flex direction="column" alignItems="flex-start">
      <Skeleton width="64%" height="16px" style={{ marginBottom: '16px' }} />
    </Flex>

    {Array.from({ length: 5 }).map(() => (
      <div key={uuid()}>
        <Flex direction="row" alignItems="center" style={{ marginTop: '16px' }}>
          <Skeleton width="8%" height="16px" />
          <Skeleton width="88%" height="16px" />
        </Flex>
      </div>
    ))}
  </ShimmerContainer>
)
