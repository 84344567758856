import { Fragment, useEffect, useState } from 'react'

import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import { Separator } from 'components'
import { CustomAccordion } from 'components/CustomAccordion/CustomAccordion'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { Icons } from 'utils/icons'

import { uuid } from '@interco/inter-ui'
import { Babi } from '@interco/inter-ui/components/Babi'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { faqData } from './Faq.data'
import * as S from './Faq.styles'
import { AnalyticsFaqBackButton, AnalyticsFaqHeader, AnalyticsTagQuestion } from './Faq.tags'

export const Faq = () => {
  const history = useHistory()

  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([])

  useNavBar({
    navbarTitle: PageTitles.FAQ,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
    backListener: history.goBack,
    backButtonCallback: () => AnalyticsFaqHeader(),
  })

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'faq_screen_loaded',
      module: 'capitalizacao',
      actions: ['enter_faq'],
      params: {},
    })
  }, [])

  const isExpanded = (key: number) => expandedAccordions.includes(key)

  const handleAccordions = (key: number, title: string) => {
    AnalyticsTagQuestion(title)
    isExpanded(key)
      ? setExpandedAccordions([...expandedAccordions.filter((x) => x !== key)])
      : setExpandedAccordions([...expandedAccordions, key])
  }

  const renderList = (list: string[]) => (
    <S.List>
      {list.map((item) => (
        <Text key={uuid()} variant="caption-1" as="li">
          {item}
        </Text>
      ))}
    </S.List>
  )

  const renderMultipleAnswer = (multipleAnswer: { answer: string; list?: string[] }[]) =>
    multipleAnswer.map((item) => (
      <Fragment key={uuid()}>
        <Text variant="caption-1">{item.answer}</Text>

        {item.list && (
          <S.List>
            {item.list.map((listItem) => (
              <Text key={uuid()} variant="caption-1" as="li">
                {listItem}
              </Text>
            ))}
          </S.List>
        )}
      </Fragment>
    ))

  const renderFaqList = faqData.map((item, index) => (
    <CustomAccordion
      key={item.id}
      labelComponent={
        <S.Header data-testid="test-accordion" $expanded={isExpanded(index)}>
          <Text variant="body-3" colorWeight={500} bold>
            {item.question}
          </Text>
          <Icons.ChevronDown width={24} height={24} color="var(--primary500)" />
        </S.Header>
      }
      expanded={isExpanded(index)}
      onClick={() => handleAccordions(index, item.question)}
    >
      {item.answer && <Text variant="caption-1">{item.answer}</Text>}
      {item.list && renderList(item.list)}
      {item.multipleAnswer && renderMultipleAnswer(item.multipleAnswer)}
    </CustomAccordion>
  ))

  const tagAndGoBack = () => {
    AnalyticsFaqBackButton()
    history.goBack()
  }

  const renderFooter = (
    <S.Footer>
      <Babi origin={TagEventsEnum.BABI_T_AJUDA} />

      <Button fullWidth variant="secondary" onClick={tagAndGoBack}>
        Voltar
      </Button>
    </S.Footer>
  )

  return (
    <S.Container>
      <S.Faq>{renderFaqList}</S.Faq>

      <Separator margin="32px 0" color="var(--gray100)" />

      {renderFooter}
    </S.Container>
  )
}
