import { defaultError } from 'common/mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import AfterSalesService from 'service/AfterSalesService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { AfterSalesState, IAfterSalesRequest, IAfterSalesResponse } from './types'

export const createAfterSalesSlice: StateCreator<AfterSalesState, [], [], AfterSalesState> = (
  set,
) => ({
  tickets: {} as IAfterSalesResponse,

  getTickets: async (payload) => {
    useBoundState.setState({ isLoading: true })

    await AfterSalesService.getTickets()
      .then((response) => {
        set({ tickets: { ...response } })

        if (payload) {
          const { onTransmission } = payload as IAfterSalesRequest
          if (onTransmission) {
            const EMPTY_AFTER_SALES =
              response.available.length === 0 &&
              response.active.length === 0 &&
              response.completed.length === 0

            onTransmission(EMPTY_AFTER_SALES)
          }
        }
      })
      .catch((error) => {
        const errorDetails: ErrorDetails = {
          ...defaultError,
          route: TypesRoutes.AFTER_SALES,
        }

        errorHandling(error as Error, 'AfterSalesService.getTickets', errorDetails)
      })
      .finally(() => useBoundState.setState({ isLoading: false }))
  },
})
