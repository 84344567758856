import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const AccordionSection = styled.div`
  padding: 24px 24px 40px;
`

export const Section = styled.div`
  padding: 0 24px;
  overflow: hidden;
`

export const Header = styled.div<{ $expanded: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transition: all 0.35s;
    ${(props) => props.$expanded && `transform: rotate(180deg);`}
  }
`

export const StatusTag = styled.div`
  > div {
    > span {
      display: flex;
      margin: 0;
    }
  }
`

export const AlertWrapper = styled.div`
  width: 100%;
  margin-top: 16px;

  > div {
    align-items: center;
  }
`
