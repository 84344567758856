import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
  overflow: hidden;
`
export const Header = styled.div`
  padding: 24px 24px 0;
  text-align: center;

  > :first-child {
    margin-bottom: 8px;
  }
`
