import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { Svg } from 'assets/svg/svg'
import useBoundState from 'store'

import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import { AnalyticsGameUse } from '../../Game.tags'
import { ScratchCanvas, ScratchContainer, ScratchGift } from './Card.styles'
import { ICardProps } from './Card.types'

export const Card = ({ id, value, image, revealed, width, height }: ICardProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const isAllScratch = useBoundState((state) => state.isAllScratch)
  const currentTicket = useBoundState((state) => state.currentTicket)
  const availableTickets = useBoundState((state) => state.availableTickets)

  const setCurrentTicket = useBoundState((state) => state.setCurrentTicket)
  const setAvailableTickets = useBoundState((state) => state.setAvailableTickets)
  const getScratchTicket = useBoundState((state) => state.getScratchTicket)

  const [isDiscovered, setIsDiscovered] = useState(false)

  const AWARDED = value === currentTicket.prizeAmount
  const PERCENTAGE_STRATH_AREA = 75

  // Função para renderizar o canvas na tela
  const renderCanvas = useCallback(() => {
    const canvas = canvasRef.current
    if (!canvas || revealed) return

    const ctx = canvas.getContext('2d', { willReadFrequently: true })
    if (!ctx) return

    ctx.fillStyle = '#ED821F'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    ctx.font = 'bold 14px Inter'
    ctx.fillStyle = '#fff'
    ctx.textAlign = 'center'
    ctx.fillText('Raspe aqui', canvas.width / 2, 100)

    const img = new Image()

    img.onload = () => {
      const x = (canvas.width - 47) / 2
      ctx.drawImage(img, x, 24, 47, 50)
    }

    img.src = image
  }, [revealed, image])

  // Função para verificar condição de vitória
  const checkConditionToWin = useCallback(
    (percentage: number) => {
      if (percentage >= PERCENTAGE_STRATH_AREA) {
        AnalyticsGameUse(id, `${value}`)

        setIsDiscovered(true)

        const updatedAvailableTickets = availableTickets.map((ticket) =>
          ticket.ticketId === currentTicket.ticketId
            ? {
                ...ticket,
                cardNumbers: ticket.cardNumbers.map((card) =>
                  card.id === id ? { ...card, revealed: true } : card,
                ),
              }
            : ticket,
        )

        const updatedTicket = updatedAvailableTickets.find(
          (ticket) => ticket.ticketId === currentTicket.ticketId,
        )

        if (updatedTicket) {
          setAvailableTickets(updatedAvailableTickets)
          setCurrentTicket(updatedTicket)
        }

        getScratchTicket({ ticketId: currentTicket.ticketId, cardId: id })
      }
    },
    [
      id,
      value,
      availableTickets,
      currentTicket.ticketId,
      setCurrentTicket,
      getScratchTicket,
      setAvailableTickets,
    ],
  )

  // Função para verificar a área "raspada" do canvas premiado
  const checkScratchedArea = useCallback(() => {
    if (isDiscovered) return

    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d', { willReadFrequently: true })
    if (!ctx) return

    const x = 32
    const y = 90
    const widthX = 64
    const heightY = 12

    const imageData = ctx.getImageData(x, y, widthX, heightY)
    const { data } = imageData

    let countTransparent = 0
    const totalPixels = widthX * heightY

    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] === 0) {
        countTransparent += 1
      }
    }

    const percentageTransparent = (countTransparent / totalPixels) * 100

    checkConditionToWin(percentageTransparent)
  }, [checkConditionToWin, isDiscovered])

  // Função para "raspar" o canvas
  const scratchOnTouch = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const canvas = canvasRef.current
      if (!canvas) return

      const ctx = canvas.getContext('2d', { willReadFrequently: true })
      if (!ctx) return

      let clientX = 0
      let clientY = 0

      if (event instanceof TouchEvent && event.touches.length > 0) {
        clientX = event.touches[0].clientX
        clientY = event.touches[0].clientY
      } else if (event instanceof MouseEvent) {
        clientX = event.clientX
        clientY = event.clientY
      }

      const rect = canvas.getBoundingClientRect()
      const x = clientX - rect.left
      const y = clientY - rect.top
      ctx.globalCompositeOperation = 'destination-out'
      ctx.beginPath()
      ctx.arc(x, y, 20, 0, Math.PI * 2, true)
      ctx.fill()

      if ('touches' in event) {
        event.preventDefault()
      }

      checkScratchedArea()
    },
    [checkScratchedArea],
  )

  // Configuração inicial do canvas
  useLayoutEffect(() => {
    renderCanvas()
  }, [renderCanvas])

  // Adicionando e removendo eventos de toque ou mouse
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    const eventTypeStart = supportsTouch ? 'touchstart' : 'mousedown'
    const eventTypeMove = supportsTouch ? 'touchmove' : 'mousemove'
    const eventTypeEnd = supportsTouch ? 'touchend' : 'mouseup'
    const eventTypeCancel = supportsTouch ? 'touchcancel' : 'mouseleave'

    canvas.addEventListener(eventTypeStart, scratchOnTouch)
    canvas.addEventListener(eventTypeMove, scratchOnTouch)
    canvas.addEventListener(eventTypeEnd, scratchOnTouch)
    canvas.addEventListener(eventTypeCancel, scratchOnTouch)

    // TODO: Entender porque o Lint do Inter não aceita o retorno para limpar os eventos
    // eslint-disable-next-line consistent-return
    return () => {
      canvas.removeEventListener(eventTypeStart, scratchOnTouch)
      canvas.removeEventListener(eventTypeMove, scratchOnTouch)
      canvas.removeEventListener(eventTypeEnd, scratchOnTouch)
      canvas.removeEventListener(eventTypeCancel, scratchOnTouch)
    }
  }, [scratchOnTouch])

  return (
    <ScratchContainer data-testid="scratch-container">
      <ScratchGift $hasFinished={isAllScratch} $isAwarded={AWARDED}>
        {Svg.GiftOpen}
        <Text variant="caption-1" bold colorWeight={500}>
          {formatToBRL(value)}
        </Text>
      </ScratchGift>

      <ScratchCanvas id={id} ref={canvasRef} width={width} height={height} />
    </ScratchContainer>
  )
}
