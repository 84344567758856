import { IAfterSalesResponse } from 'store/afterSales/types'

export const hasAnyTicket = (ticketsResponse: IAfterSalesResponse) => {
  if (
    Object.keys(ticketsResponse).length !== 0 &&
    (ticketsResponse.active.length > 0 ||
      ticketsResponse.available.length > 0 ||
      ticketsResponse.completed.length > 0)
  ) {
    return true
  }

  return false
}
