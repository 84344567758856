const Gift = (
  <svg viewBox="0 0 47 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_158_8951)">
      <path
        d="M38.6148 25.1923H8.02124C7.17409 25.1923 6.51251 25.9376 6.59803 26.7946L8.66347 47.4273C8.73769 48.1702 9.35248 48.735 10.0867 48.735H36.7365C37.4755 48.735 38.0928 48.1628 38.1605 47.4142L40.038 26.7815C40.1154 25.9286 39.4555 25.1923 38.614 25.1923H38.6148Z"
        fill="#EA7100"
      />
      <path
        d="M36.7393 49.2816H10.0887C9.07455 49.2816 8.23223 48.5076 8.12977 47.4815L6.06433 26.8487C6.00785 26.2864 6.18939 25.7225 6.56213 25.303C6.93488 24.8836 7.46738 24.6422 8.02327 24.6422H38.6168C39.1694 24.6422 39.6995 24.8803 40.0715 25.2956C40.4434 25.711 40.6282 26.27 40.5773 26.8298L38.6999 47.4626C38.6547 47.9608 38.4296 48.4214 38.0657 48.7579C37.7019 49.0953 37.2315 49.2808 36.7393 49.2808V49.2816ZM8.02327 25.7389C7.76751 25.7389 7.53273 25.8448 7.36168 26.0377C7.19064 26.2306 7.11077 26.4793 7.13658 26.7379L9.20202 47.3706C9.24882 47.8353 9.62963 48.1858 10.0887 48.1858H36.7385C37.2016 48.1858 37.5833 47.832 37.626 47.3624L39.5035 26.7297C39.5269 26.4728 39.4454 26.2257 39.2743 26.0352C39.1033 25.844 38.8693 25.7389 38.6152 25.7389H8.02327Z"
        fill="#64350F"
      />
      <g opacity="0.5">
        <path
          d="M10.4983 47.7654L8.43291 27.1327C8.40709 26.8741 8.48696 26.6254 8.65801 26.4325C8.82905 26.2396 9.06384 26.1337 9.3196 26.1337H39.6912C39.6525 26.0426 39.5993 25.9572 39.5315 25.8817C39.3604 25.6913 39.1265 25.5862 38.8723 25.5862H8.27961C8.02385 25.5862 7.78907 25.6921 7.61803 25.885C7.44698 26.0779 7.3663 26.3266 7.39293 26.5852L9.45837 47.2179C9.50516 47.6825 9.88598 48.033 10.3451 48.033H10.5669C10.5322 47.9501 10.508 47.8607 10.4983 47.7663V47.7654Z"
          fill="white"
        />
      </g>
      <path d="M19.9137 48.5833H27.3049L27.3049 25.0405H19.9137L19.9137 48.5833Z" fill="#FFC78D" />
      <path
        d="M27.3059 49.1292H19.9147C19.617 49.1292 19.3757 48.8837 19.3757 48.5808V25.0381C19.3757 24.7352 19.617 24.4897 19.9147 24.4897H27.3059C27.6036 24.4897 27.8448 24.7352 27.8448 25.0381V48.5808C27.8448 48.8837 27.6036 49.1292 27.3059 49.1292ZM20.4536 48.0333H26.7677V25.5864H20.4536V48.0333Z"
        fill="#64350F"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.11">
        <path
          d="M38.8763 25.0416H36.4244L35.1489 39.0556C35.0262 40.401 34.5421 41.6956 33.7063 42.7455C32.2459 44.5793 29.3446 46.3902 23.5872 46.5822H8.85645L8.92583 47.2767C9.00006 48.0196 9.61485 48.5844 10.349 48.5844H36.9989C37.7379 48.5844 38.3551 48.0122 38.4229 47.2636L40.3003 26.6308C40.3778 25.778 39.7178 25.0416 38.8763 25.0416Z"
          fill="#3E3331"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M14.4408 29.6483L12.341 29.8808C11.8541 29.9347 11.5024 30.3799 11.5554 30.8752L12.369 38.4825C12.4219 38.9779 12.8595 39.3357 13.3464 39.2818L15.4462 39.0493C15.933 38.9954 16.2847 38.5502 16.2318 38.0549L15.4182 30.4476C15.3652 29.9523 14.9276 29.5944 14.4408 29.6483Z"
          fill="white"
        />
      </g>
      <path
        d="M45.2924 49.2844H2.59445C2.27606 49.2844 2.01807 49.0219 2.01807 48.698C2.01807 48.3741 2.27606 48.1116 2.59445 48.1116H45.2933C45.6117 48.1116 45.8697 48.3741 45.8697 48.698C45.8697 49.0219 45.6117 49.2844 45.2933 49.2844H45.2924Z"
        fill="#64350F"
      />
      <path
        d="M44.4705 19.2387L44.1924 24.5705C44.166 25.0699 43.7595 25.4612 43.2668 25.4618L4.41044 25.5394C3.91247 25.5401 3.50302 25.1424 3.48313 24.6385L3.26751 19.131C3.2469 18.5961 3.67005 18.1515 4.19811 18.1528L43.5491 18.2516C44.0789 18.2526 44.4997 18.7033 44.4715 19.2394L44.4705 19.2387Z"
        fill="#EA7100"
      />
      <g opacity="0.5">
        <path
          d="M4.44091 20.3417C4.42074 19.8087 4.84417 19.3653 5.37211 19.3662L44.4506 19.4309L44.4625 19.2106C44.4912 18.6764 44.0708 18.2277 43.5412 18.2271L4.19822 18.1616C3.67027 18.1607 3.24699 18.605 3.26702 19.1371L3.47815 24.6248C3.49764 25.1269 3.90682 25.5236 4.40455 25.5217L4.63993 25.5213L4.44091 20.3417Z"
          fill="white"
        />
      </g>
      <path
        d="M19.7263 18.2987L19.6106 25.4113L28.0379 25.5797L28.1735 18.358L19.7263 18.2987Z"
        fill="#FFC78D"
      />
      <g opacity="0.3">
        <path
          d="M43.5545 18.2639L42.2464 18.2619L42.0236 22.4426C41.9969 22.9404 41.59 23.3307 41.0974 23.3317L3.44323 23.4383L3.49066 24.6649C3.51013 25.1672 3.91932 25.5639 4.41707 25.562L43.2672 25.4519C43.7599 25.4508 44.1665 25.0597 44.1935 24.5628L44.476 19.2485C44.5046 18.7142 44.0843 18.2653 43.5546 18.2648L43.5545 18.2639Z"
          fill="#B24800"
        />
      </g>
      <path
        d="M43.5162 26.0267C43.4353 26.0405 43.3524 26.0475 43.2684 26.0476L4.40996 26.1245C3.59877 26.1262 2.93896 25.4839 2.90627 24.6627L2.69064 19.1545C2.67415 18.735 2.82328 18.3379 3.11136 18.0351C3.39858 17.7325 3.78529 17.5663 4.19954 17.5675L43.5517 17.6663C43.9616 17.6674 44.3578 17.8411 44.6384 18.1419C44.92 18.4434 45.0689 18.8535 45.0471 19.2689L44.769 24.6014C44.7314 25.3254 44.1998 25.9092 43.5161 26.0258L43.5162 26.0267ZM4.13533 18.7414C4.0621 18.7539 3.99455 18.7902 3.94147 18.8454C3.87435 18.9163 3.83973 19.0091 3.84357 19.1069L4.05919 24.6151C4.06701 24.8063 4.22043 24.9567 4.41032 24.9562L43.2679 24.8795C43.4543 24.8787 43.6085 24.7309 43.6186 24.542L43.8967 19.2095C43.9038 19.077 43.84 18.9877 43.8009 18.9464C43.7625 18.9051 43.6777 18.8362 43.5471 18.8354L4.19493 18.7366C4.17478 18.7364 4.15406 18.7382 4.13448 18.7415L4.13533 18.7414Z"
        fill="#64350F"
      />
      <path
        d="M27.7456 26.1272C27.7114 26.133 27.6759 26.1355 27.6407 26.1353C27.3211 26.1302 27.0665 25.864 27.0721 25.5409L27.1894 18.2817C27.195 17.9578 27.4581 17.6995 27.7768 17.7048C28.0965 17.7099 28.3511 17.9761 28.3454 18.2991L28.2281 25.5583C28.2236 25.8456 28.0156 26.0812 27.7456 26.1272Z"
        fill="#64350F"
      />
      <path
        d="M19.8294 26.0453C19.7952 26.0511 19.7596 26.0536 19.7244 26.0534C19.4048 26.0483 19.1502 25.7821 19.1559 25.459L19.2732 18.1998C19.2787 17.8759 19.5418 17.6176 19.8606 17.6228C20.1802 17.628 20.4348 17.8942 20.4291 18.2172L20.3118 25.4764C20.3073 25.7637 20.0993 25.9993 19.8294 26.0453Z"
        fill="#64350F"
      />
      <path
        d="M34.5494 16.1768C34.5494 16.1768 43.2034 17.0012 44.4715 26.8125L43.3627 26.4628C43.0387 26.3606 42.7006 26.5111 42.5385 26.8364C42.2411 27.4361 41.8538 28.6637 41.7764 31.0495C41.7698 31.2459 41.5253 31.2649 41.485 31.0721C40.6236 26.913 37.4667 16.7185 27.2883 17.3049L28.5464 15.2085L34.5485 16.1769L34.5494 16.1768Z"
        fill="#FFC78D"
      />
      <path
        d="M41.7514 31.7804C41.7326 31.7836 41.7147 31.7867 41.6955 31.7874C41.3244 31.8219 40.9974 31.572 40.919 31.1932C40.0514 27.0068 36.9895 17.3306 27.3185 17.8874C27.1044 17.8994 26.9016 17.7914 26.7923 17.6061C26.6831 17.4207 26.6838 17.1881 26.7949 17.0021L28.0529 14.9057C28.176 14.7017 28.4084 14.5925 28.6401 14.6298L34.6259 15.5957C34.8734 15.6223 37.0229 15.887 39.3365 17.3145C40.7539 18.1888 41.9402 19.3204 42.8621 20.6777C44.0082 22.3656 44.7422 24.4022 45.0439 26.7325C45.0692 26.9305 44.9935 27.129 44.8416 27.2581C44.6897 27.3872 44.4841 27.4297 44.2961 27.3697L43.1872 27.0201C43.1151 26.9979 43.073 27.0575 43.0546 27.0953C42.826 27.5562 42.4304 28.6849 42.3538 31.0643C42.3421 31.4293 42.0922 31.7217 41.7514 31.7804ZM28.3274 16.6975C30.3816 16.7274 32.27 17.2003 33.9526 18.1071C35.6178 19.0045 37.0964 20.3351 38.3462 22.0611C39.3365 23.4288 40.1869 25.0478 40.8742 26.8723C41.0911 27.4492 41.2773 28.01 41.436 28.5365C41.603 27.5892 41.8261 26.9703 42.0218 26.5763C42.3071 26 42.9443 25.7181 43.5366 25.9046L43.7524 25.9731C43.1112 22.567 41.4385 20.0023 38.7724 18.3408C36.5872 16.9791 34.5112 16.7613 34.4907 16.7595C34.479 16.7579 34.4665 16.7565 34.4548 16.755L28.837 15.8483L28.3274 16.6975Z"
        fill="#64350F"
      />
      <g opacity="0.7">
        <path
          d="M41.6659 26.7127C41.5151 27.0166 41.3479 27.4565 41.2028 28.0808C41.2933 28.3609 41.3772 28.6333 41.4535 28.8964C41.6224 27.7769 41.8704 27.0726 42.0838 26.6408C42.2326 26.3408 42.4744 26.1269 42.7544 26.0228C42.3043 26.0159 41.8837 26.2703 41.665 26.7128L41.6659 26.7127Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M38.7961 18.2723C36.5979 16.9048 34.5115 16.6864 34.491 16.6846C34.4803 16.6838 34.4695 16.6821 34.4596 16.6803L28.7969 15.7663L28.7203 15.8939L34.0413 16.7523C34.0512 16.7541 34.0619 16.7549 34.0727 16.7566C34.0932 16.7584 36.1796 16.9768 38.3779 18.3442C41.0401 20.0009 42.7154 22.553 43.3736 25.9349C43.4171 25.9434 43.46 25.9537 43.5035 25.9675L43.8347 26.072C43.2067 22.5847 41.5149 19.9635 38.797 18.2721L38.7961 18.2723Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.08">
        <path
          d="M27.2885 17.2968L28.5473 15.1995L28.9795 15.269L28.2547 16.4761C30.126 16.3682 31.7591 16.6262 33.1856 17.1417L37.396 16.7067L38.6648 17.5052L38.6655 17.585C38.6655 17.585 38.3024 17.7451 37.6659 17.9203C36.9284 18.1227 36.7205 19.1974 37.3107 19.7778C39.9201 22.3489 41.3164 25.8959 42.0375 28.5032C41.9159 29.1528 41.8196 29.9846 41.7853 31.0463C41.7787 31.2428 41.534 31.2618 41.4938 31.0689C40.8202 27.8211 38.7481 20.8936 33.0266 18.2897C32.6949 18.2544 32.3549 18.2055 32.0066 18.1384L31.7813 17.8131C30.4607 17.3975 28.9716 17.1981 27.2882 17.2951L27.2885 17.2968Z"
          fill="#3E3331"
        />
      </g>
      <path
        d="M24.3482 14.5322C24.3482 14.5322 27.4338 8.57066 32.3213 6.58862C32.3213 6.58862 34.7009 5.33573 37.4481 7.46638C38.1959 8.04658 38.8336 8.79548 39.3483 9.64527C39.6914 10.2118 40.1399 10.9994 40.5042 11.7927C41.2287 13.3693 40.7254 15.2945 39.3581 16.0339C38.6 16.4443 37.5101 16.7718 35.9472 16.9033C35.9472 16.9033 28.7912 17.5832 26.3372 17.5083L24.666 17.1206L24.3481 14.5313L24.3482 14.5322Z"
        fill="#FFC78D"
      />
      <path
        d="M37.1705 17.2641C36.7987 17.3281 36.4051 17.3772 35.9899 17.4123C35.6583 17.4436 28.7689 18.0936 26.3201 18.0196C26.2876 18.019 26.2543 18.014 26.2225 18.0071L24.5512 17.6194C24.3452 17.5715 24.1909 17.3996 24.165 17.1869L23.8471 14.5973C23.8346 14.494 23.8534 14.3889 23.9015 14.2964C23.9343 14.2341 24.7123 12.7409 26.1093 10.9834C26.9322 9.94859 27.7999 9.03723 28.6884 8.27558C29.8046 7.31792 30.9586 6.593 32.1178 6.11975C32.2566 6.05156 32.8988 5.76297 33.8328 5.71572C34.773 5.66916 36.2174 5.86376 37.7572 7.05834C38.5332 7.66036 39.2144 8.43994 39.7799 9.37504C40.1088 9.91839 40.5805 10.7411 40.9635 11.5738C41.3647 12.4476 41.4505 13.436 41.2042 14.3566C40.9509 15.3031 40.3795 16.0581 39.5956 16.4827C38.9421 16.8362 38.1298 17.0973 37.1702 17.2624L37.1705 17.2641ZM26.4038 17.0003C28.8445 17.0624 35.8323 16.4026 35.9031 16.3957C35.9048 16.3954 35.9065 16.3952 35.9082 16.3949C37.2567 16.2816 38.3372 16.0089 39.122 15.5841C40.2363 14.9812 40.6595 13.3443 40.0464 12.0098C39.6835 11.2198 39.2317 10.4335 38.9171 9.9125C38.4155 9.08428 37.8178 8.39755 37.1391 7.87094C36.1131 7.07445 35.0311 6.69258 33.9253 6.73483C33.1131 6.76556 32.5796 7.0266 32.5511 7.04036C32.5358 7.04832 32.5235 7.05397 32.507 7.06035C30.0907 8.04053 28.1283 10.0725 26.9076 11.6046C25.8049 12.9889 25.0913 14.2264 24.8686 14.6298L25.1232 16.7022L26.4035 16.9985L26.4038 17.0003Z"
        fill="#232323"
      />
      <g opacity="0.7">
        <path
          d="M40.5019 12.8715C40.1375 12.0776 39.6884 11.2912 39.3461 10.7247C38.8324 9.875 38.1939 9.12647 37.4462 8.54642C34.6995 6.41634 32.3204 7.66879 32.3204 7.66879C28.4444 9.24068 25.7031 13.3138 24.7337 14.9323L24.7238 14.8561C24.7238 14.8561 27.8088 8.89646 32.6954 6.91515C32.6954 6.91515 35.0745 5.6627 37.8212 7.79278C38.5689 8.37282 39.2065 9.12151 39.7211 9.97106C40.0642 10.5374 40.5126 11.3248 40.8769 12.1179C41.4044 13.2664 41.28 14.5997 40.6445 15.5248C40.9253 14.7041 40.8981 13.7361 40.5011 12.8717L40.5019 12.8715Z"
          fill="white"
        />
      </g>
      <path
        d="M32.478 14.5178L35.5013 13.0461L38.2245 12.552C39.1379 12.2119 40.3314 12.6141 40.7924 12.7986C40.9914 14.1186 40.4531 15.4648 39.3618 16.0545C38.605 16.4633 37.5168 16.789 35.9561 16.919C35.9561 16.919 28.8104 17.5916 26.3596 17.515L25.4668 17.308L25.4503 17.2074L27.4202 16.5355L32.4788 14.5177L32.478 14.5178Z"
        fill="white"
      />
      <path
        d="M13.4365 15.4529C13.4365 15.4529 4.80314 15.7516 3.71408 25.478L4.8161 25.1962C5.13775 25.1143 5.47855 25.2843 5.64637 25.6198C5.95481 26.2368 6.36386 27.4871 6.48488 29.8754C6.49504 30.0722 6.74001 30.1063 6.77645 29.9157C7.56236 25.8122 10.5319 15.8179 20.7133 17.0212L19.4175 14.8501L13.4367 15.4537L13.4365 15.4529Z"
        fill="#FFC78D"
      />
      <path
        d="M6.75791 30.6161C6.70331 30.6255 6.64687 30.629 6.58917 30.6248C6.21952 30.6006 5.92642 30.2929 5.90765 29.9088C5.78691 27.522 5.36914 26.3635 5.13065 25.8851C5.08236 25.7879 5.0051 25.7498 4.95462 25.7629L3.85254 26.0447C3.66896 26.0914 3.4746 26.0451 3.33312 25.9196C3.19078 25.7943 3.11892 25.6063 3.14044 25.4156C3.3995 23.0991 4.09975 21.1002 5.21976 19.4759C6.12287 18.1672 7.29531 17.1012 8.70519 16.3081C11.0004 15.017 13.1499 14.8821 13.3988 14.8703L19.3635 14.2685C19.5844 14.2464 19.7976 14.3542 19.9121 14.5455L21.208 16.7168C21.3215 16.9073 21.3176 17.1473 21.1975 17.3356C21.0773 17.5238 20.8625 17.6263 20.6434 17.6002C11.0033 16.4605 8.13041 25.9088 7.34274 30.0235C7.28247 30.337 7.04881 30.5661 6.75791 30.6161ZM4.7745 24.6096C5.32986 24.5141 5.8902 24.8095 6.1614 25.354C6.36703 25.7654 6.60465 26.4106 6.78906 27.3965C6.93624 26.8899 7.10977 26.3512 7.3119 25.7996C7.96685 24.0124 8.79027 22.4409 9.75876 21.1299C10.9837 19.4716 12.4459 18.2245 14.1035 17.4234C15.7539 16.6261 17.6106 16.2659 19.6359 16.3482L19.1098 15.4663L13.4902 16.0335C13.4782 16.0355 13.466 16.0359 13.4538 16.0362C13.4215 16.0373 11.3638 16.1326 9.22081 17.3514C6.58741 18.8488 4.97037 21.3192 4.40335 24.7001L4.6761 24.6301C4.7081 24.6219 4.74109 24.6145 4.7735 24.6089L4.7745 24.6096Z"
        fill="#64350F"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.28">
        <path
          d="M19.4168 14.8502L18.6651 14.9264L19.3819 16.1269C17.4489 15.8983 15.7759 16.0745 14.3283 16.5364L10.7854 15.8382L8.36385 16.9173L9.24097 17.2665C10.1481 17.6273 10.4175 18.8011 9.75276 19.5291C7.80682 21.664 6.66886 24.402 6.01562 26.6372C6.21902 27.363 6.40364 28.4042 6.4787 29.8818C6.48887 30.0787 6.7339 30.1128 6.77036 29.9221C7.55654 25.817 10.527 15.8189 20.7113 17.0225L19.4151 14.8505L19.4168 14.8502Z"
          fill="#3E3331"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M6.52384 25.6148C6.36421 25.2962 6.10117 25.0675 5.7998 24.96C5.92833 25.0722 6.03767 25.2126 6.12012 25.3772C6.34526 25.8268 6.61081 26.5611 6.80044 27.7202C6.86493 27.4895 6.93514 27.2507 7.01064 27.0066C6.85449 26.3753 6.67977 25.9268 6.52398 25.6156L6.52384 25.6148Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M4.7808 24.6619C5.38584 21.3868 6.99733 18.9846 9.58731 17.5151C11.7492 16.289 13.8231 16.1982 13.844 16.1973C13.8545 16.1972 13.8657 16.1962 13.8761 16.1953L19.291 15.65L19.1344 15.3884L13.4755 15.958C13.4652 15.9597 13.4539 15.9599 13.4434 15.9599C13.4225 15.9609 11.3486 16.0517 9.18671 17.2778C6.50447 18.7994 4.86851 21.3199 4.31598 24.7771L4.69443 24.6803C4.72299 24.6727 4.75182 24.6669 4.7808 24.6619Z"
          fill="white"
        />
      </g>
      <path
        d="M23.5442 14.4442C23.5442 14.4442 20.3468 8.28764 15.4178 6.00592C15.4178 6.00592 13.0125 4.6066 10.3014 6.57343C9.56317 7.10836 8.93876 7.81965 8.4391 8.63981C8.10629 9.18654 7.67143 9.94678 7.32028 10.7201C6.62398 12.2551 7.1632 14.2123 8.54497 15.0367C9.31155 15.4936 10.4084 15.8883 11.9751 16.1143C11.9751 16.1143 19.1509 17.2299 21.6061 17.3048L23.2725 17.018L23.5437 14.4461L23.5442 14.4442Z"
        fill="#FFC78D"
      />
      <path
        d="M23.3557 17.521L21.6892 17.8078C21.6559 17.8135 21.6228 17.8148 21.5894 17.8143C19.1385 17.7396 12.2287 16.6702 11.8993 16.6188C10.4141 16.4035 9.19883 16.0194 8.28737 15.4758C6.68997 14.5235 6.05129 12.2951 6.86167 10.5072C7.23081 9.69354 7.68963 8.89722 8.00934 8.37223C8.56292 7.46295 9.23547 6.71852 10.0081 6.15727C11.5394 5.04691 12.9876 4.93866 13.9336 5.04276C14.8708 5.14661 15.5138 5.47488 15.6467 5.54776C16.8114 6.08951 17.9749 6.8824 19.1047 7.90454C20.005 8.71879 20.8873 9.68067 21.7269 10.7634C23.1541 12.6033 23.9581 14.14 23.9914 14.2043C24.0376 14.2939 24.0567 14.3952 24.0459 14.4955L23.7749 17.0684C23.7507 17.2969 23.5792 17.4825 23.3548 17.5211L23.3557 17.521ZM21.576 16.7907L22.8124 16.5779L23.026 14.5514C22.8017 14.1439 22.0608 12.8491 20.9197 11.3811C19.6651 9.76597 17.6547 7.60344 15.2066 6.46944C15.1924 6.46302 15.1789 6.45559 15.1654 6.44816C15.1615 6.44616 14.613 6.13798 13.7765 6.05406C12.6716 5.94361 11.6016 6.25722 10.5958 6.98629C9.93287 7.4666 9.35291 8.11174 8.87064 8.90412C8.56548 9.40532 8.12933 10.1632 7.78131 10.9289C7.1902 12.2321 7.65843 13.9099 8.80493 14.5931C9.60278 15.0694 10.6952 15.4099 12.0508 15.6058C12.0525 15.6055 12.0544 15.606 12.0562 15.6066C12.1273 15.6174 19.1337 16.7038 21.5767 16.7897L21.576 16.7907Z"
        fill="#232323"
      />
      <g opacity="0.7">
        <path
          d="M15.8308 7.04234C15.8308 7.04234 13.4263 5.64373 10.7164 7.6094C9.97847 8.14401 9.35431 8.85489 8.85488 9.67458C8.5222 10.221 8.08753 10.9808 7.73654 11.7537C7.47986 12.3198 7.39134 12.9439 7.45108 13.5468C7.14493 12.7018 7.15096 11.7224 7.53676 10.8726C7.88704 10.1007 8.32157 9.34007 8.6551 8.79351C9.15368 7.97396 9.77883 7.2638 10.5166 6.72833C13.2267 4.76352 15.631 6.16127 15.631 6.16127C20.5581 8.44186 23.7545 14.5951 23.7545 14.5951L23.7085 15.0337C22.8732 13.5841 19.9602 8.95326 15.8306 7.04147L15.8308 7.04234Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M26.6244 17.5254C26.6244 17.5254 25.6719 16.9706 28.7894 16.1061L33.6448 13.9892L37.6066 12.5968L39.8004 12.4656C39.8004 12.4656 40.9598 12.4498 40.9437 13.3569C40.9437 13.3569 40.7894 16.0886 38.3394 16.5163L26.6245 17.5263L26.6244 17.5254Z"
          fill="#FFC78D"
        />
      </g>
      <path
        d="M15.2675 13.9191L12.2114 12.2535L9.47485 11.588C8.55359 11.1892 7.36682 11.5185 6.90794 11.6752C6.73455 12.9876 7.30029 14.3728 8.40533 15.0326C9.17145 15.4898 10.2674 15.8852 11.8326 16.1124C11.8326 16.1124 19.0017 17.2333 21.4544 17.3102L22.345 17.1575L22.3594 17.0575L20.3741 16.2606L15.2685 13.9198L15.2675 13.9191Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M21.4621 17.4338C21.4621 17.4338 22.4056 16.9358 19.2673 15.8767L14.3639 13.4551L10.37 11.8151L8.17009 11.5486C8.17009 11.5486 7.00925 11.4612 7.04145 12.3733C7.04145 12.3733 7.2464 15.1238 9.70957 15.7037L21.4622 17.4346L21.4621 17.4338Z"
          fill="#FFC78D"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M26.074 17.6423C26.0584 15.9356 24.99 14.5201 23.6868 14.4808C23.3091 14.4695 22.9525 14.5761 22.6378 14.7732C23.0118 14.4496 23.465 14.2678 23.9548 14.2829C25.2572 14.3224 26.3264 15.7377 26.342 17.4444C26.3429 17.5197 26.3402 17.5947 26.3374 17.6689L26.0735 17.661C26.0734 17.6549 26.0741 17.6485 26.0741 17.6432L26.074 17.6423Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.18">
        <path
          d="M25.2293 13.3855C26.6837 14.3115 28.4069 14.457 29.9044 13.7593C32.3966 12.5982 36.3675 11.2494 39.84 12.3092C39.84 12.3092 37.9512 11.9546 31.3594 14.8811C30.0113 15.48 28.4087 15.7446 26.6485 16.6983C26.6485 16.6983 25.7688 14.8724 24.7121 14.295L25.2291 13.3847L25.2293 13.3855Z"
          fill="#3E3331"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M25.1559 14.2302C25.1559 14.2302 25.6662 16.9724 21.3879 17.1679L21.2202 17.8766L26.2734 18.185L26.8455 17.0682L26.124 14.9987L25.1559 14.2302Z"
          fill="#000072"
        />
      </g>
      <path
        d="M23.3683 17.6042L21.7028 17.8899C21.6653 17.8963 21.627 17.8985 21.5891 17.897C19.1358 17.8208 12.23 16.7449 11.8944 16.6924C10.3999 16.4748 9.17635 16.0857 8.2572 15.5369C6.62967 14.5648 5.97555 12.2915 6.80002 10.471C7.16967 9.65416 7.62968 8.85376 7.94942 8.32679C8.5069 7.4094 9.18514 6.65676 9.96578 6.09068C11.5136 4.96705 12.981 4.85824 13.9384 4.96559C14.8804 5.07025 15.5325 5.40125 15.675 5.47887C16.8452 6.0245 18.0138 6.82246 19.149 7.85066C20.0527 8.6692 20.9384 9.63548 21.7808 10.7228C23.213 12.5697 24.0197 14.114 24.054 14.1791C24.1066 14.281 24.1291 14.397 24.1171 14.5117L23.8482 17.0862C23.8208 17.3474 23.6249 17.5594 23.3682 17.6034L23.3683 17.6042ZM21.572 16.7258L22.7463 16.5244L22.9501 14.5752C22.7145 14.1479 21.979 12.8728 20.8582 11.4286C19.6076 9.81846 17.6068 7.66258 15.1713 6.53257C15.1542 6.52398 15.1418 6.51812 15.1253 6.50852C15.0955 6.49233 14.5613 6.20146 13.7574 6.12104C12.6728 6.01332 11.6221 6.32221 10.6347 7.03912C9.98018 7.51346 9.40779 8.15209 8.93103 8.93584C8.6278 9.43513 8.19371 10.1903 7.84771 10.9534C7.27265 12.2243 7.72892 13.8615 8.84533 14.5282C9.63538 15.0006 10.7182 15.3385 12.0645 15.5344C12.0662 15.5341 12.068 15.5347 12.0707 15.5351C12.1418 15.546 19.1278 16.6356 21.5719 16.725L21.572 16.7258Z"
        fill="#64350F"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
        <path
          d="M22.0466 13.1562C20.6071 13.9959 18.8838 14.0362 17.3717 13.2444C14.8543 11.9261 10.8518 10.33 7.394 11.1802C7.394 11.1802 9.27917 10.9394 15.9355 14.2818C17.2977 14.9657 18.9072 15.3291 20.6875 16.3944C20.6875 16.3944 21.5339 14.6165 22.5813 14.1018L22.0468 13.157L22.0466 13.1562Z"
          fill="#3E3331"
        />
      </g>
      <path
        d="M37.1846 17.3446C36.8112 17.4087 36.4161 17.4578 35.9994 17.4929C35.6628 17.5249 28.776 18.1693 26.3244 18.093C26.2865 18.0915 26.2498 18.0871 26.2133 18.0783L24.5423 17.689C24.3072 17.6344 24.1311 17.4366 24.1011 17.1943L23.7814 14.6023C23.7665 14.4843 23.7881 14.3644 23.8426 14.2583C23.8752 14.1951 24.6553 12.6954 26.0549 10.9324C26.8798 9.89417 27.7495 8.97956 28.6409 8.21379C29.7607 7.25274 30.9185 6.52465 32.0835 6.04902C32.2376 5.97293 32.8752 5.68619 33.8255 5.63943C34.7775 5.59237 36.2393 5.7905 37.7967 7.00115C38.58 7.61013 39.267 8.39705 39.8378 9.34138C40.1681 9.88701 40.6415 10.7132 41.0265 11.5502C41.8893 13.4281 41.2628 15.674 39.6309 16.5579C38.9715 16.9149 38.1512 17.178 37.1845 17.3438L37.1846 17.3446ZM26.4193 16.9263C28.8608 16.9892 35.8285 16.3354 35.8992 16.3286C35.9009 16.3283 35.9035 16.3279 35.9052 16.3276C37.2428 16.2162 38.3145 15.9472 39.0889 15.5271C40.1695 14.9426 40.5773 13.3479 39.9787 12.0456C39.6167 11.2579 39.1658 10.4731 38.8519 9.95305C38.3546 9.13094 37.7621 8.45023 37.0913 7.9284C36.0802 7.14295 35.0174 6.76462 33.9309 6.8037C33.1372 6.83249 32.6131 7.08468 32.5799 7.10102C32.5622 7.11114 32.549 7.11607 32.5293 7.12389C30.1301 8.09678 28.1794 10.1183 26.9669 11.6426C25.8858 13.0006 25.1804 14.2178 24.9478 14.6391L25.1945 16.6396L26.419 16.9245L26.4193 16.9263Z"
        fill="#64350F"
      />
      <path
        d="M26.1698 17.3416C26.1493 17.3451 26.1289 17.3486 26.1109 17.3508C25.8847 17.3806 25.6788 17.2198 25.6503 16.9925C25.6216 16.7643 25.7814 16.5542 26.007 16.5262C26.8792 16.4107 30.8815 14.7157 34.3371 13.1671C34.9853 12.8762 35.5975 12.6488 36.2081 12.4703C37.6416 12.0522 38.8752 11.9306 39.8763 12.11C40.6681 12.2519 41.0299 12.5415 41.0685 12.5738L40.5388 13.2135C40.5388 13.2135 40.5409 13.2158 40.5449 13.2186C40.5419 13.2165 40.273 13.0126 39.6434 12.9153C39.0497 12.8234 38.0101 12.8095 36.4338 13.2696C35.86 13.4375 35.2826 13.6527 34.6685 13.9272C32.5215 14.8892 27.4504 17.1227 26.1698 17.3416Z"
        fill="#64350F"
      />
      <path
        d="M21.9682 17.1735C21.9205 17.1817 21.8714 17.1812 21.8217 17.172C20.6461 16.9436 15.3939 14.3183 13.1905 13.2005C12.5685 12.8852 11.9847 12.6324 11.4054 12.4277C9.75753 11.8458 8.69552 11.808 8.09468 11.8791C7.54882 11.9434 7.30962 12.1041 7.30806 12.1053C7.1281 12.2478 6.86894 12.2169 6.72896 12.0361C6.58884 11.8545 6.62036 11.592 6.79946 11.4496C6.93592 11.3411 8.234 10.4226 11.6803 11.6394C12.294 11.856 12.9099 12.1227 13.5634 12.454C17.0568 14.2265 21.1031 16.1809 21.9811 16.3522C22.2045 16.3954 22.3507 16.6141 22.3065 16.8416C22.2722 17.0177 22.1339 17.1469 21.9685 17.1752L21.9682 17.1735Z"
        fill="#64350F"
      />
      <path
        d="M26.4322 18.2405C26.3946 18.247 26.3565 18.25 26.3177 18.2487L21.6177 18.1061C21.3214 18.0968 21.0806 17.8624 21.0617 17.5633C21.0556 17.4668 21.0519 17.385 21.0513 17.3061C21.0438 16.4473 21.2755 15.6191 21.7052 14.9723C22.156 14.2935 22.7939 13.8529 23.5013 13.7312C23.6575 13.7043 23.8179 13.6926 23.9776 13.698C24.7834 13.7224 25.5343 14.1421 26.0916 14.8807C26.6173 15.5767 26.9107 16.4831 26.9198 17.4321C26.9203 17.5109 26.9188 17.5928 26.9143 17.6893C26.9011 17.9691 26.6951 18.1961 26.4323 18.2414L26.4322 18.2405Z"
        fill="#FFC78D"
      />
      <path
        d="M23.5952 14.3072C23.713 14.2869 23.8344 14.2784 23.957 14.2822C25.2584 14.3216 26.3268 15.7358 26.3424 17.4412C26.3433 17.5164 26.3406 17.5914 26.3377 17.6655L21.6374 17.5229C21.6322 17.4484 21.6288 17.3736 21.6288 17.2982C21.615 15.7548 22.468 14.5021 23.5954 14.308M23.4084 13.1554C22.5422 13.3045 21.768 13.835 21.2267 14.6483C20.7321 15.3924 20.4653 16.3393 20.4735 17.3152C20.4741 17.4047 20.4777 17.4972 20.4846 17.6042C20.5226 18.2034 21.0035 18.6722 21.5961 18.6908L26.2965 18.8335C26.375 18.8359 26.452 18.8298 26.5263 18.817C27.0537 18.7262 27.465 18.2732 27.4912 17.7126C27.4965 17.6053 27.4981 17.5128 27.4976 17.4233C27.4882 16.3483 27.1519 15.3189 26.5521 14.5237C26.24 14.1109 25.8718 13.7788 25.457 13.538C24.9995 13.2717 24.5087 13.1292 23.999 13.1132C23.8015 13.1072 23.6028 13.121 23.4082 13.1545L23.4084 13.1554Z"
        fill="#64350F"
      />
    </g>
    <defs>
      <clipPath id="clip0_158_8951">
        <rect width="47" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const GiftOpen = (
  <svg viewBox="0 0 47 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.7422 33.6884H6.35512C5.4583 33.6884 4.75793 34.4188 4.84846 35.2587L7.03499 55.4787C7.11357 56.2068 7.7644 56.7602 8.54164 56.7602H36.7538C37.5361 56.7602 38.1895 56.1995 38.2613 55.4659L40.2488 35.2458C40.3308 34.41 39.6321 33.6884 38.7413 33.6884H38.7422Z"
      fill="#EA7100"
    />
    <path
      d="M36.7567 57.2961H8.54374C7.47012 57.2961 6.57843 56.5374 6.46995 55.5319L4.28343 35.3118C4.22364 34.7607 4.41582 34.2081 4.81042 33.797C5.20502 33.3859 5.76873 33.1494 6.35721 33.1494H38.7443C39.3293 33.1494 39.8905 33.3827 40.2842 33.7898C40.678 34.1968 40.8735 34.7447 40.8197 35.2933L38.8322 55.5134C38.7844 56.0017 38.5461 56.453 38.1609 56.7828C37.7757 57.1134 37.2777 57.2952 36.7567 57.2952V57.2961ZM6.35721 34.2242C6.08646 34.2242 5.83791 34.3279 5.65684 34.517C5.47577 34.706 5.39121 34.9498 5.41854 35.2032L7.60507 55.4233C7.65461 55.8786 8.05775 56.2221 8.54374 56.2221H36.7559C37.2461 56.2221 37.6501 55.8754 37.6954 55.4152L39.6829 35.1952C39.7077 34.9434 39.6214 34.7012 39.4404 34.5146C39.2593 34.3271 39.0116 34.2242 38.7425 34.2242H6.35721Z"
      fill="#64350F"
    />
    <g opacity="0.5">
      <path
        d="M8.97732 55.8101L6.7908 35.5901C6.76347 35.3367 6.84802 35.0929 7.0291 34.9039C7.21017 34.7148 7.45871 34.611 7.72947 34.611H39.8816C39.8406 34.5217 39.7843 34.4381 39.7125 34.3641C39.5314 34.1774 39.2838 34.0745 39.0147 34.0745H6.62852C6.35776 34.0745 6.10922 34.1782 5.92815 34.3673C5.74708 34.5563 5.66166 34.8001 5.68985 35.0535L7.87637 55.2736C7.92591 55.7289 8.32905 56.0724 8.81504 56.0724H9.04992C9.0132 55.9911 8.98757 55.9035 8.97732 55.8109V55.8101Z"
        fill="white"
      />
    </g>
    <path d="M18.9448 56.6117H26.7693L26.7693 33.5398H18.9448L18.9448 56.6117Z" fill="#FFC78D" />
    <path
      d="M26.7703 57.1466H18.9458C18.6306 57.1466 18.3752 56.9061 18.3752 56.6093V33.5374C18.3752 33.2405 18.6306 33 18.9458 33H26.7703C27.0855 33 27.3408 33.2405 27.3408 33.5374V56.6093C27.3408 56.9061 27.0855 57.1466 26.7703 57.1466ZM19.5163 56.0727H26.2006V34.0748H19.5163V56.0727Z"
      fill="#64350F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.11">
      <path
        d="M39.019 33.5409H36.4234L35.073 47.2746C34.9432 48.5931 34.4307 49.8618 33.5459 50.8907C31.9999 52.6878 28.9286 54.4625 22.8336 54.6507H7.23926L7.31271 55.3313C7.39129 56.0593 8.04212 56.6128 8.81936 56.6128H37.0315C37.8139 56.6128 38.4673 56.0521 38.539 55.3184L40.5265 35.0983C40.6085 34.2625 39.9099 33.5409 39.019 33.5409Z"
        fill="#3E3331"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M13.151 38.0555L10.928 38.2833C10.4127 38.3361 10.0403 38.7724 10.0964 39.2578L10.9577 46.713C11.0138 47.1984 11.477 47.5491 11.9924 47.4963L14.2153 47.2684C14.7307 47.2156 15.103 46.7793 15.0469 46.2939L14.1857 38.8387C14.1296 38.3533 13.6663 38.0026 13.151 38.0555Z"
        fill="white"
      />
    </g>
    <path
      d="M45.8113 57.2988H0.610172C0.273116 57.2988 0 57.0416 0 56.7241C0 56.4067 0.273116 56.1494 0.610172 56.1494H45.8122C46.1493 56.1494 46.4224 56.4067 46.4224 56.7241C46.4224 57.0416 46.1493 57.2988 45.8122 57.2988H45.8113Z"
      fill="#64350F"
    />
    <path
      d="M44.4769 27.9634L43.3273 32.8506C43.2193 33.3083 42.7508 33.606 42.2605 33.5289L3.58914 27.4745C3.0936 27.3966 2.75134 26.9643 2.81404 26.4951L3.50118 21.3676C3.56824 20.8697 4.06204 20.5252 4.58719 20.6097L43.7218 26.9052C44.2487 26.9897 44.5936 27.4729 44.4778 27.9642L44.4769 27.9634Z"
      fill="#EA7100"
    />
    <g opacity="0.5">
      <path
        d="M4.47687 22.6711C4.54393 22.1732 5.03773 21.8287 5.56288 21.9132L44.4345 28.1661L44.4824 27.9635C44.5982 27.4721 44.2533 26.989 43.7265 26.9045L4.59183 20.6089C4.06668 20.5244 3.57288 20.8698 3.50582 21.3669L2.81868 26.4944C2.75598 26.9635 3.09824 27.3967 3.59378 27.4738L3.82805 27.5107L4.47687 22.6711Z"
        fill="white"
      />
    </g>
    <path
      d="M19.5454 23.1969L18.0962 29.7443L27.5707 31.2273L29.0625 24.5823L19.5454 23.1969Z"
      fill="#FFC78D"
    />
    <g opacity="0.3">
      <path
        d="M43.7256 26.9024L42.4245 26.6932L41.5196 30.5378C41.4116 30.9955 40.9431 31.2933 40.4528 31.2162L2.97196 25.3472L2.81868 26.4931C2.75598 26.9623 3.09824 27.3954 3.59378 27.4725L42.2651 33.5277C42.7554 33.6048 43.224 33.3062 43.3319 32.8493L44.4815 27.9622C44.5974 27.4708 44.2525 26.9877 43.7256 26.9032V26.9024Z"
        fill="#B24800"
      />
    </g>
    <path
      d="M42.4141 34.0907C42.3314 34.0907 42.2478 34.0841 42.1642 34.071L3.49197 28.0157C2.68464 27.8894 2.13337 27.1914 2.23526 26.4269L2.9224 21.2994C2.97465 20.9089 3.18802 20.5652 3.52419 20.3306C3.85948 20.096 4.27142 20.0034 4.68335 20.0698L43.818 26.3654C44.2256 26.431 44.5913 26.6541 44.8213 26.9765C45.052 27.2996 45.133 27.7024 45.0433 28.083L43.8938 32.9702C43.7379 33.6338 43.1134 34.0898 42.4141 34.0898V34.0907ZM4.42731 21.1452C4.35241 21.1452 4.27926 21.1681 4.21742 21.2108C4.13904 21.2657 4.0894 21.3461 4.07721 21.4372L3.39007 26.5647C3.36656 26.7427 3.49458 26.9059 3.68356 26.9354L42.3549 32.9907C42.5404 33.0194 42.718 32.907 42.759 32.7339L43.9086 27.8468C43.9373 27.7254 43.8885 27.6327 43.8563 27.5884C43.8249 27.5441 43.7518 27.467 43.622 27.4457L4.4874 21.1501C4.46737 21.1468 4.44647 21.1452 4.42644 21.1452H4.42731Z"
      fill="#64350F"
    />
    <path
      d="M27.7611 31.8089C27.7263 31.8089 27.6906 31.8057 27.6558 31.7999C27.3396 31.745 27.1306 31.4595 27.189 31.1626L28.4901 24.488C28.5484 24.1903 28.8515 23.9934 29.1668 24.0484C29.4829 24.1033 29.6919 24.3888 29.6336 24.6857L28.3325 31.3603C28.2811 31.6244 28.0364 31.8089 27.7611 31.8089Z"
      fill="#64350F"
    />
    <path
      d="M18.2409 30.2723C18.206 30.2723 18.1703 30.269 18.1355 30.2633C17.8194 30.2083 17.6104 29.9229 17.6687 29.626L18.9698 22.9514C19.0282 22.6537 19.3312 22.4568 19.6465 22.5118C19.9626 22.5667 20.1717 22.8522 20.1133 23.1491L18.8122 29.8236C18.7608 30.0878 18.5161 30.2723 18.2409 30.2723Z"
      fill="#64350F"
    />
    <path
      d="M35.1021 23.5511C35.1021 23.5511 43.5524 25.6896 43.2241 34.9586L42.1808 34.4591C41.876 34.313 41.5163 34.3984 41.3029 34.6731C40.911 35.1801 40.3284 36.253 39.8659 38.4456C39.8276 38.626 39.582 38.6047 39.5733 38.4201C39.3913 34.4394 37.9081 24.5158 27.7178 23.4387L29.3045 21.7014L35.1012 23.5511H35.1021Z"
      fill="#FFC78D"
    />
    <path
      d="M39.7224 39.1163C39.7033 39.1163 39.685 39.1163 39.6658 39.1138C39.2922 39.0868 39.0083 38.8038 38.9918 38.4412C38.808 34.4342 37.3353 25.0044 27.6527 23.9807C27.4384 23.9577 27.2547 23.8257 27.1763 23.637C27.0979 23.4483 27.1362 23.2334 27.2764 23.0792L28.8632 21.3419C29.0182 21.1729 29.2664 21.1089 29.4903 21.1803L35.2713 23.0251C35.5125 23.0891 37.6018 23.6756 39.6658 25.3628C40.9304 26.3964 41.9241 27.6309 42.619 29.0319C43.483 30.7742 43.8818 32.7732 43.8043 34.9748C43.7974 35.1618 43.6902 35.3332 43.5187 35.4284C43.3471 35.5235 43.1364 35.5301 42.9596 35.4448L41.9162 34.9452C41.8483 34.9132 41.7969 34.9616 41.7725 34.9936C41.4712 35.3832 40.8964 36.3635 40.4357 38.5503C40.3652 38.8858 40.0699 39.1163 39.7224 39.1163ZM28.8458 23.0415C30.8785 23.3958 32.6751 24.1333 34.1975 25.239C35.7041 26.3332 36.9556 27.7982 37.9162 29.5922C38.6774 31.0137 39.2591 32.6452 39.6458 34.4408C39.7677 35.0084 39.8618 35.5563 39.9341 36.0682C40.2528 35.2192 40.5742 34.6827 40.832 34.3497C41.2082 33.8625 41.8857 33.7033 42.4431 33.9699L42.646 34.0675C42.5607 30.8176 41.3162 28.1813 38.9404 26.2217C36.993 24.6156 34.9691 24.084 34.949 24.0791C34.9377 24.0758 34.9255 24.0726 34.9142 24.0693L29.4885 22.3377L28.8458 23.0415Z"
      fill="#64350F"
    />
    <g opacity="0.7">
      <path
        d="M40.4685 34.3886C40.2699 34.6453 40.0331 35.0251 39.7883 35.5788C39.8327 35.8519 39.8719 36.1169 39.905 36.372C40.2534 35.3647 40.6131 34.7536 40.8944 34.3886C41.0903 34.1351 41.3647 33.976 41.659 33.9243C41.214 33.8464 40.7559 34.0145 40.4676 34.3886H40.4685Z"
        fill="white"
      />
    </g>
    <g opacity="0.7">
      <path
        d="M38.9841 26.1654C37.0237 24.5511 34.9884 24.0171 34.9684 24.0122C34.9579 24.0097 34.9475 24.0065 34.9379 24.0032L29.4661 22.2568L29.3694 22.3627L34.5112 24.0032C34.5207 24.0065 34.5312 24.0089 34.5416 24.0122C34.5617 24.0171 36.597 24.5511 38.5573 26.1654C40.9314 28.1209 42.1811 30.7474 42.2874 33.9793C42.3292 33.9941 42.3701 34.0105 42.4111 34.0302L42.7228 34.1794C42.6636 30.8549 41.4087 28.162 38.985 26.1654H38.9841Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.08">
      <path
        d="M27.7156 23.438L29.3024 21.7007L29.7195 21.8336L28.8059 22.8335C30.6784 23.0312 32.2556 23.5291 33.5863 24.232L37.8302 24.4994L38.9589 25.4386L38.9467 25.5125C38.9467 25.5125 38.5609 25.6027 37.9016 25.6634C37.1378 25.7331 36.7581 26.6928 37.2493 27.3228C39.4204 30.1125 40.2312 33.611 40.5247 36.1341C40.2992 36.7149 40.0693 37.4679 39.8637 38.4432C39.8254 38.6237 39.5798 38.6023 39.5711 38.4178C39.4283 35.3106 38.4938 28.5819 33.2432 25.2672C32.9201 25.1819 32.5909 25.0827 32.2564 24.9654L32.0857 24.629C30.8438 24.0352 29.3999 23.6144 27.7156 23.4364V23.438Z"
        fill="#3E3331"
      />
    </g>
    <path
      d="M25.2648 20.453C25.2648 20.453 29.2953 15.4255 34.471 14.369C34.471 14.369 37.0376 13.5881 39.4212 15.9981C40.07 16.6543 40.5821 17.4491 40.9557 18.3178C41.2048 18.8969 41.5227 19.6975 41.7561 20.4899C42.2203 22.0648 41.4086 23.7669 39.9307 24.2336C39.1112 24.4928 37.9755 24.6224 36.4018 24.4953C36.4018 24.4953 29.1838 23.9851 26.7584 23.5249L25.1611 22.8998L25.2648 20.4522V20.453Z"
      fill="#FFC78D"
    />
    <path
      d="M37.5592 25.0217C37.1795 25.0217 36.7806 25.0045 36.3626 24.9708C36.0282 24.947 29.0801 24.4516 26.6599 23.9931C26.6277 23.9873 26.5955 23.9775 26.565 23.966L24.9678 23.341C24.7709 23.2639 24.6455 23.0801 24.6542 22.8791L24.7579 20.4315C24.7622 20.3339 24.7979 20.2395 24.8606 20.1616C24.9033 20.1091 25.9179 18.8508 27.59 17.4465C28.575 16.6197 29.5844 15.9142 30.5903 15.3507C31.8539 14.642 33.1176 14.1548 34.3456 13.9013C34.4945 13.8603 35.179 13.6954 36.1144 13.8004C37.0558 13.907 38.4588 14.3172 39.7948 15.6682C40.468 16.349 41.0184 17.1791 41.4286 18.1347C41.6672 18.69 42.0025 19.5267 42.2481 20.3585C42.505 21.2312 42.4301 22.1598 42.0365 22.9727C41.6315 23.8085 40.9418 24.4163 40.0944 24.6846C39.3881 24.9077 38.5389 25.0201 37.5592 25.0201V25.0217ZM26.9081 23.0629C29.3222 23.5091 36.3695 24.0111 36.441 24.016C36.4427 24.016 36.4444 24.016 36.4462 24.016C37.8039 24.126 38.9213 24.0456 39.7695 23.7773C40.974 23.3967 41.6594 21.949 41.2666 20.616C41.0341 19.8269 40.7127 19.0272 40.4845 18.4948C40.1205 17.6483 39.638 16.9174 39.0493 16.3219C38.1592 15.4213 37.1464 14.8955 36.0412 14.7585C35.2295 14.6576 34.6574 14.8143 34.6269 14.8225C34.6103 14.8274 34.5973 14.8307 34.5799 14.834C32.0212 15.3565 29.7429 16.9248 28.2824 18.1487C26.963 19.2544 26.0538 20.2863 25.7672 20.6242L25.6845 22.583L26.9081 23.0612V23.0629Z"
      fill="#232323"
    />
    <g opacity="0.7">
      <path
        d="M41.5735 21.4868C41.3401 20.6936 41.0213 19.8939 40.7731 19.3147C40.4004 18.4461 39.8874 17.6512 39.2386 16.995C36.8549 14.5851 34.2884 15.366 34.2884 15.366C30.183 16.2043 26.7996 19.5395 25.5742 20.8839L25.5768 20.8117C25.5768 20.8117 29.6073 15.7843 34.7831 14.7278C34.7831 14.7278 37.3496 13.9469 39.7333 16.3569C40.3821 17.0131 40.8942 17.8079 41.2678 18.6766C41.5169 19.2557 41.8347 20.0563 42.0681 20.8486C42.406 21.9962 42.0664 23.211 41.2852 23.9665C41.6971 23.2512 41.8269 22.3506 41.5726 21.4868H41.5735Z"
        fill="white"
      />
    </g>
    <path
      d="M33.3342 21.7116L36.5817 20.8257L39.3712 20.7995C40.3353 20.6289 41.457 21.1916 41.8855 21.436C41.8681 22.6927 41.113 23.8566 39.9312 24.2307C39.1117 24.4899 37.976 24.6195 36.4023 24.4924C36.4023 24.4924 29.1843 23.9821 26.7589 23.522L25.9045 23.1881V23.0922L27.9738 22.7813L33.335 21.7116H33.3342Z"
      fill="white"
    />
    <path
      d="M14.2012 19.589C14.2012 19.589 5.57933 18.4907 2.9231 27.3184L4.0631 27.2331C4.39578 27.2085 4.70669 27.4201 4.81904 27.7573C5.02544 28.3774 5.22923 29.5996 4.96274 31.8291C4.94096 32.0128 5.17872 32.0834 5.24578 31.9127C6.6906 28.2404 11.2576 19.4643 21.1736 22.1991L20.2383 19.9836L14.2012 19.5898V19.589Z"
      fill="#FFC78D"
    />
    <path
      d="M5.11412 32.559C5.05838 32.559 5.00177 32.5533 4.94516 32.5402C4.582 32.459 4.34076 32.1276 4.38431 31.7691C4.6508 29.5413 4.4235 28.4027 4.26412 27.9221C4.2319 27.8244 4.16136 27.7769 4.1091 27.781L2.9691 27.8663C2.77924 27.8802 2.59374 27.8064 2.47356 27.6678C2.3525 27.5291 2.31157 27.3438 2.36383 27.1707C2.9961 25.0683 4.01505 23.3302 5.39019 22.0055C6.49884 20.9383 7.83567 20.1385 9.36409 19.6291C11.8522 18.7998 14.0086 19.0172 14.2577 19.0459L20.2782 19.4388C20.5011 19.4536 20.6953 19.5873 20.7781 19.7825L21.7134 21.9981C21.7953 22.1925 21.7526 22.4139 21.6028 22.569C21.453 22.724 21.2231 22.7847 21.0097 22.7257C11.6215 20.1361 7.23911 28.4216 5.7908 32.1038C5.6802 32.3843 5.41109 32.559 5.11412 32.559ZM4.11694 26.6851C4.6839 26.6851 5.1925 27.0476 5.37365 27.5948C5.51125 28.0082 5.64275 28.6431 5.66627 29.5847C5.89444 29.1393 6.15397 28.6685 6.44398 28.1903C7.38368 26.6408 8.45575 25.3177 9.62972 24.2587C11.1146 22.9192 12.7684 21.9981 14.5442 21.5207C16.3121 21.0457 18.2141 21.008 20.212 21.4067L19.8323 20.5068L14.1601 20.1369C14.1479 20.1369 14.1357 20.1352 14.1235 20.1336C14.0913 20.1295 12.0325 19.89 9.70723 20.6766C6.84982 21.6429 4.84414 23.6714 3.73375 26.7097L4.01592 26.6884C4.04901 26.6859 4.08298 26.6843 4.11607 26.6843L4.11694 26.6851Z"
      fill="#64350F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.28">
      <path
        d="M20.2384 19.9793L19.4798 19.9301L19.9972 21.1548C18.1152 20.6355 16.4256 20.5322 14.9137 20.7291L11.5094 19.5192L8.93067 20.1319L9.74496 20.5945C10.5871 21.0728 10.6646 22.2014 9.88691 22.7691C7.60952 24.4342 6.03668 26.7859 5.02644 28.7497C5.11091 29.4534 5.12572 30.446 4.96112 31.8248C4.93935 32.0086 5.1771 32.0791 5.24416 31.9085C6.68898 28.2362 11.256 19.4601 21.172 22.1949L20.2367 19.9793H20.2384Z"
        fill="#3E3331"
      />
    </g>
    <g opacity="0.7">
      <path
        d="M5.66627 27.8995C5.56002 27.5804 5.33707 27.3277 5.05664 27.1809C5.1655 27.3048 5.25085 27.4516 5.30572 27.6165C5.45551 28.0668 5.59921 28.7862 5.59921 29.8853C5.70023 29.6827 5.80822 29.4736 5.92231 29.2603C5.87006 28.6533 5.76991 28.212 5.66627 27.9003V27.8995Z"
        fill="white"
      />
    </g>
    <g opacity="0.7">
      <path
        d="M4.10574 26.7512C5.23877 23.8113 7.23051 21.841 10.0435 20.8912C12.3914 20.0988 14.4677 20.3449 14.4886 20.3473C14.499 20.349 14.5103 20.3498 14.5208 20.3506L19.9918 20.7074L19.8786 20.44L14.1611 20.0668C14.1507 20.0668 14.1393 20.0652 14.1289 20.0635C14.108 20.0611 12.0318 19.815 9.68383 20.6074C6.77068 21.5909 4.73539 23.667 3.625 26.784L4.0169 26.7545C4.04651 26.752 4.07612 26.7512 4.10574 26.7512Z"
        fill="white"
      />
    </g>
    <path
      d="M24.4426 20.295C24.4426 20.295 22.2645 14.0905 17.7402 11.1949C17.7402 11.1949 15.5786 9.51749 12.5688 10.9054C11.7493 11.2827 11.0143 11.8413 10.3855 12.5205C9.96658 12.9733 9.41182 13.6073 8.93805 14.2668C7.99835 15.576 8.21695 17.4725 9.45537 18.4551C10.1425 18.9998 11.1675 19.5395 12.6864 19.9981C12.6864 19.9981 19.6301 22.1726 22.0555 22.6328H23.7564L24.4418 20.2966L24.4426 20.295Z"
      fill="#FFC78D"
    />
    <path
      d="M23.7556 23.1104H22.0548C22.0208 23.1104 21.9877 23.1063 21.9546 23.1006C19.5335 22.6412 12.8468 20.552 12.528 20.4519C11.0884 20.0164 9.94407 19.4676 9.12717 18.8196C7.69541 17.6843 7.42195 15.5213 8.5158 13.9964C9.01395 13.3025 9.59832 12.6389 10.0007 12.2041C10.6974 11.4511 11.4856 10.8696 12.3434 10.4734C14.0434 9.69001 15.4986 9.82043 16.4209 10.0673C17.3345 10.3126 17.9197 10.7186 18.0399 10.8072C19.1085 11.4938 20.1353 12.4125 21.0916 13.5379C21.8536 14.4344 22.5738 15.4647 23.2322 16.6C24.3513 18.5292 24.9009 20.0787 24.9235 20.1435C24.9548 20.2337 24.9575 20.3305 24.9305 20.4216L24.2451 22.7585C24.1841 22.9661 23.9838 23.1104 23.7548 23.1104H23.7556ZM22.107 22.1515H23.3689L23.9089 20.3109C23.7521 19.8983 23.2261 18.5825 22.3308 17.0429C21.3467 15.349 19.7007 13.0285 17.4538 11.5898C17.4408 11.5815 17.4286 11.5725 17.4164 11.5635C17.4129 11.561 16.9182 11.1886 16.1013 10.9778C15.0223 10.6998 13.9093 10.8195 12.7928 11.3338C12.0569 11.6726 11.3767 12.1771 10.7697 12.8333C10.3856 13.2483 9.82998 13.8799 9.36057 14.5329C8.56283 15.6443 8.75617 17.2709 9.78382 18.0855C10.4988 18.6531 11.5282 19.142 12.8424 19.539C12.8442 19.539 12.8459 19.5398 12.8476 19.5406C12.9164 19.5619 19.6964 21.6823 22.1079 22.1507L22.107 22.1515Z"
      fill="#232323"
    />
    <g opacity="0.7">
      <path
        d="M17.9809 12.2204C17.9809 12.2204 15.8193 10.5429 12.8095 11.9308C11.99 12.3081 11.2549 12.8668 10.6262 13.5459C10.2073 13.9987 9.65249 14.6328 9.17873 15.2923C8.83211 15.7754 8.64313 16.3389 8.60481 16.9066C8.43759 16.0756 8.60219 15.1701 9.12299 14.4449C9.59589 13.7863 10.1506 13.1514 10.5704 12.6986C11.1983 12.0194 11.9342 11.4616 12.7538 11.0835C15.7636 9.69641 17.9251 11.373 17.9251 11.373C22.4495 14.2686 24.6276 20.4731 24.6276 20.4731L24.5109 20.8718C23.916 19.3969 21.7728 14.6467 17.9809 12.2196V12.2204Z"
        fill="white"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M27.0224 23.5694C27.0224 23.5694 26.1655 22.9026 29.407 22.5958L34.581 21.4023L38.7482 20.7395L40.9516 20.9667C40.9516 20.9667 42.1072 21.1365 41.9435 21.9765C41.9435 21.9765 41.3452 24.4889 38.8388 24.4963L27.0224 23.5702V23.5694Z"
        fill="#FFC78D"
      />
    </g>
    <path
      d="M16.2988 18.4814L13.532 16.4553L10.9202 15.4054C10.0693 14.8902 8.83611 15.0067 8.3545 15.0789C7.9687 16.2658 8.30573 17.6373 9.29681 18.4231C9.98395 18.9678 11.009 19.5075 12.5278 19.9661C12.5278 19.9661 19.4715 22.1406 21.897 22.6008H22.807L22.8375 22.5105L20.9938 21.4581L16.2997 18.4822L16.2988 18.4814Z"
      fill="white"
    />
    <g opacity="0.3">
      <path
        d="M21.8817 22.7249C21.8817 22.7249 22.8998 22.414 19.9535 20.9334L15.4745 17.9099L11.7723 15.755L9.62993 15.1579C9.62993 15.1579 8.4908 14.8921 8.37497 15.7419C8.37497 15.7419 8.13286 18.3217 10.486 19.251L21.8817 22.7257V22.7249Z"
        fill="#FFC78D"
      />
    </g>
    <g opacity="0.7">
      <path
        d="M26.4416 23.6099C26.702 22.0301 25.8711 20.5519 24.5848 20.3083C24.2121 20.2378 23.8411 20.2796 23.4971 20.4117C23.9203 20.1721 24.3993 20.0762 24.8827 20.168C26.1681 20.4117 26.9998 21.8898 26.7394 23.4696C26.7281 23.5393 26.7133 23.6083 26.6985 23.6763L26.4381 23.6271C26.439 23.6214 26.4407 23.6156 26.4416 23.6107V23.6099Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.18">
      <path
        d="M26.3046 19.5183C27.6005 20.6101 29.2909 21.0194 30.8942 20.6092C33.5627 19.9268 37.7325 19.305 41.014 20.8422C41.014 20.8422 39.193 20.2122 32.1588 21.8831C30.72 22.2252 29.0828 22.2161 27.1764 22.8223C27.1764 22.8223 26.599 20.9857 25.6418 20.2811L26.3046 19.5175V19.5183Z"
        fill="#3E3331"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M26.0767 20.3055C26.0767 20.3055 26.1394 22.9247 21.8589 22.4243L21.5776 23.0534L26.5461 24.1436L27.2951 23.2011L26.9136 21.1709L26.0767 20.3055Z"
        fill="#000072"
      />
    </g>
    <path
      d="M23.7531 23.1793H22.0522C22.0139 23.1793 21.9756 23.1752 21.9381 23.1679C19.5135 22.7085 12.8285 20.6193 12.5037 20.5176C11.0545 20.0796 9.90231 19.5259 9.07844 18.8729C7.61969 17.7164 7.33926 15.5115 8.45401 13.9595C8.95391 13.2631 9.54089 12.5962 9.94412 12.1599C10.6469 11.4003 11.4429 10.8122 12.3103 10.4127C14.0304 9.61948 15.5057 9.75154 16.4393 10.0025C17.3581 10.2486 17.952 10.6579 18.0809 10.7523C19.1547 11.4421 20.1859 12.3649 21.1465 13.4953C21.9111 14.3951 22.634 15.4286 23.2941 16.5672C24.4167 18.5014 24.9671 20.0566 24.9906 20.1222C25.0263 20.2247 25.0298 20.3355 24.9993 20.4396L24.3139 22.7766C24.2442 23.0137 24.0152 23.1785 23.7531 23.1785V23.1793ZM22.1114 22.0826H23.3106L23.8297 20.3133C23.6651 19.881 23.1417 18.5858 22.263 17.0733C21.2823 15.3868 19.645 13.0769 17.4094 11.6464C17.3938 11.6357 17.3824 11.6283 17.3676 11.6168C17.3406 11.5972 16.8573 11.2436 16.0717 11.0418C15.0119 10.7703 13.918 10.8893 12.8207 11.3954C12.0935 11.73 11.4212 12.2296 10.8202 12.8784C10.4379 13.2918 9.88402 13.921 9.41635 14.5714C8.63864 15.655 8.82589 17.2406 9.82655 18.0338C10.5346 18.5957 11.5553 19.0796 12.8608 19.4742C12.8625 19.4742 12.8642 19.475 12.8668 19.4758C12.9356 19.4972 19.6982 21.6118 22.1114 22.0818V22.0826Z"
      fill="#64350F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
      <path
        d="M23.1513 18.8585C21.5846 19.4072 19.8654 19.1702 18.4911 18.1957C16.2033 16.5732 12.4846 14.4569 8.9104 14.694C8.9104 14.694 10.8229 14.7711 16.8957 18.9282C18.1384 19.7788 19.679 20.3719 21.2754 21.6424C21.2754 21.6424 22.405 20.1299 23.5293 19.8198L23.1513 18.8593V18.8585Z"
        fill="#3E3331"
      />
    </g>
    <path
      d="M37.5542 25.092C37.1728 25.092 36.7721 25.0748 36.3524 25.0412C36.0127 25.0174 29.0647 24.5211 26.641 24.0618C26.6036 24.0544 26.5678 24.0445 26.533 24.0306L24.9358 23.4055C24.7111 23.3178 24.5683 23.107 24.5778 22.8781L24.6815 20.4304C24.6858 20.3189 24.7268 20.2114 24.7982 20.122C24.8409 20.0687 25.8598 18.8055 27.5372 17.3971C28.5256 16.5678 29.5385 15.8599 30.5487 15.2931C31.8176 14.5819 33.0865 14.0922 34.3214 13.8371C34.4869 13.7912 35.1671 13.6271 36.119 13.7346C37.0726 13.842 38.493 14.2571 39.8438 15.6236C40.5231 16.311 41.0779 17.1477 41.4915 18.1115C41.731 18.6685 42.0672 19.5076 42.3137 20.3427C42.8658 22.2161 41.8782 24.1938 40.1129 24.7524C39.3996 24.978 38.5418 25.0912 37.5542 25.0912V25.092ZM26.9249 22.9979C29.3408 23.4433 36.3707 23.9436 36.4421 23.9486C36.4438 23.9486 36.4464 23.9486 36.4482 23.9486C37.7954 24.0577 38.9041 23.9789 39.7419 23.7131C40.9106 23.344 41.5751 21.934 41.1919 20.6347C40.9603 19.8489 40.6398 19.0516 40.4125 18.5208C40.0519 17.6817 39.5738 16.9582 38.9921 16.3693C38.1151 15.4826 37.1205 14.9642 36.0345 14.828C35.2411 14.7287 34.6794 14.8788 34.6437 14.8887C34.6245 14.8952 34.6106 14.8977 34.5897 14.9018C32.0475 15.421 29.7806 16.9812 28.3279 18.1985C27.0329 19.2829 26.1341 20.2967 25.8345 20.6494L25.7544 22.5385L26.9249 22.9962V22.9979Z"
      fill="#64350F"
    />
    <path
      d="M26.5949 23.3406C26.574 23.3406 26.5531 23.3406 26.5348 23.3398C26.3049 23.3316 26.1264 23.1495 26.1351 22.9337C26.1438 22.7172 26.3371 22.5474 26.5662 22.5572C27.4527 22.5884 31.7114 21.6492 35.4023 20.7592C36.0946 20.5919 36.7409 20.4779 37.3775 20.4089C38.8719 20.2482 40.1191 20.331 41.0858 20.6567C41.8504 20.9142 42.1631 21.2407 42.1962 21.2768L41.5648 21.787C41.5648 21.787 41.5665 21.7895 41.57 21.7927C41.5674 21.7903 41.3331 21.5581 40.7226 21.3678C40.1469 21.1882 39.1149 21.0102 37.4715 21.1874C36.8732 21.2522 36.2636 21.3605 35.6078 21.518C33.3147 22.0708 27.9047 23.3406 26.5949 23.3406Z"
      fill="#64350F"
    />
    <path
      d="M22.4323 22.5565C22.3835 22.5565 22.3347 22.5483 22.2868 22.5319C21.1556 22.1341 16.3621 18.8719 14.3538 17.4881C13.7869 17.0976 13.2478 16.7712 12.7052 16.4898C11.162 15.69 10.1126 15.4866 9.50382 15.4571C8.9508 15.43 8.68692 15.5408 8.68518 15.5416C8.48313 15.6449 8.23057 15.5752 8.12084 15.3857C8.0111 15.1954 8.08513 14.9575 8.28631 14.8542C8.43958 14.7754 9.87918 14.1315 13.1067 15.8041C13.6815 16.1018 14.2502 16.4463 14.8459 16.8565C18.0299 19.0507 21.7338 21.5008 22.5786 21.7986C22.7937 21.8741 22.9034 22.0996 22.8224 22.3031C22.7597 22.4605 22.6012 22.5582 22.4323 22.5582V22.5565Z"
      fill="#64350F"
    />
    <path
      d="M26.6982 24.2192C26.6599 24.2192 26.6215 24.2159 26.5832 24.2086L21.9405 23.3284C21.6478 23.2726 21.4467 23.0175 21.4763 22.7378C21.4859 22.6476 21.4954 22.5713 21.5076 22.4983C21.6391 21.7026 22.0032 20.9734 22.5344 20.4435C23.0918 19.8874 23.7963 19.5814 24.5183 19.5814C24.6777 19.5814 24.8388 19.5962 24.9964 19.6265C25.7924 19.7775 26.47 20.2852 26.9037 21.0571C27.313 21.7847 27.4576 22.6697 27.313 23.5491C27.3008 23.6221 27.286 23.6975 27.266 23.7861C27.2076 24.0429 26.9664 24.22 26.6982 24.22V24.2192Z"
      fill="#FFC78D"
    />
    <path
      d="M24.5169 20.131C24.6371 20.131 24.759 20.1425 24.88 20.1655C26.1655 20.4091 26.9972 21.8872 26.7368 23.4671C26.7255 23.5368 26.7107 23.6057 26.6959 23.6738L22.0531 22.7936C22.0601 22.7239 22.0688 22.6542 22.081 22.5845C22.317 21.1547 23.3664 20.1319 24.5169 20.1319M24.5177 19.036C23.6338 19.036 22.7794 19.4035 22.1106 20.0695C21.4992 20.679 21.0812 21.5124 20.9314 22.4163C20.9175 22.4992 20.9061 22.5853 20.8957 22.6854C20.8365 23.2456 21.2379 23.7558 21.8232 23.8674L26.4659 24.7475C26.5434 24.7623 26.621 24.7688 26.6967 24.7688C27.2349 24.7688 27.7165 24.4153 27.8332 23.901C27.8559 23.8026 27.8724 23.7173 27.8864 23.6344C28.051 22.6386 27.8838 21.6329 27.417 20.802C27.174 20.3706 26.8622 20.0047 26.4894 19.716C26.0784 19.3969 25.6142 19.1869 25.1108 19.0909C24.9157 19.054 24.7163 19.0352 24.5177 19.0352V19.036Z"
      fill="#64350F"
    />
    <path
      d="M28.0137 9.05589C27.9641 9.05589 27.9136 9.05015 27.8631 9.03866C27.5286 8.96074 27.3248 8.64165 27.4076 8.32667L29.4838 0.446347C29.5665 0.131364 29.9053 -0.0605794 30.2397 0.0173461C30.5742 0.0952717 30.7779 0.414356 30.6952 0.729339L28.619 8.60966C28.5485 8.87707 28.2942 9.05589 28.0137 9.05589Z"
      fill="#64350F"
    />
    <path
      d="M22.6926 7.79343C22.3931 7.79343 22.1292 7.59001 22.0778 7.30291L20.9961 1.23128C20.9395 0.911378 21.1686 0.607878 21.5082 0.554561C21.8479 0.500423 22.1701 0.716974 22.2267 1.03688L23.3084 7.10851C23.365 7.42842 23.1359 7.73191 22.7963 7.78523C22.7615 7.79097 22.7266 7.79343 22.6926 7.79343Z"
      fill="#64350F"
    />
    <path
      d="M33.2381 9.87592C33.0927 9.87592 32.9472 9.82834 32.8288 9.73155C32.5684 9.5191 32.5405 9.14752 32.767 8.90226L37.2338 4.05775C37.4594 3.81249 37.8539 3.78624 38.1143 3.99951C38.3747 4.21196 38.4025 4.58354 38.1761 4.8288L33.7093 9.67331C33.5856 9.80702 33.4123 9.87592 33.2381 9.87592Z"
      fill="#64350F"
    />
  </svg>
)

const GiftSuccess = (
  <svg viewBox="0 0 110 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.4307 82.4766L8.77344 88.0211V123.063L53.4307 144.353L98.088 123.063V88.0211L53.4307 82.4766Z"
      fill="#FF5722"
    />
    <g opacity="0.3">
      <path
        d="M53.4307 82.4766L8.77344 88.0211V123.063L53.4307 144.353L98.088 123.063V88.0211L53.4307 82.4766Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M53.4264 82.4766L52.9609 82.5347V144.131L53.4264 144.353L98.0836 123.063V88.0211L53.4264 82.4766Z"
        fill="#232323"
      />
    </g>
    <path
      d="M53.4272 82.4865C53.4851 82.4865 53.541 82.4906 53.5989 82.4968L96.862 87.8692C97.5591 87.9563 98.0845 88.5519 98.0845 89.2574V122.178C98.0845 122.718 97.7742 123.21 97.2902 123.44L54.0271 144.066C53.8389 144.155 53.6341 144.201 53.4293 144.201C53.2245 144.201 53.0197 144.155 52.8315 144.066L9.56842 123.44C9.08231 123.208 8.77204 122.718 8.77204 122.178V89.2574C8.77204 88.5519 9.29537 87.9563 9.99453 87.8692L53.2576 82.4968C53.3135 82.4906 53.3714 82.4865 53.4293 82.4865M53.4272 80.6895C53.2969 80.6895 53.1666 80.6978 53.0363 80.7144L9.77113 86.0867C8.17837 86.2838 6.97656 87.6471 6.97656 89.2574V122.178C6.97656 122.784 7.14618 123.374 7.46887 123.886C7.79156 124.399 8.2487 124.805 8.79479 125.065L52.0579 145.691C52.4819 145.894 52.9556 146 53.4252 146C53.8947 146 54.3684 145.892 54.7924 145.691L98.0555 125.065C98.5995 124.805 99.0588 124.397 99.3814 123.886C99.7041 123.374 99.8737 122.784 99.8737 122.178V89.2574C99.8737 87.6471 98.6719 86.2838 97.0792 86.0867L53.8161 80.7144C53.6858 80.6978 53.5555 80.6895 53.4252 80.6895H53.4272Z"
      fill="#64350F"
    />
    <path
      d="M53.1055 97.9412C62.5612 97.9412 70.2266 90.2488 70.2266 80.7597C70.2266 71.2706 62.5612 63.5781 53.1055 63.5781C43.6498 63.5781 35.9844 71.2706 35.9844 80.7597C35.9844 90.2488 43.6498 97.9412 53.1055 97.9412Z"
      fill="#FFC78D"
    />
    <path
      d="M49.6479 78.2795C49.6479 77.796 50.0389 77.4038 50.5208 77.4038H58.3771C59.341 77.4038 60.1229 76.6195 60.1229 75.6525C60.1229 74.6855 59.341 73.9011 58.3771 73.9011H54.8854V72.1498C54.8854 71.1828 54.1035 70.3984 53.1396 70.3984C52.1756 70.3984 51.3937 71.1828 51.3937 72.1498V73.9011H50.5208C48.111 73.9011 46.1562 75.8621 46.1562 78.2795C46.1562 80.697 48.111 82.6579 50.5208 82.6579H55.7583C56.2403 82.6579 56.6312 83.0501 56.6312 83.5336C56.6312 84.0171 56.2403 84.4093 55.7583 84.4093H47.9021C46.9382 84.4093 46.1562 85.1936 46.1562 86.1606C46.1562 87.1276 46.9382 87.912 47.9021 87.912H51.3937V89.6633C51.3937 90.6303 52.1756 91.4147 53.1396 91.4147C54.1035 91.4147 54.8854 90.6303 54.8854 89.6633V87.912H55.7583C58.1681 87.912 60.1229 85.951 60.1229 83.5336C60.1229 81.1161 58.1681 79.1552 55.7583 79.1552H50.5208C50.0389 79.1552 49.6479 78.763 49.6479 78.2795Z"
      fill="#64350F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.1437 61.6387C42.5363 61.6387 33.9375 70.2647 33.9375 80.9056C33.9375 91.5466 42.5363 100.173 53.1437 100.173C63.7511 100.173 72.3499 91.5466 72.3499 80.9056C72.3499 70.2647 63.7511 61.6387 53.1437 61.6387ZM36.2501 80.7811C36.2501 71.352 43.8685 63.7096 53.2678 63.7096C62.6672 63.7096 70.2855 71.352 70.2855 80.7811C70.2855 90.2102 62.6672 97.8527 53.2678 97.8527C43.8685 97.8527 36.2501 90.2102 36.2501 80.7811Z"
      fill="#64350F"
    />
    <path
      d="M53.4307 96.7044L8.77344 88.18V123.062L53.4307 144.352L98.088 123.062V88.0203L97.9887 88.0078L53.4307 96.7044Z"
      fill="#FF5722"
    />
    <path
      d="M53.4307 96.7044L8.77344 88.18V123.062L53.4307 144.352L98.088 123.062V88.0203L97.9887 88.0078L53.4307 96.7044Z"
      fill="#EA7100"
    />
    <path
      d="M21.4844 129.125L31.7215 134.005L32.1972 92.6536L21.9891 90.7051L21.4844 129.125Z"
      fill="#FFC78D"
    />
    <path
      d="M31.8509 134.208L21.3594 129.207L21.8662 90.5508L32.3308 92.5491L31.8509 134.21V134.208ZM21.6159 129.045L31.5985 133.803L32.0722 92.7586L22.1185 90.8579L21.6179 129.043L21.6159 129.045Z"
      fill="#232323"
    />
    <path
      d="M83.2988 130.185L73.0617 135.066L72.5859 93.7141L82.7941 91.7656L83.2988 130.185Z"
      fill="#FFC78D"
    />
    <path
      d="M72.933 135.269L72.4531 93.6077L82.9178 91.6094L83.4246 130.266L83.3501 130.301L72.933 135.267V135.269ZM72.7117 93.8193L73.1854 134.864L83.1681 130.106L82.6675 91.9206L72.7138 93.8214L72.7117 93.8193Z"
      fill="#232323"
    />
    <g opacity="0.6">
      <path
        d="M97.9829 88.0078L53.4229 96.7044L8.76562 88.178V123.06L53.4229 144.35L53.4953 144.315L9.67784 123.425V91.1453C9.67784 90.6846 10.0957 90.336 10.5487 90.4232L53.4229 98.6093L97.9829 89.9127L98.0801 89.9252V88.0203L97.9829 88.0078Z"
        fill="white"
      />
    </g>
    <path
      d="M53.4254 97.4372C53.3799 97.4372 53.3324 97.4331 53.2868 97.4248L8.6296 88.7406C8.23451 88.6638 7.97595 88.28 8.05248 87.8815C8.12902 87.4831 8.5117 87.2258 8.90885 87.3026L53.4275 95.9598L97.9462 87.3026C98.3413 87.2258 98.726 87.4852 98.8025 87.8815C98.8791 88.2779 98.6205 88.6638 98.2254 88.7406L53.5682 97.4248C53.5227 97.4331 53.4751 97.4372 53.4296 97.4372H53.4254Z"
      fill="#64350F"
    />
    <path
      d="M53.4255 145.085C53.0221 145.085 52.6953 144.757 52.6953 144.353V96.7052C52.6953 96.3005 53.0221 95.9727 53.4255 95.9727C53.8289 95.9727 54.1557 96.3005 54.1557 96.7052V144.353C54.1557 144.757 53.8289 145.085 53.4255 145.085Z"
      fill="#64350F"
    />
    <path
      d="M97.6223 32.4458C97.6223 31.954 97.2954 31.5224 96.8218 31.3917L52.8347 19.1758H52.7251L8.73802 31.3917C8.26639 31.5224 7.9375 31.954 7.9375 32.4458V42.8855C7.9375 43.4416 8.35327 43.9085 8.9035 43.9728L52.0693 48.9426C52.1107 48.9467 52.1521 48.9488 52.1935 48.9488H53.3622C53.4035 48.9488 53.4449 48.9467 53.4863 48.9426L96.6521 43.9728C97.2024 43.9085 97.6181 43.4416 97.6181 42.8855V32.4458H97.6223Z"
      fill="#EA7100"
    />
    <path
      d="M73.6851 24.9671C73.6851 24.9671 82.3811 34.6701 80.9766 40.5363L73.3045 38.2288L73.2031 23.3008L73.6872 24.9671H73.6851Z"
      fill="#FFC78D"
    />
    <g opacity="0.3">
      <path
        d="M8.90625 43.9744L53.2698 49.6954L96.5701 44.4849L95.8047 43.3187L51.6832 35.0371L8.90625 43.9744Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M96.5733 44.4866L95.808 43.3204L52.0547 35.1094C52.3298 39.1599 52.7104 45.0821 53.0021 49.6618L53.273 49.6971L96.5754 44.4866H96.5733Z"
        fill="#232323"
      />
    </g>
    <path
      d="M32.2761 26.5351L31.8645 39.388L24.9453 40.4339L30.5407 25.3398L32.2761 26.5351Z"
      fill="#FFC78D"
    />
    <path
      d="M52.837 19.178L96.824 31.3939C97.2957 31.5246 97.6245 31.9562 97.6245 32.448V42.8877C97.6245 43.4438 97.2088 43.9107 96.6586 43.975L53.4927 48.9448C53.4513 48.9489 53.41 48.951 53.3686 48.951H52.1999C52.1585 48.951 52.1171 48.9489 52.0758 48.9448L8.90991 43.975C8.35968 43.9107 7.94391 43.4438 7.94391 42.8877V32.448C7.94391 31.9562 8.27074 31.5246 8.74443 31.3939L52.7315 19.178H52.8411M52.837 17.3789H52.7273C52.566 17.3789 52.4047 17.4017 52.2495 17.4432L8.26246 29.6591C7.01721 30.0057 6.14844 31.1511 6.14844 32.4459V42.8856C6.14844 44.3568 7.24682 45.5915 8.70306 45.7596L51.8689 50.7293C51.9785 50.7418 52.0882 50.748 52.1978 50.748H53.3665C53.4762 50.748 53.5858 50.7418 53.6954 50.7293L96.8613 45.7596C98.3175 45.5915 99.4159 44.3568 99.4159 42.8856V32.4459C99.4159 31.1511 98.5471 30.0036 97.3019 29.6591L53.3148 17.4432C53.1597 17.3997 52.9983 17.3789 52.837 17.3789Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M8.82696 43.5163V33.0766C8.82696 32.5848 9.15379 32.1532 9.62748 32.0225L53.6145 19.8066H53.7242L97.5064 31.9644C97.372 31.6905 97.1279 31.4788 96.8218 31.3917L52.8347 19.1758H52.7251L8.73802 31.3917C8.2664 31.5224 7.9375 31.954 7.9375 32.4458V42.8855C7.9375 43.4416 8.35327 43.9085 8.9035 43.9728L8.93246 43.9769C8.86627 43.8358 8.82696 43.6802 8.82696 43.5142V43.5163Z"
        fill="white"
      />
    </g>
    <path
      d="M73.2987 39.5135C72.8746 39.5135 72.5312 39.1691 72.5312 38.7437V24.3656C72.5312 23.9402 72.8746 23.5957 73.2987 23.5957C73.7227 23.5957 74.0661 23.9402 74.0661 24.3656V38.7437C74.0661 39.1691 73.7227 39.5135 73.2987 39.5135Z"
      fill="#64350F"
    />
    <g opacity="0.3">
      <path
        d="M53.3551 19.291L52.8359 35.0262L99.0011 44.0922V30.6105L53.3551 19.291Z"
        fill="#232323"
      />
    </g>
    <path
      d="M32.2049 39.6104C31.7809 39.6104 31.4375 39.2659 31.4375 38.8405V25.2074C31.4375 24.782 31.7809 24.4375 32.2049 24.4375C32.629 24.4375 32.9723 24.782 32.9723 25.2074V38.8405C32.9723 39.2659 32.629 39.6104 32.2049 39.6104Z"
      fill="#64350F"
    />
    <path
      d="M7.94089 44.596C7.59959 44.596 7.29552 44.3553 7.22519 44.0087C7.14658 43.6124 7.40101 43.2264 7.7961 43.1455L52.172 34.1937C52.2651 34.175 52.3623 34.175 52.4575 34.1937L97.7622 43.1455C98.1573 43.2244 98.4158 43.6083 98.3372 44.0046C98.2586 44.4009 97.876 44.6603 97.4809 44.5815L52.3189 35.6587L8.08569 44.5815C8.03811 44.5918 7.98847 44.596 7.94089 44.596Z"
      fill="#64350F"
    />
    <path
      d="M52.5019 41.7693C52.4605 41.7693 52.417 41.7651 52.3757 41.7589L35.751 38.9887C35.3331 38.9181 35.0498 38.5218 35.1201 38.1026C35.1904 37.6835 35.5855 37.3992 36.0033 37.4697L52.5019 40.2192L68.9797 37.4697C69.3975 37.3992 69.7926 37.6835 69.8629 38.1026C69.9333 38.5218 69.6499 38.9181 69.232 38.9887L52.628 41.7589C52.5867 41.7651 52.5432 41.7693 52.5019 41.7693Z"
      fill="#64350F"
    />
    <path
      d="M52.5742 35.6812C52.5742 35.6812 52.5701 35.6812 52.5659 35.6812C52.1419 35.677 51.8006 35.3284 51.8047 34.903L51.9578 19.1699C51.9619 18.7445 52.3074 18.4021 52.7335 18.4063C53.1575 18.4104 53.4988 18.759 53.4947 19.1844L53.3416 34.9176C53.3375 35.3409 52.9941 35.6812 52.5742 35.6812Z"
      fill="#64350F"
    />
    <path
      d="M32.6205 18.9457C32.6205 18.9457 14.7671 23.9943 16.2606 42.4748L18.445 41.3812C19.0821 41.0617 19.8578 41.1986 20.3356 41.7278C21.2168 42.7051 22.551 44.7926 23.7259 49.1295C23.8231 49.4864 24.3464 49.4221 24.3485 49.0507C24.3982 41.0845 26.7128 21.1286 48.3702 18.0409L44.8351 14.7188L32.6205 18.9477V18.9457Z"
      fill="#FFC78D"
    />
    <path
      d="M24.0333 50.2616C23.4893 50.2616 23.0135 49.9026 22.8667 49.3631C21.6897 45.0179 20.3617 43.0901 19.677 42.3286C19.4722 42.1024 19.133 42.0422 18.8496 42.1833L16.6653 43.2769C16.3984 43.4097 16.084 43.4014 15.8254 43.2561C15.5669 43.1088 15.3973 42.8432 15.3724 42.5465C15.0187 38.1702 15.6951 34.1549 17.383 30.6128C18.7338 27.7762 20.7299 25.2446 23.3114 23.0886C27.5602 19.5402 31.9351 18.2101 32.355 18.0877L44.5489 13.867C44.8633 13.7591 45.2108 13.8338 45.4528 14.06L48.9879 17.3822C49.2403 17.6187 49.3334 17.9798 49.2299 18.3077C49.1265 18.6355 48.8431 18.8783 48.5018 18.926C27.6636 21.8975 25.2993 40.9839 25.2496 49.0518C25.2455 49.666 24.8028 50.1703 24.1947 50.2491C24.1409 50.2553 24.085 50.2595 24.0313 50.2595L24.0333 50.2616ZM19.1661 40.3116C19.8528 40.3116 20.5272 40.5938 21.005 41.123C21.7166 41.9115 22.664 43.3163 23.5969 45.7732C23.7044 44.3767 23.8906 42.7913 24.2009 41.1126C24.869 37.4999 25.9674 34.1985 27.465 31.3017C29.3473 27.6579 31.8689 24.6449 34.9572 22.3457C38.1654 19.9573 42.0212 18.316 46.4416 17.4548L44.6213 15.7429L32.9155 19.7934C32.899 19.7996 32.8824 19.8038 32.8659 19.8079C32.7873 19.8307 28.4703 21.0965 24.4057 24.51C19.3337 28.768 16.8742 34.3313 17.079 41.0586L18.045 40.5752C18.4007 40.3967 18.7834 40.3116 19.162 40.3116H19.1661Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M25.2279 24.9492C29.3091 21.5212 33.6448 20.2596 33.6882 20.2472C33.7047 20.243 33.7213 20.2368 33.7358 20.2326L45.185 16.2693L44.6224 15.7422L32.9166 19.7927C32.9001 19.7989 32.8835 19.8031 32.8691 19.8072C32.8256 19.8197 28.49 21.0813 24.4088 24.5093C19.3368 28.7674 16.8773 34.3306 17.0821 41.058L17.8888 40.6533C17.9075 34.3036 20.3669 29.0267 25.2259 24.9472L25.2279 24.9492Z"
        fill="white"
      />
    </g>
    <g opacity="0.6">
      <path
        d="M21.8229 41.5626C21.5209 41.2264 21.1403 40.9919 20.7266 40.8633C20.8238 40.9421 20.9189 41.0272 21.0058 41.1227C21.7174 41.9112 22.6648 43.3181 23.5977 45.777C23.6287 45.3848 23.668 44.974 23.7114 44.5527C23.0185 43.0878 22.3545 42.1519 21.8229 41.5626Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.23">
      <path
        d="M48.3766 18.037L44.8415 14.7148L43.9624 15.0199L45.9978 16.9331C42.0179 17.4996 38.6938 18.6388 35.9158 20.166L26.8805 21.0894L24.5514 23.0691L24.5845 23.2164C24.5845 23.2164 25.416 23.3616 26.8288 23.4218C28.465 23.4903 29.3565 25.3724 28.3637 26.6755C23.9681 32.4463 22.5366 39.5077 22.125 44.5729C22.6566 45.7121 23.211 47.1958 23.7343 49.1236C23.8315 49.4805 24.3549 49.4161 24.3569 49.0447C24.3942 42.8257 25.8152 29.3025 36.737 22.1996C37.4196 22.0004 38.1126 21.7701 38.8179 21.5065L39.1551 20.8197C41.7552 19.5186 44.8022 18.5454 48.3807 18.0349L48.3766 18.037Z"
        fill="#232323"
      />
    </g>
    <path
      d="M73.9225 18.6593C73.9225 18.6593 91.8504 23.4361 90.6361 41.9374L88.4352 40.877C87.794 40.5678 87.0183 40.7151 86.5487 41.2526C85.6841 42.2424 84.3789 44.3507 83.2701 48.7041C83.1791 49.0631 82.6537 49.005 82.6454 48.6357C82.4758 40.6716 79.8591 20.753 58.1562 17.9953L61.6417 14.6191L73.9204 18.6614L73.9225 18.6593Z"
      fill="#FFC78D"
    />
    <path
      d="M82.9719 49.8427C82.9243 49.8427 82.8767 49.8406 82.8271 49.8344C82.2189 49.7638 81.768 49.2679 81.7556 48.6537C81.5839 40.5879 78.932 21.5409 58.0503 18.8869C57.709 18.8433 57.4215 18.6067 57.3119 18.2789C57.2023 17.951 57.2912 17.5899 57.5394 17.3492L61.0249 13.9731C61.2648 13.7407 61.6103 13.6619 61.9267 13.7656L74.1827 17.7995C74.6047 17.9157 79.0003 19.1794 83.3008 22.6614C85.9154 24.78 87.9487 27.2805 89.3429 30.0943C91.0846 33.6115 91.821 37.6143 91.5335 41.9948C91.5148 42.2936 91.3473 42.5613 91.0908 42.7127C90.8343 42.8642 90.5199 42.8767 90.251 42.7459L88.0501 41.6856C87.7667 41.5486 87.4275 41.613 87.2247 41.8433C86.5504 42.6152 85.2534 44.5616 84.1406 48.9255C83.9999 49.4754 83.52 49.8427 82.9698 49.8427H82.9719ZM60.0878 17.3783C64.5186 18.173 68.3991 19.7542 71.6447 22.0949C74.7681 24.3464 77.3351 27.322 79.2713 30.9347C80.8123 33.8107 81.9604 37.0935 82.6843 40.6958C83.0194 42.3683 83.2304 43.9536 83.3587 45.346C84.2543 42.8746 85.181 41.4573 85.8781 40.6564C86.6083 39.8222 87.8184 39.5773 88.8258 40.0629L89.8 40.5319C89.9035 33.7796 87.3426 28.2413 82.1755 24.0558C78.0405 20.7088 73.738 19.5364 73.6966 19.5239C73.6801 19.5198 73.6635 19.5156 73.647 19.5094L61.8812 15.6373L60.0858 17.3762L60.0878 17.3783Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M79.9143 30.4111C75.9221 22.9657 69.4745 18.411 60.7309 16.8526L61.9596 15.6636L61.8872 15.6387L60.0938 17.3755C68.8374 18.9339 75.285 23.4886 79.2772 30.934C81.9642 35.9452 82.9902 41.3757 83.3646 45.3494C83.5466 44.8452 83.7307 44.3866 83.9148 43.9654C83.4784 40.0892 82.4151 35.0696 79.9164 30.409L79.9143 30.4111Z"
        fill="white"
      />
      <path
        d="M89.4695 39.5415C88.4642 39.0581 87.2499 39.3008 86.5218 40.135C86.5198 40.1371 86.5177 40.1412 86.5156 40.1433C87.2106 39.749 88.0794 39.7034 88.8303 40.0665L89.8046 40.5355C89.8087 40.2554 89.8066 39.9773 89.8025 39.7013L89.4695 39.5415Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.15">
      <path
        d="M82.7028 23.3323C83.071 23.3884 83.3854 23.4527 83.6254 23.5274L79.538 21.1494C79.538 21.1494 79.5297 21.1452 79.5277 21.1431L79.4739 21.112C76.4166 19.3254 73.9282 18.6614 73.9282 18.6614L61.6494 14.6191L60.3359 15.8912L72.0851 19.7591C72.0851 19.7591 72.865 19.9666 74.0895 20.4646L67.6564 19.6927L71.1502 22.4629C71.1502 22.4629 73.1794 22.6953 75.6057 22.8737C78.0755 23.0563 80.4233 24.0628 82.2271 25.7664C85.7146 29.0616 88.805 33.967 88.8567 41.0762L90.6439 41.9374C91.252 32.6597 87.0488 26.835 82.7049 23.3323H82.7028Z"
        fill="#232323"
      />
    </g>
    <path
      d="M51.5587 13.7206C51.5587 13.7206 43.1398 3.50917 32.125 1.1851C32.125 1.1851 26.6724 -0.479103 21.4886 4.23959C20.0779 5.52406 18.9526 7.09281 18.1232 8.81303C17.5688 9.96054 16.8593 11.5459 16.3298 13.1229C15.279 16.2521 16.9482 19.6864 20.0862 20.6803C21.8279 21.2323 24.248 21.5373 27.6135 21.3464C27.6135 21.3464 43.0509 20.6201 48.2491 19.8005L51.6849 18.6177L51.5566 13.7226L51.5587 13.7206Z"
      fill="#FFC78D"
    />
    <g opacity="0.6">
      <path
        d="M17.5122 15.0975C18.0418 13.5225 18.7513 11.9371 19.3056 10.7876C20.1351 9.06733 21.2604 7.49858 22.6711 6.21412C27.8528 1.49542 33.3074 3.15963 33.3074 3.15963C42.0449 5.00228 49.1461 11.8064 51.711 14.5434L51.7069 14.4002C51.7069 14.4002 43.288 4.18678 32.2732 1.86479C32.2732 1.86479 26.8206 0.200584 21.6369 4.91928C20.2261 6.20374 19.1008 7.77249 18.2714 9.49272C17.717 10.6402 17.0075 12.2256 16.478 13.8026C15.7126 16.0831 16.3911 18.5234 18.0314 20.0652C17.1792 18.6189 16.9351 16.8135 17.5122 15.0975Z"
        fill="white"
      />
    </g>
    <path
      d="M34.2704 15.9127L27.3677 14.0098L21.4104 13.8438C19.3584 13.462 16.9403 14.5431 16.0157 15.0121C16.0053 17.525 17.5733 19.8822 20.0845 20.677C21.8261 21.229 24.2463 21.534 27.6118 21.3431C27.6118 21.3431 43.0491 20.6168 48.2473 19.7972L50.0863 19.1643V18.9734L45.6824 18.2678L34.2704 15.9127Z"
      fill="white"
    />
    <path
      d="M25.6975 21.5275C23.4759 21.5275 21.5832 21.2826 20.0504 20.7991C18.8093 20.4069 17.7565 19.6329 17.0035 18.5622C16.2816 17.533 15.8865 16.2734 15.8907 15.0118V14.9329L15.961 14.8976C16.9104 14.4142 19.343 13.3331 21.426 13.7149L27.3896 13.8809L34.3025 15.7879L45.7063 18.143L50.2198 18.8652L50.2136 19.2574L48.2692 19.9255C43.1248 20.7369 27.7743 21.4652 27.6192 21.4715C26.9531 21.5088 26.3098 21.5275 25.6934 21.5275H25.6975ZM16.1472 15.0906C16.1699 17.616 17.7606 19.8052 20.127 20.5543C22.0548 21.1643 24.5702 21.3864 27.6088 21.2141C27.764 21.2058 43.0793 20.4796 48.2216 19.6724L49.9447 19.0789L45.6608 18.3941L34.2446 16.0369H34.2405L27.3523 14.1361L21.3991 13.9701H21.3888C19.4278 13.6049 17.1194 14.603 16.1451 15.0906H16.1472Z"
      fill="#232323"
    />
    <path
      d="M25.6994 22.3002C23.395 22.3002 21.4237 22.045 19.8165 21.5366C18.0934 20.9908 16.691 19.7935 15.8656 18.1625C15.0237 16.4983 14.8872 14.6059 15.4809 12.8358C16.0373 11.182 16.7861 9.52195 17.3157 8.42216C18.2258 6.53178 19.4276 4.90286 20.8859 3.57481C23.7487 0.966459 26.7005 0.217361 28.6718 0.047206C30.6514 -0.122949 32.0683 0.236037 32.3393 0.312814C34.927 0.862706 37.5851 1.87949 40.2369 3.33618C42.353 4.49822 44.4732 5.94246 46.5355 7.62741C50.0458 10.4931 52.1578 13.0413 52.2467 13.1492C52.375 13.3048 52.4474 13.4978 52.4515 13.6991L52.5798 18.5941C52.5901 18.9863 52.346 19.3412 51.9758 19.4677L48.5399 20.6505C48.4903 20.6671 48.4407 20.6796 48.3889 20.6879C43.2135 21.5034 28.3533 22.211 27.6603 22.2421C26.9818 22.2794 26.3282 22.3002 25.6994 22.3002ZM29.7226 1.79856C29.4206 1.79856 29.0938 1.81101 28.7463 1.84421C26.3075 2.07247 24.0673 3.1017 22.0898 4.90286C20.8032 6.07527 19.74 7.52159 18.9291 9.20239C18.4202 10.2565 17.7045 11.8419 17.1791 13.4065C16.7385 14.7158 16.8399 16.1144 17.4625 17.3449C18.0686 18.5402 19.0966 19.42 20.3564 19.8184C22.1953 20.4015 24.6196 20.6111 27.5631 20.4451C27.5652 20.4451 27.5693 20.4451 27.5714 20.4451C27.7244 20.4389 42.8681 19.7188 48.0332 18.922L50.7719 17.9778L50.6684 14.0622C50.0727 13.3795 48.1635 11.2692 45.373 8.99488C42.2806 6.47368 37.4382 3.22413 31.938 2.06417C31.9132 2.05794 31.8863 2.05172 31.8615 2.04342C31.8532 2.04134 31.0155 1.79856 29.7206 1.79856H29.7226Z"
      fill="#64350F"
    />
    <path
      d="M48.6701 19.4281C46.0472 19.4281 34.3828 16.6309 29.466 15.4232C28.0635 15.0787 26.7562 14.8359 25.4696 14.6803C21.9179 14.2528 19.6715 14.5745 18.4139 14.9189C17.0714 15.2862 16.5481 15.7552 16.5439 15.7593C16.5501 15.7531 16.5543 15.749 16.5543 15.749L15.4766 14.6513C15.5469 14.5828 16.2088 13.9603 17.8161 13.4871C19.8639 12.8854 22.5013 12.7712 25.6537 13.151C27.0024 13.3128 28.3676 13.566 29.8321 13.9271C37.6966 15.8589 46.7836 17.9153 48.697 17.8884C48.7011 17.8884 48.7053 17.8884 48.7073 17.8884C49.1272 17.8884 49.4685 18.2266 49.4747 18.6478C49.4809 19.0732 49.1417 19.4239 48.7177 19.4281C48.7011 19.4281 48.6866 19.4281 48.6701 19.4281Z"
      fill="#64350F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
      <path
        d="M47.6814 19.8897C47.6814 19.8897 49.5369 18.5928 42.6259 17.8458L31.6194 15.2499L22.7433 13.7559L18.0292 14.119C18.0292 14.119 15.5552 14.4095 15.8717 16.0965C15.8717 16.0965 17.0528 21.141 22.4082 21.2593L47.6835 19.8897H47.6814Z"
        fill="#FFC78D"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.15">
      <path
        d="M49.359 11.8184C46.5479 13.9474 42.9218 14.6965 39.5149 13.8104C33.841 12.3371 24.9567 10.9261 17.8906 13.8623C17.8906 13.8623 21.8043 12.6774 36.7659 16.3046C39.8273 17.0475 43.3231 17.0952 47.3732 18.3859C47.3732 18.3859 48.6764 14.7401 50.747 13.3705L49.3611 11.8184H49.359Z"
        fill="#232323"
      />
    </g>
    <path
      d="M54.4839 13.696C54.4839 13.696 62.9111 3.49083 73.9301 1.17921C73.9301 1.17921 79.3848 -0.480838 84.5623 4.24408C85.973 5.53062 87.0941 7.09937 87.9236 8.82167C88.4759 9.96918 89.1854 11.5566 89.7129 13.1316C90.7616 16.2608 89.0882 19.6929 85.9482 20.6848C84.2065 21.2347 81.7863 21.5377 78.4208 21.3447C78.4208 21.3447 62.9835 20.6039 57.7874 19.7801L54.3516 18.5931L54.4839 13.6981V13.696Z"
      fill="#FFC78D"
    />
    <g opacity="0.6">
      <path
        d="M73.471 3.35502C73.471 3.35502 78.9257 1.69497 84.1032 6.41989C85.514 7.70643 86.6351 9.27517 87.4646 10.9975C88.0169 12.145 88.7264 13.7324 89.2538 15.3074C89.6407 16.4632 89.6551 17.6584 89.3635 18.7582C90.2405 17.3057 90.4949 15.4796 89.9158 13.7449C89.3883 12.1699 88.6788 10.5825 88.1265 9.43495C87.2991 7.71265 86.1759 6.1439 84.7652 4.85736C79.5876 0.132443 74.133 1.79249 74.133 1.79249C63.114 4.10412 54.6868 14.3093 54.6868 14.3093L54.6641 15.1455C56.8236 12.7737 64.2372 5.29313 73.471 3.35502Z"
        fill="white"
      />
    </g>
    <path
      d="M72.1013 15.9579L79.006 14.0613L84.9633 13.9015C87.0174 13.5218 89.4313 14.605 90.356 15.076C90.3642 17.5889 88.7922 19.9441 86.281 20.7368C84.5393 21.2867 82.1191 21.5896 78.7536 21.3966C78.7536 21.3966 63.3163 20.6558 58.1202 19.832L56.2812 19.1971V19.0062L60.6872 18.3048L72.1013 15.96V15.9579Z"
      fill="white"
    />
    <path
      d="M80.3649 22.3013C79.7257 22.3013 79.0617 22.2805 78.3729 22.2411C77.6861 22.2079 62.8197 21.4879 57.6443 20.6661C57.5925 20.6578 57.5429 20.6454 57.4933 20.6288L54.0574 19.4419C53.6872 19.3132 53.4431 18.9604 53.4534 18.5683L53.5858 13.6732C53.592 13.4719 53.6644 13.2789 53.7906 13.1233C53.8796 13.0154 55.9936 10.4714 59.5059 7.60777C61.5703 5.92489 63.6926 4.48272 65.8087 3.32276C68.4626 1.86814 71.1207 0.853434 73.7104 0.307692C73.9814 0.23299 75.3983 -0.125996 77.3779 0.0462341C79.3492 0.218464 82.301 0.971713 85.1618 3.58007C86.618 4.90811 87.8177 6.54118 88.7258 8.43157C89.2554 9.53135 90.0021 11.1914 90.5565 12.8473C91.1501 14.6173 91.0115 16.5098 90.1676 18.174C89.3402 19.8029 87.9356 21.0002 86.2126 21.5439C84.6136 22.0481 82.6506 22.3034 80.3607 22.3034L80.3649 22.3013ZM58.0021 18.9023C63.1672 19.7054 78.3088 20.4379 78.4618 20.4462C78.4639 20.4462 78.468 20.4462 78.4701 20.4462C81.4136 20.6163 83.84 20.4068 85.6789 19.8278C86.9386 19.4294 87.9687 18.5517 88.5748 17.3564C89.1995 16.1259 89.3009 14.7273 88.8623 13.4179C88.3369 11.8513 87.6233 10.2659 87.1165 9.21179C86.3077 7.52891 85.2445 6.08259 83.9599 4.91018C81.9845 3.10695 79.7443 2.07565 77.3055 1.84324C75.4708 1.66893 74.1986 2.0383 74.1862 2.04037C74.1614 2.04867 74.1386 2.05282 74.1138 2.05905C68.5971 3.21693 63.7423 6.47271 60.6395 9.00221C57.8573 11.2703 55.9626 13.3598 55.371 14.0384L55.2655 17.9561L58.0042 18.9023H58.0021Z"
      fill="#64350F"
    />
    <path
      d="M57.3636 19.4072C57.3449 19.4072 57.3284 19.4072 57.3118 19.4072C56.8878 19.401 56.5486 19.0503 56.5548 18.6249C56.561 18.1995 56.9106 17.8592 57.3346 17.8655C57.347 17.8655 57.3615 17.8655 57.376 17.8655C59.3783 17.8655 68.3929 15.834 76.2036 13.9228C77.6681 13.5638 79.0354 13.3128 80.382 13.1509C83.5365 12.7753 86.1718 12.8915 88.2197 13.4954C89.8248 13.9685 90.4868 14.5931 90.5571 14.6616L89.4773 15.7572C89.4773 15.7572 89.4815 15.7613 89.4877 15.7676C89.4835 15.7634 88.9602 15.2944 87.6177 14.9251C86.3601 14.5806 84.1157 14.2569 80.562 14.6802C79.2754 14.8338 77.9702 15.0745 76.5656 15.419C71.6508 16.6225 59.9906 19.4051 57.3615 19.4051L57.3636 19.4072Z"
      fill="#64350F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
      <path
        d="M58.1165 19.8318C58.1165 19.8318 56.2631 18.5328 63.174 17.792L74.1848 15.2064L83.0628 13.7207L87.777 14.088C87.777 14.088 90.251 14.3826 89.9324 16.0676C89.9324 16.0676 88.7451 21.1121 83.3897 21.2241L58.1165 19.8297V19.8318Z"
        fill="#FFC78D"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.13">
      <path
        d="M57.1869 11.877C59.996 14.008 63.62 14.7613 67.031 13.8794C72.707 12.4123 81.5913 11.0096 88.6553 13.9541C88.6553 13.9541 84.7437 12.7651 69.7759 16.3778C66.7145 17.1165 63.2166 17.1621 59.1665 18.4487C59.1665 18.4487 57.8675 14.8007 55.7969 13.4291L57.1848 11.877H57.1869Z"
        fill="#232323"
      />
    </g>
    <path
      d="M47.5898 19.2197C47.1161 19.2197 46.7252 18.8503 46.6962 18.3772C46.6859 18.1967 46.6797 18.0452 46.6797 17.8979C46.6797 14.2001 49.3481 11.1934 52.6267 11.1934C55.9053 11.1934 58.5737 14.2001 58.5737 17.8979C58.5737 18.0452 58.5675 18.1967 58.5571 18.3772C58.5282 18.8503 58.1351 19.2197 57.6635 19.2197H47.5919H47.5898Z"
      fill="#FFC78D"
    />
    <path
      d="M52.6239 12.0919C55.4143 12.0919 57.6752 14.692 57.6752 17.898C57.6752 18.0411 57.669 18.1802 57.6608 18.3192H47.5891C47.5809 18.1802 47.5747 18.0391 47.5747 17.898C47.5747 14.692 49.8355 12.0919 52.626 12.0919M52.6239 10.2949C51.6683 10.2949 50.7457 10.5087 49.8769 10.932C49.0578 11.3304 48.3255 11.8948 47.7029 12.6128C46.4639 14.0363 45.7812 15.9142 45.7812 17.9C45.7812 18.066 45.7875 18.2362 45.7999 18.4354C45.8599 19.3816 46.6418 20.1203 47.5891 20.1203H57.6608C58.6061 20.1203 59.39 19.3816 59.45 18.4354C59.4624 18.2362 59.4686 18.066 59.4686 17.9C59.4686 15.9142 58.786 14.0363 57.547 12.6128C56.9244 11.8969 56.1921 11.3304 55.373 10.932C54.5042 10.5107 53.5796 10.2949 52.626 10.2949H52.6239Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M48.1573 18.2859C48.1573 15.0799 50.4182 12.4798 53.2086 12.4798C54.0174 12.4798 54.7828 12.6998 55.4613 13.0899C54.6545 12.4612 53.6803 12.0918 52.6294 12.0918C49.839 12.0918 47.5781 14.6919 47.5781 17.8978C47.5781 18.041 47.5843 18.18 47.5926 18.3191H48.1573C48.1573 18.3066 48.1573 18.2962 48.1573 18.2859Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.12">
      <path
        d="M55.7498 12.9531C55.7498 12.9531 56.3166 17.4975 47.1406 17.2942V18.9833H58.3934L58.5506 16.626L57.0985 13.3391L55.7477 12.9531H55.7498Z"
        fill="#606060"
      />
    </g>
    <path
      d="M21.9912 130.359C21.5941 130.359 21.2734 130.037 21.2734 129.639V91.4329C21.2734 91.0345 21.5941 90.7129 21.9912 90.7129C22.3884 90.7129 22.709 91.0345 22.709 91.4329V129.639C22.709 130.037 22.3884 130.359 21.9912 130.359Z"
      fill="#64350F"
    />
    <path
      d="M32.2021 135.088C31.805 135.088 31.4844 134.766 31.4844 134.368V93.2337C31.4844 92.8353 31.8071 92.5137 32.2021 92.5137C32.5972 92.5137 32.9199 92.8374 32.9199 93.2337V134.368C32.9199 134.766 32.5972 135.088 32.2021 135.088Z"
      fill="#64350F"
    />
    <path
      d="M73.2959 136.002C72.8987 136.002 72.5781 135.681 72.5781 135.282V93.6771C72.5781 93.2787 72.9008 92.957 73.2959 92.957C73.691 92.957 74.0137 93.2787 74.0137 93.6771V135.282C74.0137 135.681 73.691 136.002 73.2959 136.002Z"
      fill="#64350F"
    />
    <path
      d="M83.374 130.691C82.9769 130.691 82.6562 130.369 82.6562 129.971V91.0736C82.6562 90.6752 82.9789 90.3535 83.374 90.3535C83.7691 90.3535 84.0918 90.6752 84.0918 91.0736V129.971C84.0918 130.369 83.7691 130.691 83.374 130.691Z"
      fill="#64350F"
    />
    <path
      d="M15.6189 77.3094C15.3852 77.3094 15.1659 77.1642 15.0832 76.9318C14.3633 74.9169 13.3891 73.6158 12.2865 73.1386C12.181 73.5307 12.0362 73.9167 11.8521 74.2902C11.4943 75.0144 10.8655 75.5332 10.1249 75.7179C9.3058 75.9212 8.59837 75.6639 8.13709 74.9916C7.85784 74.5869 7.88266 74.0391 8.20121 73.4892C8.71627 72.6053 9.9429 71.7773 11.3557 71.796C11.4012 70.9348 11.2275 70.0363 10.8344 69.1088C10.3566 67.9779 9.69675 67.1748 9.53541 66.9881C9.51058 66.9694 9.48576 66.9528 9.46094 66.9362C9.20031 67.7081 8.7142 68.3908 8.04814 68.895C6.49468 70.0716 4.9495 70.2625 3.58014 69.447C2.61621 68.8722 2.20664 67.6832 2.60587 66.6187C3.04646 65.4463 3.7994 64.6681 4.78402 64.3673C5.82035 64.0519 7.07801 64.276 8.54045 65.0334C8.48874 63.0703 7.893 61.5846 6.76359 60.6093C4.48408 58.6401 0.713174 59.3186 0.673872 59.3269C0.365662 59.385 0.0677957 59.1817 0.00987721 58.8704C-0.0480413 58.5612 0.154674 58.2624 0.462883 58.2043C0.638707 58.1711 4.80057 57.4137 7.49998 59.7399C9.02448 61.0534 9.75467 63.0579 9.674 65.7015C9.86223 65.826 10.0525 65.9568 10.247 66.0958C10.278 66.1186 10.309 66.1456 10.3339 66.1726C10.3711 66.212 11.2378 67.1665 11.8625 68.6253C12.3507 69.7645 12.5534 70.8996 12.4748 71.989C13.6786 72.4019 15.0914 73.5805 16.1505 76.5437C16.256 76.8405 16.103 77.1663 15.8072 77.2742C15.743 77.297 15.6789 77.3074 15.6148 77.3074L15.6189 77.3094ZM9.08033 74.3525C9.21479 74.5434 9.41129 74.7177 9.85189 74.6098C10.2656 74.506 10.6234 74.2051 10.8324 73.7839C10.9689 73.5079 11.0785 73.2257 11.1633 72.9393C10.9875 72.9518 10.8096 72.9829 10.6297 73.0327C10.1663 73.1635 9.72157 73.4311 9.40923 73.7673C9.1217 74.0765 9.07826 74.2985 9.08033 74.3525ZM5.75415 65.3695C5.52455 65.3695 5.31149 65.3986 5.11498 65.4587C4.47167 65.6559 3.98557 66.1809 3.67116 67.0213C3.46637 67.567 3.67116 68.1771 4.16139 68.4676C5.11498 69.0341 6.19061 68.8722 7.36346 67.9862C7.92196 67.5629 8.30671 66.9652 8.46185 66.2929C7.41311 65.6829 6.49882 65.3695 5.75415 65.3695Z"
      fill="#64350F"
    />
    <path
      d="M88.8899 81.3113C88.8382 81.3113 88.7844 81.303 88.7327 81.2844C88.4886 81.1972 88.3624 80.9274 88.4493 80.6826C89.3222 78.2402 90.4847 77.2712 91.4756 76.9309C91.4094 76.0344 91.579 75.0986 91.9803 74.1607C92.4953 72.9592 93.209 72.1728 93.24 72.1416C93.2628 72.1188 93.2855 72.096 93.3124 72.0773C93.4717 71.9632 93.6289 71.8553 93.784 71.7536C93.7178 69.5768 94.3198 67.9251 95.5754 66.844C97.799 64.9287 101.227 65.5512 101.371 65.5803C101.626 65.628 101.793 65.8749 101.744 66.1302C101.696 66.3854 101.45 66.5535 101.196 66.5037C101.14 66.4933 98.0514 65.9455 96.1814 67.5599C95.2527 68.3629 94.7604 69.5851 94.719 71.2016C95.9229 70.577 96.9592 70.3923 97.8114 70.6517C98.6223 70.8987 99.2428 71.5399 99.6048 72.5068C99.9337 73.3846 99.5966 74.364 98.8023 74.8371C97.6749 75.5095 96.4028 75.3517 95.1224 74.3827C94.5742 73.9677 94.1729 73.4053 93.9598 72.7683C93.9392 72.7828 93.9185 72.7973 93.8978 72.8119C93.7675 72.9633 93.2235 73.6253 92.8284 74.5591C92.5057 75.3227 92.3629 76.0635 92.3981 76.7711C93.5627 76.7566 94.5721 77.4372 94.9962 78.1655C95.2609 78.6179 95.2796 79.0682 95.05 79.4023C94.6693 79.9563 94.0881 80.168 93.4117 80.002C92.8015 79.8505 92.2843 79.423 91.9906 78.8254C91.8396 78.5183 91.7196 78.2008 91.6328 77.8771C90.7247 78.2693 89.9221 79.3421 89.3284 81.0001C89.2602 81.191 89.0802 81.3113 88.8878 81.3113H88.8899ZM92.5615 77.7132C92.6298 77.9497 92.7208 78.1801 92.8325 78.4083C93.0042 78.7548 93.2979 79.0039 93.6392 79.0889C94.0012 79.1782 94.1646 79.035 94.2743 78.8773C94.3012 78.6905 93.8502 78.0327 93.0001 77.792C92.8532 77.7505 92.7063 77.7235 92.5615 77.7152V77.7132ZM94.7831 72.2371C94.9114 72.789 95.2258 73.2829 95.6871 73.6315C96.651 74.3619 97.5384 74.4968 98.3244 74.0279C98.7278 73.7871 98.8974 73.2871 98.7278 72.8368C98.4692 72.1458 98.0679 71.7121 97.5384 71.5502C96.8537 71.3406 95.9125 71.5772 94.7831 72.2371Z"
      fill="#64350F"
    />
    <path
      d="M88.9258 55.0985C88.8762 55.0985 88.8265 55.0923 88.779 55.0757C88.508 54.9947 88.3549 54.7084 88.4356 54.4365L89.3437 51.409C89.4243 51.1372 89.7098 50.9836 89.9808 51.0646C90.2517 51.1455 90.4048 51.4318 90.3241 51.7037L89.4161 54.7312C89.3499 54.9532 89.1451 55.0964 88.9258 55.0964V55.0985Z"
      fill="#64350F"
    />
    <path
      d="M86.2461 64.0418C86.1965 64.0418 86.1468 64.0356 86.0993 64.019C85.8283 63.9381 85.6752 63.6517 85.7559 63.3799L86.664 60.3524C86.7446 60.0805 87.0301 59.927 87.3011 60.0079C87.5721 60.0888 87.7251 60.3752 87.6445 60.647L86.7364 63.6745C86.6702 63.8966 86.4654 64.0398 86.2461 64.0398V64.0418Z"
      fill="#64350F"
    />
    <path
      d="M90.7547 56.595C90.5726 56.595 90.3947 56.4975 90.3037 56.3253C90.1693 56.0763 90.2624 55.765 90.5126 55.6301L93.2865 54.134C93.5348 53.9991 93.845 54.0925 93.9795 54.3436C94.1139 54.5926 94.0209 54.9038 93.7706 55.0387L90.9967 56.5348C90.9201 56.5764 90.8374 56.5971 90.7547 56.5971V56.595Z"
      fill="#64350F"
    />
    <path
      d="M82.5515 61.0169C82.3695 61.0169 82.1916 60.9194 82.1006 60.7471C81.9661 60.4981 82.0592 60.1869 82.3095 60.052L85.0834 58.5559C85.3316 58.421 85.6419 58.5144 85.7764 58.7655C85.9108 59.0145 85.8177 59.3257 85.5674 59.4606L82.7936 60.9567C82.717 60.9982 82.6343 61.019 82.5515 61.019V61.0169Z"
      fill="#64350F"
    />
    <path
      d="M94.0063 59.8569C93.9566 59.8569 93.907 59.8507 93.8594 59.8341L90.8415 58.9231C90.5705 58.8422 90.4174 58.5558 90.4981 58.284C90.5788 58.0122 90.8642 57.8586 91.1352 57.9396L94.1531 58.8505C94.4241 58.9314 94.5772 59.2178 94.4965 59.4896C94.4303 59.7117 94.2255 59.8548 94.0063 59.8548V59.8569Z"
      fill="#64350F"
    />
    <path
      d="M85.0902 57.1675C85.0405 57.1675 84.9909 57.1612 84.9433 57.1446L81.9274 56.2337C81.6564 56.1528 81.5033 55.8664 81.584 55.5946C81.6647 55.3227 81.9502 55.1692 82.2211 55.2501L85.237 56.1611C85.508 56.242 85.6611 56.5283 85.5804 56.8002C85.5142 57.0222 85.3094 57.1654 85.0902 57.1654V57.1675Z"
      fill="#64350F"
    />
    <path
      d="M90.9883 63.5593C90.8062 63.5593 90.6283 63.4618 90.5373 63.2895L89.0459 60.5069C88.9115 60.2579 89.0045 59.9466 89.2548 59.8117C89.5031 59.6769 89.8133 59.7702 89.9478 60.0213L91.4392 62.804C91.5736 63.053 91.4806 63.3642 91.2303 63.4991C91.1537 63.5406 91.071 63.5614 90.9883 63.5614V63.5593Z"
      fill="#64350F"
    />
    <path
      d="M86.582 55.3366C86.4 55.3366 86.2221 55.2391 86.1311 55.0669L84.6397 52.2842C84.5052 52.0352 84.5983 51.724 84.8486 51.5891C85.0968 51.4542 85.4071 51.5476 85.5415 51.7987L87.0329 54.5813C87.1674 54.8303 87.0743 55.1416 86.824 55.2765C86.7475 55.318 86.6647 55.3387 86.582 55.3387V55.3366Z"
      fill="#64350F"
    />
    <path
      d="M30.0664 62.1177C30.0168 62.1177 29.9672 62.1114 29.9196 62.0948C29.6486 62.0139 29.4955 61.7276 29.5762 61.4557L30.3933 58.7332C30.4739 58.4614 30.7594 58.3078 31.0304 58.3888C31.3014 58.4697 31.4544 58.7561 31.3737 59.0279L30.5567 61.7504C30.4905 61.9724 30.2857 62.1156 30.0664 62.1156V62.1177Z"
      fill="#64350F"
    />
    <path
      d="M27.6524 70.1626C27.6027 70.1626 27.5531 70.1564 27.5055 70.1398C27.2345 70.0588 27.0815 69.7725 27.1621 69.5006L27.9792 66.7782C28.0599 66.5063 28.3453 66.3528 28.6163 66.4337C28.8873 66.5146 29.0404 66.801 28.9597 67.0728L28.1426 69.7953C28.0764 70.0173 27.8716 70.1605 27.6524 70.1605V70.1626Z"
      fill="#64350F"
    />
    <path
      d="M31.7078 63.4632C31.5258 63.4632 31.3479 63.3657 31.2569 63.1934C31.1224 62.9444 31.2155 62.6332 31.4658 62.4983L33.9625 61.1516C34.2107 61.0167 34.521 61.1101 34.6554 61.3612C34.7899 61.6102 34.6968 61.9214 34.4465 62.0563L31.9498 63.403C31.8733 63.4445 31.7905 63.4653 31.7078 63.4653V63.4632Z"
      fill="#64350F"
    />
    <path
      d="M24.3328 67.4417C24.1508 67.4417 23.9729 67.3442 23.8819 67.172C23.7474 66.9229 23.8405 66.6117 24.0908 66.4768L26.5875 65.1301C26.8357 64.9952 27.146 65.0886 27.2804 65.3397C27.4149 65.5887 27.3218 65.8999 27.0715 66.0348L24.5748 67.3815C24.4983 67.423 24.4155 67.4438 24.3328 67.4438V67.4417Z"
      fill="#64350F"
    />
    <path
      d="M34.6397 66.3984C34.5901 66.3984 34.5404 66.3922 34.4929 66.3756L31.779 65.5559C31.508 65.475 31.3549 65.1887 31.4356 64.9168C31.5163 64.645 31.8017 64.4914 32.0727 64.5724L34.7866 65.392C35.0576 65.4729 35.2106 65.7593 35.13 66.0311C35.0638 66.2532 34.859 66.3964 34.6397 66.3964V66.3984Z"
      fill="#64350F"
    />
    <path
      d="M26.6163 63.9785C26.5666 63.9785 26.517 63.9723 26.4694 63.9557L23.7555 63.136C23.4845 63.0551 23.3315 62.7687 23.4121 62.4969C23.4928 62.2251 23.7783 62.0715 24.0492 62.1524L26.7631 62.9721C27.0341 63.053 27.1872 63.3394 27.1065 63.6112C27.0403 63.8332 26.8355 63.9764 26.6163 63.9764V63.9785Z"
      fill="#64350F"
    />
    <path
      d="M31.9253 69.7324C31.7432 69.7324 31.5653 69.6349 31.4743 69.4627L30.1319 66.9581C29.9974 66.709 30.0905 66.3978 30.3408 66.2629C30.589 66.128 30.8993 66.2214 31.0337 66.4725L32.3762 68.9771C32.5106 69.2261 32.4176 69.5374 32.1673 69.6722C32.0907 69.7137 32.008 69.7345 31.9253 69.7345V69.7324Z"
      fill="#64350F"
    />
    <path
      d="M27.9565 62.332C27.7745 62.332 27.5966 62.2345 27.5056 62.0623L26.1631 59.5577C26.0286 59.3086 26.1217 58.9974 26.372 58.8625C26.6202 58.7276 26.9305 58.821 27.065 59.0721L28.4074 61.5767C28.5419 61.8257 28.4488 62.137 28.1985 62.2718C28.122 62.3133 28.0392 62.3341 27.9565 62.3341V62.332Z"
      fill="#64350F"
    />
    <path
      d="M70.5692 65.267C70.4803 65.267 70.3913 65.2442 70.3127 65.1985L68.5048 64.1465C68.2876 64.0199 68.1945 63.7543 68.2835 63.5177C68.9889 61.6689 70.3024 60.1976 72.0834 59.2639C73.4238 58.5604 74.5532 58.4069 74.6007 58.4006C74.6235 58.3986 74.6463 58.3965 74.6669 58.3965C74.8986 58.3965 75.1075 58.5563 75.1634 58.7866L75.6784 60.8803C75.7136 61.0194 75.6867 61.1667 75.6081 61.285C75.5295 61.4053 75.4054 61.4863 75.2647 61.5091C72.1868 62.0195 71.0594 64.906 71.047 64.935C70.9933 65.0761 70.8816 65.1861 70.7388 65.2359C70.683 65.2567 70.6251 65.2649 70.5671 65.2649L70.5692 65.267Z"
      fill="#64350F"
    />
    <path
      d="M74.6635 58.9119L75.1786 61.0057C71.7759 61.5701 70.5679 64.7553 70.5679 64.7553L68.76 63.7032C70.3755 59.4722 74.6656 58.9119 74.6656 58.9119M74.6656 57.8848C74.6222 57.8848 74.5766 57.8868 74.5311 57.8931C74.4794 57.8993 73.2611 58.0653 71.8421 58.8123C69.9514 59.8063 68.5531 61.3709 67.8022 63.338C67.6223 63.8091 67.8105 64.3403 68.2449 64.5935L70.0528 65.6455C70.2121 65.7389 70.392 65.7866 70.5741 65.7866C70.6878 65.7866 70.8016 65.7679 70.9133 65.7264C71.1967 65.6248 71.418 65.4069 71.5235 65.1226C71.5421 65.079 71.8172 64.4088 72.4233 63.7012C73.2197 62.7736 74.2043 62.2071 75.3461 62.0183C75.6275 61.9706 75.8777 61.8087 76.0349 61.5701C76.1922 61.3314 76.2418 61.0368 76.1735 60.7587L75.6585 58.665C75.5447 58.2023 75.1289 57.8848 74.6656 57.8848Z"
      fill="#64350F"
    />
    <path
      d="M14.1711 57.5033C14.0801 57.5033 13.9891 57.4784 13.9105 57.4307C13.7885 57.3581 13.7016 57.2356 13.6705 57.0966C13.3996 55.8287 12.719 54.7622 11.6434 53.9259C10.8222 53.2889 10.0693 53.0316 10.061 53.0295C9.91826 52.9818 9.80449 52.8739 9.74864 52.7348C9.69279 52.5958 9.69899 52.4381 9.76725 52.3053L10.7312 50.444C10.8222 50.2696 10.998 50.168 11.1863 50.168C11.24 50.168 11.2938 50.1763 11.3476 50.1949C13.2217 50.8175 14.7462 52.0646 15.7598 53.8056C16.523 55.1149 16.7278 56.2417 16.7361 56.2894C16.7816 56.5467 16.6265 56.7978 16.3782 56.8725L14.318 57.4846C14.2704 57.4992 14.2208 57.5054 14.1732 57.5054L14.1711 57.5033Z"
      fill="#FFC78D"
    />
    <path
      d="M11.1847 50.7186C15.4728 52.1421 16.2299 56.4125 16.2299 56.4125L14.1696 57.0247C13.4498 53.6403 10.2208 52.5778 10.2208 52.5778L11.1847 50.7165M11.1847 49.6914C10.8103 49.6914 10.4566 49.8968 10.2767 50.2454L9.31273 52.1068C9.17414 52.3765 9.15552 52.6919 9.27136 52.9721C9.3872 53.2522 9.61267 53.4639 9.90019 53.5552C9.9457 53.5718 10.6242 53.8166 11.3564 54.3914C12.3162 55.1468 12.9264 56.1054 13.1685 57.2405C13.2284 57.5206 13.4022 57.7634 13.6463 57.9087C13.8056 58.0041 13.9876 58.0518 14.1696 58.0518C14.2668 58.0518 14.3661 58.0373 14.4613 58.0103L16.5215 57.3982C17.0221 57.2488 17.3303 56.7487 17.2393 56.2341C17.231 56.1822 17.0076 54.9704 16.2009 53.5822C15.1232 51.7333 13.5015 50.4052 11.5074 49.7433C11.4019 49.708 11.2923 49.6914 11.1868 49.6914H11.1847Z"
      fill="#64350F"
    />
    <path
      d="M103.656 83.9467C103.449 83.9467 103.255 83.8201 103.178 83.6167L102.409 81.6039C102.357 81.4711 102.366 81.3217 102.428 81.1931C102.492 81.0644 102.603 80.969 102.742 80.9275C103.981 80.5581 104.988 79.7945 105.735 78.6532C106.304 77.7837 106.5 77.0097 106.503 77.0014C106.54 76.8562 106.637 76.7317 106.771 76.6653C106.844 76.63 106.922 76.6113 107.001 76.6113C107.069 76.6113 107.136 76.6238 107.2 76.6528L109.123 77.4725C109.355 77.57 109.481 77.8232 109.421 78.068C108.95 79.9895 107.826 81.6122 106.176 82.7618C104.933 83.6271 103.83 83.9197 103.785 83.9322C103.741 83.9425 103.698 83.9488 103.656 83.9488V83.9467Z"
      fill="#FFC78D"
    />
    <path
      d="M107.051 77.3223L108.974 78.142C107.892 82.5411 103.706 83.6305 103.706 83.6305L102.936 81.6177C106.244 80.6341 107.048 77.3223 107.048 77.3223M107.042 76.291C106.887 76.291 106.73 76.3263 106.587 76.3989C106.318 76.5359 106.126 76.7787 106.056 77.0733C106.043 77.119 105.853 77.8183 105.338 78.5964C104.661 79.6153 103.755 80.3 102.647 80.63C102.374 80.7109 102.146 80.9039 102.02 81.1612C101.894 81.4185 101.879 81.7152 101.983 81.9829L102.752 83.9957C102.907 84.4004 103.292 84.6556 103.708 84.6556C103.793 84.6556 103.879 84.6452 103.966 84.6224C104.016 84.6099 105.205 84.2925 106.521 83.3774C108.275 82.1572 109.469 80.4308 109.971 78.3848C110.091 77.895 109.839 77.3908 109.378 77.1937L107.454 76.374C107.324 76.318 107.185 76.291 107.046 76.291H107.042Z"
      fill="#64350F"
    />
    <g opacity="0.4">
      <path
        d="M38.3984 38.6493L52.4995 41.0003L67.5273 38.2301L52.3154 34.9121L38.3984 37.9562V38.6493Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M53.7969 145.546L99.1036 124.235V88.5605L53.9541 97.1264L53.7969 145.546Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M99.4005 87.9785L95.0525 88.931C95.0525 88.931 96.517 123.514 55.1777 141.789C54.8136 141.936 54.4557 142.084 54.1041 142.229L54.0938 145.246L99.4005 123.311V87.9806V87.9785Z"
        fill="#232323"
      />
    </g>
  </svg>
)

const GiftFail = (
  <svg viewBox="0 0 89 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14501_7843)">
      <path
        d="M87.6085 57.5498L85.3936 67.6081C85.1855 68.5501 84.2827 69.163 83.338 69.0043L8.828 56.5436C7.87322 56.3832 7.21376 55.4935 7.33458 54.5278L8.65852 43.9748C8.78773 42.9501 9.73915 42.241 10.751 42.4149L86.1537 55.372C87.1689 55.5458 87.8334 56.5402 87.6102 57.5514L87.6085 57.5498Z"
        fill="#EA7100"
      />
      <g opacity="0.5">
        <path
          d="M10.5295 46.6571C10.6587 45.6323 11.6101 44.9233 12.622 45.0972L87.5179 57.9664L87.6102 57.5494C87.8334 56.5382 87.1689 55.5438 86.1537 55.37L10.751 42.4129C9.73915 42.239 8.78773 42.9498 8.65852 43.9728L7.33458 54.5259C7.21376 55.4915 7.87322 56.3829 8.828 56.5416L9.27938 56.6175L10.5295 46.6571Z"
          fill="white"
        />
      </g>
      <path
        d="M39.5734 47.7441L36.7812 61.2195L55.0362 64.2717L57.9107 50.5955L39.5734 47.7441Z"
        fill="#FFC78D"
      />
      <g opacity="0.3">
        <path
          d="M86.152 55.3708L83.6451 54.9403L81.9016 62.8529C81.6936 63.795 80.7908 64.4078 79.8461 64.2491L7.62991 52.1699L7.33458 54.5284C7.21376 55.494 7.87322 56.3854 8.828 56.5441L83.338 69.0065C84.2827 69.1652 85.1855 68.5507 85.3936 67.6103L87.6085 57.5519C87.8317 56.5407 87.1672 55.5463 86.152 55.3725V55.3708Z"
          fill="#B24800"
        />
      </g>
      <path
        d="M83.6333 70.1576C83.4739 70.1576 83.3128 70.1441 83.1517 70.1171L8.64002 57.6547C7.08451 57.3947 6.02233 55.958 6.21866 54.3846L7.5426 43.8316C7.64328 43.028 8.05439 42.3206 8.7021 41.8378C9.34813 41.355 10.1418 41.1642 10.9355 41.301L86.3382 54.258C87.1235 54.3931 87.8283 54.8522 88.2713 55.5157C88.716 56.1809 88.872 57.0098 88.6992 57.7931L86.4842 67.8515C86.1839 69.2172 84.9807 70.1559 83.6333 70.1559V70.1576ZM10.4422 43.5142C10.2979 43.5142 10.1569 43.5615 10.0378 43.6493C9.88677 43.7624 9.79113 43.9278 9.76763 44.1152L8.44369 54.6682C8.39838 55.0346 8.64505 55.3705 9.00918 55.4313L83.5192 67.8937C83.8766 67.9528 84.2189 67.7215 84.2978 67.3653L86.5127 57.3069C86.5681 57.0571 86.4742 56.8663 86.4121 56.7751C86.3517 56.684 86.2107 56.5253 85.9607 56.4814L10.558 43.5243C10.5194 43.5176 10.4791 43.5142 10.4405 43.5142H10.4422Z"
        fill="#64350F"
      />
      <path
        d="M55.4025 65.4618C55.3354 65.4618 55.2666 65.4551 55.1995 65.4433C54.5904 65.3301 54.1876 64.7426 54.3001 64.1315L56.807 50.3945C56.9194 49.7817 57.5034 49.3765 58.1108 49.4896C58.7199 49.6028 59.1227 50.1902 59.0102 50.8014L56.5033 64.5384C56.4043 65.082 55.9328 65.4618 55.4025 65.4618Z"
        fill="#64350F"
      />
      <path
        d="M37.0666 62.3056C36.9995 62.3056 36.9307 62.2988 36.8635 62.287C36.2544 62.1739 35.8517 61.5864 35.9641 60.9753L38.4711 47.2383C38.5835 46.6255 39.1674 46.2203 39.7749 46.3334C40.384 46.4465 40.7867 47.034 40.6743 47.6451L38.1674 61.3821C38.0684 61.9257 37.5968 62.3056 37.0666 62.3056Z"
        fill="#64350F"
      />
      <path
        d="M74.549 96.4531H10.9208C9.1589 96.4531 7.78294 97.986 7.96081 99.7485L12.2565 142.182C12.4109 143.71 13.6895 144.871 15.2165 144.871H70.6426C72.1796 144.871 73.4633 143.694 73.6043 142.155L77.509 99.7215C77.6701 97.9674 76.2974 96.4531 74.5473 96.4531H74.549Z"
        fill="#EA7100"
      />
      <path
        d="M70.6468 146H15.2191C13.1098 146 11.358 144.408 11.1449 142.297L6.84919 99.8641C6.73173 98.7077 7.10928 97.5479 7.88452 96.6852C8.65976 95.8225 9.76724 95.3262 10.9234 95.3262H74.5515C75.701 95.3262 76.8034 95.8158 77.577 96.67C78.3505 97.5242 78.7348 98.6739 78.6291 99.8253L74.7244 142.258C74.6304 143.283 74.1623 144.23 73.4055 144.922C72.6487 145.616 71.6704 145.998 70.6468 145.998V146ZM10.9234 97.5816C10.3915 97.5816 9.90316 97.7994 9.54742 98.1961C9.19169 98.5929 9.02556 99.1044 9.07926 99.6362L13.3749 142.069C13.4723 143.025 14.2643 143.746 15.2191 143.746H70.6452C71.6083 143.746 72.402 143.018 72.491 142.053L76.3957 99.6193C76.4443 99.0909 76.2749 98.5827 75.9191 98.1911C75.5634 97.7977 75.0768 97.5816 74.5482 97.5816H10.9234Z"
        fill="#64350F"
      />
      <g opacity="0.5">
        <path
          d="M15.5393 143.198L11.2436 100.765C11.1899 100.233 11.3561 99.7213 11.7118 99.3245C12.0675 98.9278 12.5558 98.71 13.0878 98.71H76.2545C76.1739 98.5226 76.0632 98.3471 75.9222 98.1917C75.5665 97.8001 75.0799 97.584 74.5513 97.584H10.9248C10.3929 97.584 9.90458 97.8018 9.54885 98.1985C9.19311 98.5952 9.02531 99.1067 9.08069 99.6385L13.3764 142.072C13.4737 143.027 14.2657 143.748 15.2205 143.748H15.6819C15.6098 143.578 15.5595 143.394 15.5393 143.199V143.198Z"
          fill="white"
        />
      </g>
      <path d="M35.1278 144.873H50.5L50.5 96.4551H35.1278L35.1278 144.873Z" fill="#FFC78D" />
      <path
        d="M50.5009 146H35.1287C34.5095 146 34.0078 145.495 34.0078 144.872V96.4539C34.0078 95.831 34.5095 95.3262 35.1287 95.3262H50.5009C51.1201 95.3262 51.6218 95.831 51.6218 96.4539V144.872C51.6218 145.495 51.1201 146 50.5009 146ZM36.2496 143.746H49.3817V97.5816H36.2496V143.746Z"
        fill="#64350F"
      />
      <path
        d="M84.1569 146H1.12091C0.501723 146 0 145.495 0 144.872C0 144.249 0.501723 143.744 1.12091 143.744H84.1585C84.7777 143.744 85.2794 144.249 85.2794 144.872C85.2794 145.495 84.7777 146 84.1585 146H84.1569Z"
        fill="#64350F"
      />
      <path
        d="M69.5403 48.4573C69.5403 48.4573 85.8219 52.8585 85.1893 71.9353L83.1791 70.9072C82.5918 70.6067 81.8988 70.7822 81.4877 71.3478C80.7326 72.3911 79.61 74.5993 78.719 79.1119C78.6451 79.4833 78.1719 79.4394 78.1551 79.0595C77.8044 70.8666 74.9468 50.4427 55.3125 48.226L58.3698 44.6504L69.5386 48.4573H69.5403Z"
        fill="#FFC78D"
      />
      <path
        d="M78.4458 80.4952C78.4089 80.4952 78.3736 80.4952 78.3367 80.4902C77.6169 80.4344 77.0698 79.852 77.038 79.1058C76.6839 70.8589 73.8464 51.4512 55.1904 49.3443C54.7776 49.2971 54.4235 49.0253 54.2725 48.637C54.1215 48.2487 54.1953 47.8064 54.4655 47.489L57.5228 43.9133C57.8215 43.5656 58.2997 43.4339 58.731 43.5808L69.8695 47.3776C70.3343 47.5092 74.3599 48.7163 78.3367 52.189C80.7732 54.3161 82.6878 56.8569 84.0268 59.7403C85.6914 63.3261 86.4599 67.4403 86.3106 71.9714C86.2972 72.3564 86.0908 72.7092 85.7602 72.905C85.4296 73.1009 85.0236 73.1144 84.6829 72.9388L82.6727 71.9107C82.5418 71.8448 82.4428 71.9444 82.3958 72.0103C81.8152 72.8122 80.7077 74.8296 79.8201 79.3304C79.6842 80.0208 79.1153 80.4952 78.4458 80.4952ZM57.4892 47.4113C61.4057 48.1406 64.8674 49.6583 67.8006 51.934C70.7035 54.1861 73.1148 57.2013 74.9656 60.8934C76.4322 63.8191 77.5531 67.1769 78.2981 70.8724C78.5331 72.0407 78.7143 73.1684 78.8536 74.2218C79.4677 72.4745 80.0869 71.3704 80.5836 70.685C81.3085 69.6822 82.614 69.3547 83.6879 69.9034L84.0788 70.1043C83.9144 63.4156 81.5165 57.9897 76.939 53.9565C73.1869 50.651 69.2873 49.557 69.2487 49.5469C69.2269 49.5402 69.2034 49.5334 69.1815 49.5266L58.7276 45.9628L57.4892 47.4113Z"
        fill="#64350F"
      />
      <g opacity="0.7">
        <path
          d="M79.873 70.7693C79.4904 71.2978 79.034 72.0794 78.5625 73.2189C78.6481 73.7811 78.7236 74.3264 78.7874 74.8514C79.4586 72.7783 80.1516 71.5206 80.6936 70.7693C81.0711 70.2477 81.5997 69.9202 82.1668 69.8138C81.3094 69.6534 80.4268 69.9995 79.8713 70.7693H79.873Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M77.0251 53.8432C73.248 50.5208 69.3265 49.4217 69.2879 49.4116C69.2677 49.4065 69.2476 49.3998 69.2291 49.393L58.6863 45.7988L58.5 46.0166L68.4069 49.393C68.4254 49.3998 68.4455 49.4048 68.4657 49.4116C68.5042 49.4217 72.4257 50.5208 76.2029 53.8432C80.7772 57.8679 83.1851 63.2735 83.3898 69.9251C83.4703 69.9555 83.5492 69.9892 83.6281 70.0297L84.2288 70.337C84.1147 63.4947 81.6967 57.9523 77.0268 53.8432H77.0251Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.08">
        <path
          d="M55.3125 48.226L58.3698 44.6504L59.1736 44.9239L57.4134 46.9818C61.0211 47.3887 64.0599 48.4134 66.6239 49.8602L74.8008 50.4106L76.9755 52.3436L76.952 52.4955C76.952 52.4955 76.2087 52.6812 74.9384 52.8061C73.4668 52.9496 72.7352 54.9248 73.6816 56.2214C77.8649 61.963 79.4271 69.1632 79.9926 74.3562C79.558 75.5514 79.115 77.1012 78.719 79.1085C78.6451 79.4799 78.1719 79.436 78.1551 79.0562C77.88 72.6612 76.0795 58.8128 65.9628 51.9907C65.3402 51.8152 64.706 51.6109 64.0616 51.3695L63.7327 50.6773C61.3399 49.455 58.5578 48.589 55.3125 48.2226V48.226Z"
          fill="#3E3331"
        />
      </g>
      <path
        d="M50.5747 42.0786C50.5747 42.0786 58.3405 31.7315 68.3128 29.5571C68.3128 29.5571 73.2579 27.9499 77.8506 32.9099C79.1007 34.2605 80.0874 35.8963 80.8073 37.6842C81.2872 38.876 81.8996 40.5237 82.3493 42.1545C83.2437 45.3959 81.6798 48.899 78.8322 49.8595C77.2532 50.393 75.0651 50.6598 72.033 50.3981C72.033 50.3981 58.1257 49.348 53.4525 48.4009L50.375 47.1145L50.5747 42.0769V42.0786Z"
        fill="#FFC78D"
      />
      <path
        d="M74.2639 51.4859C73.5323 51.4859 72.7638 51.4505 71.9583 51.3812C71.314 51.3323 57.9269 50.3126 53.2637 49.3689C53.2016 49.3571 53.1395 49.3368 53.0808 49.3132L50.0033 48.0268C49.6241 47.8681 49.3825 47.4899 49.3993 47.0763L49.5989 42.0387C49.6073 41.8378 49.6761 41.6436 49.7969 41.4832C49.8792 41.3752 51.834 38.7855 55.0558 35.8953C56.9536 34.1935 58.8984 32.7417 60.8365 31.5819C63.2713 30.1233 65.7061 29.1205 68.0721 28.5988C68.359 28.5144 69.6779 28.1751 71.4801 28.3912C73.294 28.6106 75.9973 29.4547 78.5713 32.2352C79.8684 33.6364 80.9289 35.3449 81.7193 37.3117C82.179 38.4546 82.8251 40.1766 83.2983 41.8884C83.7933 43.6847 83.649 45.5957 82.8905 47.2687C82.1102 48.989 80.7813 50.24 79.1486 50.792C77.7877 51.2512 76.1517 51.4825 74.2639 51.4825V51.4859ZM53.7419 47.4545C58.3934 48.3728 71.9717 49.406 72.1093 49.4162C72.1127 49.4162 72.1161 49.4162 72.1194 49.4162C74.7354 49.6424 76.8883 49.4769 78.5227 48.9249C80.8433 48.1416 82.1639 45.1619 81.4072 42.4185C80.9591 40.7945 80.3399 39.1484 79.9003 38.0528C79.1989 36.3106 78.2693 34.8064 77.135 33.5807C75.42 31.7271 73.4685 30.6449 71.3391 30.363C69.7752 30.1553 68.6728 30.4778 68.6141 30.4947C68.5822 30.5048 68.557 30.5116 68.5235 30.5183C63.5935 31.5937 59.2038 34.8216 56.3898 37.3404C53.8476 39.6161 52.0958 41.7399 51.5437 42.4354L51.3843 46.4668L53.7419 47.4511V47.4545Z"
        fill="#232323"
      />
      <g opacity="0.7">
        <path
          d="M82.0141 44.2102C81.5644 42.5777 80.9502 40.9317 80.472 39.7398C79.7538 37.952 78.7655 36.3161 77.5154 34.9655C72.9227 30.0055 67.9776 31.6127 67.9776 31.6127C60.0675 33.3381 53.5484 40.2023 51.1875 42.9693L51.1925 42.8208C51.1925 42.8208 58.9583 32.4737 68.9307 30.2993C68.9307 30.2993 73.8758 28.6921 78.4685 33.6521C79.7186 35.0027 80.7052 36.6385 81.4251 38.4263C81.905 39.6182 82.5175 41.2659 82.9672 42.8967C83.6183 45.2585 82.9638 47.7588 81.4587 49.3136C82.2524 47.8415 82.5024 45.9879 82.0124 44.2102H82.0141Z"
          fill="white"
        />
      </g>
      <path
        d="M66.1275 44.6769L72.3848 42.8536L77.7594 42.7996C79.617 42.4484 81.7783 43.6065 82.6038 44.1096C82.5703 46.696 81.1155 49.0915 78.8384 49.8614C77.2594 50.3948 75.0713 50.6616 72.0391 50.3999C72.0391 50.3999 58.1319 49.3498 53.4586 48.4027L51.8125 47.7156V47.5181L55.7994 46.8783L66.1292 44.6769H66.1275Z"
        fill="white"
      />
      <path
        d="M29.2614 40.3007C29.2614 40.3007 12.6492 38.0402 7.53125 56.2087L9.72775 56.0331C10.3688 55.9825 10.9678 56.4181 11.1843 57.1119C11.5819 58.3882 11.9746 60.9036 11.4611 65.4922C11.4192 65.8704 11.8773 66.0155 12.0065 65.6644C14.7903 58.1063 23.5897 40.0441 42.6955 45.6726L40.8933 41.1127L29.2614 40.3024V40.3007Z"
        fill="#FFC78D"
      />
      <path
        d="M11.7561 66.9919C11.6487 66.9919 11.5396 66.9801 11.4306 66.953C10.7308 66.7859 10.266 66.1039 10.3499 65.3661C10.8634 60.7809 10.4255 58.4377 10.1184 57.4484C10.0563 57.2475 9.92037 57.1496 9.81969 57.158L7.62319 57.3336C7.25738 57.3623 6.89997 57.2104 6.6684 56.9251C6.43516 56.6398 6.3563 56.2582 6.45698 55.902C7.67521 51.5751 9.63847 47.9978 12.288 45.2713C14.4241 43.075 16.9999 41.429 19.9448 40.3806C24.7388 38.6738 28.8935 39.1212 29.3735 39.1803L40.9735 39.9889C41.403 40.0193 41.7772 40.2945 41.9367 40.6963L43.7388 45.2561C43.8966 45.6562 43.8143 46.1121 43.5257 46.4311C43.2371 46.7502 42.7941 46.8751 42.383 46.7536C24.2941 41.4239 15.8504 58.4765 13.0599 66.0549C12.8468 66.6323 12.3283 66.9919 11.7561 66.9919ZM9.8348 54.9026C10.9272 54.9026 11.9071 55.6488 12.2562 56.7748C12.5213 57.6257 12.7747 58.9324 12.82 60.8704C13.2596 59.9537 13.7596 58.9847 14.3184 58.0005C16.129 54.8114 18.1946 52.0883 20.4565 49.9089C23.3175 47.152 26.5041 45.2561 29.9255 44.2736C33.3319 43.2961 36.9966 43.2185 40.846 44.0389L40.1143 42.187L29.1855 41.4256C29.162 41.4256 29.1385 41.4222 29.115 41.4188C29.053 41.4104 25.0862 40.9174 20.6059 42.5364C15.1004 44.5251 11.2359 48.7001 9.09647 54.9532L9.64015 54.9093C9.70391 54.9043 9.76935 54.9009 9.83312 54.9009L9.8348 54.9026Z"
        fill="#64350F"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.28">
        <path
          d="M40.8992 41.1131L39.4376 41.0118L40.4344 43.5323C36.8082 42.4637 33.5529 42.251 30.6399 42.6561L24.0805 40.166L19.112 41.4271L20.6809 42.3793C22.3035 43.3635 22.4529 45.6865 20.9544 46.8547C16.5664 50.2818 13.536 55.1219 11.5895 59.1635C11.7523 60.612 11.7808 62.6547 11.4636 65.4926C11.4217 65.8707 11.8798 66.0159 12.009 65.6648C14.7928 58.1067 23.5922 40.0445 42.698 45.673L40.8958 41.1131H40.8992Z"
          fill="#3E3331"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M12.8152 57.4008C12.6105 56.744 12.1809 56.2241 11.6406 55.9219C11.8504 56.1768 12.0148 56.479 12.1205 56.8183C12.4092 57.7451 12.686 59.2257 12.686 61.4879C12.8807 61.0709 13.0887 60.6404 13.3086 60.2015C13.2079 58.9522 13.0149 58.044 12.8152 57.4024V57.4008Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M9.82469 55.0453C12.0078 48.9948 15.8454 44.9397 21.2653 42.9848C25.7892 41.3539 29.7896 41.8604 29.8298 41.8655C29.85 41.8689 29.8718 41.8705 29.8919 41.8722L40.4331 42.6066L40.215 42.0562L29.1989 41.2881C29.1788 41.2881 29.157 41.2847 29.1368 41.2814C29.0965 41.2763 25.0962 40.7698 20.5723 42.4006C14.9594 44.4248 11.0379 48.6977 8.89844 55.1129L9.65354 55.0521C9.71059 55.047 9.76764 55.0453 9.82469 55.0453Z"
          fill="white"
        />
      </g>
      <path
        d="M48.9936 41.7592C48.9936 41.7592 44.7969 28.9896 36.0797 23.0302C36.0797 23.0302 31.9149 19.5778 26.1157 22.4343C24.5367 23.2108 23.1205 24.3605 21.909 25.7584C21.1019 26.6903 20.033 27.9952 19.1201 29.3526C17.3096 32.0469 17.7308 35.9501 20.1169 37.9726C21.4408 39.0935 23.4158 40.2044 26.3423 41.1481C26.3423 41.1481 39.721 45.6236 44.3942 46.5706H47.6713L48.9919 41.7626L48.9936 41.7592Z"
        fill="#FFC78D"
      />
      <path
        d="M47.6706 47.5547H44.3934C44.328 47.5547 44.2642 47.5463 44.2005 47.5344C39.5356 46.589 26.6519 42.2892 26.0378 42.0832C23.264 41.1868 21.0591 40.0574 19.4852 38.7237C16.7265 36.3872 16.1996 31.9354 18.3072 28.797C19.267 27.3687 20.393 26.003 21.1682 25.1082C22.5106 23.5584 24.0292 22.3615 25.682 21.5461C28.9575 19.9339 31.7614 20.2023 33.5384 20.7104C35.2987 21.2152 36.4263 22.0509 36.6578 22.2332C38.7167 23.6462 40.6951 25.537 42.5376 27.8533C44.0058 29.6985 45.3935 31.8189 46.6621 34.1554C48.8183 38.126 49.8771 41.3151 49.9208 41.4484C49.9812 41.6341 49.9862 41.8333 49.9342 42.0207L48.6136 46.8305C48.4961 47.2576 48.1102 47.5547 47.6689 47.5547H47.6706ZM44.4941 45.5812H46.9255L47.9659 41.7928C47.6639 40.9437 46.6503 38.2358 44.9254 35.067C43.0292 31.5808 39.8578 26.8049 35.5285 23.8438C35.5034 23.8269 35.4799 23.8083 35.4564 23.7897C35.4497 23.7847 34.4966 23.0182 32.9226 22.5844C30.8436 22.012 28.6991 22.2585 26.5479 23.317C25.13 24.0143 23.8194 25.0525 22.6499 26.4031C21.9099 27.2573 20.8393 28.5572 19.9349 29.9011C18.3978 32.1886 18.7703 35.5363 20.7504 37.2127C22.128 38.381 24.1114 39.3871 26.6435 40.2042C26.6469 40.2042 26.6502 40.2059 26.6536 40.2076C26.7861 40.2515 39.8494 44.6155 44.4958 45.5795L44.4941 45.5812Z"
        fill="#232323"
      />
      <g opacity="0.7">
        <path
          d="M36.5458 25.1406C36.5458 25.1406 32.381 21.6882 26.5818 24.5446C25.0028 25.3212 23.5866 26.4709 22.375 27.8687C21.5679 28.8006 20.499 30.1056 19.5862 31.4629C18.9184 32.4573 18.5542 33.6171 18.4804 34.7853C18.1582 33.0752 18.4754 31.2114 19.4788 29.719C20.39 28.3634 21.4589 27.0567 22.2677 26.1248C23.4775 24.727 24.8954 23.579 26.4744 22.8007C32.2736 19.9459 36.4384 23.3967 36.4384 23.3967C45.1556 29.356 49.3523 42.1257 49.3523 42.1257L49.1274 42.9462C47.9814 39.9107 43.8518 30.1343 36.5458 25.1389V25.1406Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M53.9631 48.506C53.9631 48.506 52.312 47.1335 58.5575 46.5021L68.5265 44.0457L76.5558 42.6816L80.8011 43.1493C80.8011 43.1493 83.0278 43.4987 82.7124 45.2275C82.7124 45.2275 81.5596 50.3985 76.7303 50.4137L53.9631 48.5077V48.506Z"
          fill="#FFC78D"
        />
      </g>
      <path
        d="M33.3022 38.0283L27.9712 33.8584L22.9388 31.6975C21.2994 30.6373 18.9234 30.877 17.9954 31.0256C17.2521 33.4684 17.9015 36.2911 19.811 37.9084C21.135 39.0294 23.11 40.1402 26.0364 41.084C26.0364 41.084 39.4151 45.5594 44.0884 46.5065H45.8419L45.9006 46.3208L42.3483 44.1548L33.3039 38.03L33.3022 38.0283Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M44.0612 46.7583C44.0612 46.7583 46.0227 46.1184 40.3461 43.0712L31.7161 36.8485L24.5829 32.4135L20.455 31.1845C20.455 31.1845 18.2602 30.6375 18.037 32.3865C18.037 32.3865 17.5705 37.6959 22.1045 39.6087L44.0612 46.76V46.7583Z"
          fill="#FFC78D"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M52.853 48.5803C53.3547 45.3288 51.7539 42.2866 49.2755 41.7852C48.5573 41.64 47.8425 41.7261 47.1797 41.9979C47.9952 41.505 48.9181 41.3075 49.8494 41.4965C52.3261 41.9979 53.9286 45.0401 53.4269 48.2916C53.4051 48.4351 53.3765 48.5769 53.348 48.717L52.8463 48.6157C52.848 48.6039 52.8513 48.5921 52.853 48.582V48.5803Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.18">
        <path
          d="M52.5895 40.1638C55.0863 42.4108 58.3433 43.2532 61.4325 42.4091C66.5739 41.0045 74.6082 39.7249 80.9309 42.8886C80.9309 42.8886 77.4222 41.592 63.869 45.0309C61.0969 45.7349 57.9423 45.7163 54.2691 46.9639C54.2691 46.9639 53.1566 43.184 51.3125 41.7338L52.5895 40.1621V40.1638Z"
          fill="#3E3331"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M52.1529 41.7852C52.1529 41.7852 52.2738 47.1756 44.0264 46.1458L43.4844 47.4407L53.0574 49.6843L54.5005 47.7445L53.7655 43.5662L52.1529 41.7852Z"
          fill="#000072"
        />
      </g>
      <path
        d="M47.6675 47.6959H44.3904C44.3166 47.6959 44.2427 47.6874 44.1706 47.6722C39.499 46.7268 26.6187 42.4269 25.9928 42.2176C23.2006 41.3161 20.9806 40.1766 19.3932 38.8327C16.5825 36.4524 16.0422 31.9144 18.1901 28.7203C19.1532 27.2871 20.2842 25.9145 21.0611 25.0164C22.4153 23.4531 23.949 22.2427 25.6203 21.4205C28.9343 19.788 31.7768 20.0598 33.5757 20.5764C35.346 21.0829 36.4904 21.9253 36.7387 22.1194C38.8077 23.5392 40.7944 25.4385 42.6453 27.7648C44.1185 29.6168 45.5113 31.7439 46.7832 34.0872C48.9462 38.068 50.0067 41.2688 50.052 41.4039C50.1208 41.6149 50.1275 41.8428 50.0687 42.0572L48.7482 46.8669C48.6139 47.3548 48.1726 47.6942 47.6675 47.6942V47.6959ZM44.5045 45.4387H46.8151L47.8152 41.7972C47.498 40.9076 46.4896 38.2419 44.7965 35.1288C42.907 31.6578 39.7524 26.9038 35.445 23.9596C35.4148 23.9376 35.3929 23.9224 35.3644 23.8988C35.3124 23.8583 34.3811 23.1307 32.8675 22.7154C30.8254 22.1566 28.7178 22.4014 26.6036 23.443C25.2024 24.1318 23.907 25.1599 22.7492 26.4953C22.0125 27.3461 20.9453 28.641 20.0443 29.9798C18.5458 32.2099 18.9066 35.4732 20.8346 37.1057C22.1988 38.2621 24.1654 39.2582 26.6808 40.0702C26.6841 40.0702 26.6875 40.0719 26.6925 40.0736C26.8251 40.1175 39.8547 44.4697 44.5045 45.437V45.4387Z"
        fill="#64350F"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
        <path
          d="M46.5246 38.8001C43.5059 39.9295 40.1935 39.4416 37.5456 37.436C33.1375 34.0967 25.9725 29.7412 19.0859 30.2291C19.0859 30.2291 22.7708 30.3877 34.4715 38.9436C36.866 40.6943 39.8344 41.9149 42.9102 44.5299C42.9102 44.5299 45.0866 41.4168 47.2529 40.7787L46.5246 38.8018V38.8001Z"
          fill="#3E3331"
        />
      </g>
      <path
        d="M74.2609 51.6284C73.526 51.6284 72.7541 51.593 71.9453 51.5238C71.2909 51.4748 57.9038 50.4534 53.2339 49.508C53.1617 49.4928 53.0929 49.4726 53.0258 49.4439L49.9483 48.1575C49.5154 47.9768 49.2402 47.543 49.2587 47.072L49.4584 42.0343C49.4668 41.8047 49.5456 41.5836 49.6832 41.3996C49.7654 41.2898 51.7287 38.69 54.9605 35.7913C56.8651 34.0845 58.8166 32.6276 60.7631 31.4611C63.2079 29.9974 65.6528 28.9895 68.0322 28.4645C68.351 28.3699 69.6615 28.0323 71.4956 28.2534C73.333 28.4746 76.0698 29.3288 78.6724 32.1414C79.9812 33.5561 81.0501 35.2781 81.8472 37.2617C82.3086 38.408 82.9563 40.1351 83.4312 41.8537C84.495 45.7096 82.5922 49.7798 79.1909 50.9295C77.8166 51.3938 76.1638 51.6267 74.2609 51.6267V51.6284ZM53.7809 47.3184C58.4357 48.2351 71.9805 49.2649 72.1181 49.2751C72.1215 49.2751 72.1265 49.2751 72.1299 49.2751C74.7257 49.4996 76.8618 49.3375 78.4761 48.7906C80.7279 48.0309 82.0082 45.1288 81.2699 42.4547C80.8236 40.8374 80.2061 39.1964 79.7681 38.1042C79.0734 36.3771 78.1522 34.8881 77.0313 33.676C75.3416 31.851 73.4253 30.7841 71.3328 30.5038C69.8041 30.2996 68.7218 30.6085 68.653 30.6288C68.6161 30.6423 68.5893 30.6473 68.549 30.6558C63.6509 31.7244 59.2831 34.9354 56.4842 37.4407C53.989 39.6725 52.2573 41.7591 51.68 42.4851L51.5257 46.373L53.7809 47.3151V47.3184Z"
        fill="#64350F"
      />
      <path
        d="M53.1444 48.0276C53.1041 48.0276 53.0638 48.0276 53.0286 48.0259C52.5856 48.009 52.2416 47.6342 52.2584 47.1902C52.2752 46.7445 52.6477 46.3951 53.089 46.4153C54.7972 46.4795 63.0026 44.5465 70.114 42.7148C71.448 42.3704 72.6931 42.1357 73.9197 41.9939C76.7992 41.663 79.2021 41.8335 81.0647 42.5038C82.538 43.0338 83.1404 43.7058 83.2041 43.78L81.9876 44.8301C81.9876 44.8301 81.9909 44.8352 81.9976 44.8419C81.9926 44.8369 81.5412 44.3591 80.3649 43.9674C79.2558 43.5977 77.2673 43.2314 74.101 43.596C72.9482 43.7294 71.7736 43.9522 70.51 44.2764C66.0918 45.4142 55.6681 48.0276 53.1444 48.0276Z"
        fill="#64350F"
      />
      <path
        d="M45.1177 46.417C45.0238 46.417 44.9298 46.4001 44.8375 46.3664C42.6578 45.5476 33.4221 38.8336 29.5526 35.9856C28.4602 35.182 27.4215 34.5101 26.3761 33.931C23.4027 32.285 21.3807 31.8663 20.2078 31.8055C19.1423 31.7498 18.6338 31.9777 18.6305 31.9794C18.2412 32.1921 17.7546 32.0486 17.5431 31.6587C17.3317 31.267 17.4743 30.7774 17.8619 30.5647C18.1573 30.4026 20.931 29.0774 27.1497 32.5197C28.2572 33.1325 29.3529 33.8415 30.5007 34.6856C36.6354 39.2016 43.772 44.2443 45.3996 44.8571C45.8141 45.0124 46.0255 45.4767 45.8695 45.8954C45.7487 46.2195 45.4433 46.4204 45.1177 46.4204V46.417Z"
        fill="#64350F"
      />
      <path
        d="M53.3481 49.842C53.2743 49.842 53.2004 49.8353 53.1266 49.8201L44.1812 48.0086C43.6174 47.8938 43.2298 47.3688 43.2868 46.7931C43.3053 46.6074 43.3237 46.4504 43.3472 46.3002C43.6006 44.6626 44.302 43.1618 45.3256 42.0712C46.3995 40.9266 47.757 40.2969 49.1481 40.2969C49.4551 40.2969 49.7656 40.3273 50.0693 40.3897C51.603 40.7004 52.9085 41.7454 53.7441 43.334C54.5328 44.8314 54.8113 46.653 54.5328 48.4628C54.5093 48.613 54.4808 48.7683 54.4422 48.9506C54.3297 49.4791 53.8649 49.8437 53.3481 49.8437V49.842Z"
        fill="#FFC78D"
      />
      <path
        d="M49.1445 41.4254C49.3761 41.4254 49.611 41.449 49.8443 41.4963C52.321 41.9977 53.9235 45.0398 53.4218 48.2913C53.3999 48.4348 53.3714 48.5766 53.3429 48.7168L44.3975 46.9053C44.4109 46.7618 44.4277 46.6183 44.4512 46.4748C44.9059 43.5323 46.9279 41.4271 49.1445 41.4271M49.1462 39.1716C47.443 39.1716 45.7969 39.9279 44.5082 41.2988C43.3303 42.5531 42.5248 44.2683 42.2362 46.1287C42.2093 46.2992 42.1875 46.4765 42.1674 46.6825C42.0533 47.8355 42.8269 48.8856 43.9545 49.1152L52.8999 50.9266C53.0492 50.957 53.1986 50.9705 53.3446 50.9705C54.3816 50.9705 55.3095 50.2429 55.5344 49.1844C55.578 48.9818 55.6099 48.8062 55.6367 48.6357C55.9539 46.5862 55.6317 44.5165 54.7323 42.8063C54.2641 41.9183 53.6634 41.1654 52.9452 40.5711C52.1532 39.9144 51.2588 39.4822 50.2889 39.2847C49.9131 39.2087 49.5288 39.1699 49.1462 39.1699V39.1716Z"
        fill="#64350F"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.11">
        <path
          d="M74.5523 96.4551H69.4529L66.7999 125.276C66.5449 128.043 65.5381 130.706 63.7997 132.865C60.7625 136.636 54.7284 140.36 42.7542 140.755H12.1172L12.2615 142.184C12.4159 143.712 13.6945 144.873 15.2215 144.873H70.6476C72.1846 144.873 73.4683 143.696 73.6093 142.157L77.514 99.7235C77.6751 97.9694 76.3025 96.4551 74.5523 96.4551Z"
          fill="#3E3331"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M23.7396 105.936L19.3724 106.415C18.3599 106.525 17.6284 107.441 17.7385 108.46L19.4306 124.105C19.5408 125.124 20.4509 125.859 21.4634 125.749L25.8306 125.271C26.8431 125.16 27.5746 124.244 27.4645 123.225L25.7724 107.58C25.6622 106.562 24.7521 105.826 23.7396 105.936Z"
          fill="white"
        />
      </g>
      <path
        d="M55.9065 18.6381C55.8109 18.6381 55.7135 18.6263 55.6162 18.6027C54.9719 18.4423 54.5792 17.7856 54.7386 17.1373L58.739 0.918636C58.8984 0.270362 59.5511 -0.12468 60.1955 0.0357005C60.8398 0.196081 61.2325 0.852795 61.0731 1.50107L57.0727 17.7197C56.9368 18.2701 56.4468 18.6381 55.9065 18.6381Z"
        fill="#64350F"
      />
      <path
        d="M45.6447 16.0425C45.0674 16.0425 44.559 15.6238 44.46 15.033L42.3759 2.5368C42.2669 1.8784 42.7082 1.25376 43.3626 1.14403C44.017 1.03261 44.6379 1.47829 44.747 2.1367L46.831 14.6328C46.9401 15.2913 46.4988 15.9159 45.8444 16.0256C45.7772 16.0374 45.7101 16.0425 45.6447 16.0425Z"
        fill="#64350F"
      />
      <path
        d="M65.9608 20.3288C65.6805 20.3288 65.4003 20.2309 65.1721 20.0317C64.6704 19.5944 64.6167 18.8297 65.053 18.3249L73.6594 8.35432C74.0941 7.84955 74.8542 7.79552 75.3559 8.23446C75.8576 8.67171 75.9113 9.43647 75.475 9.94124L66.8686 19.9118C66.6303 20.187 66.2964 20.3288 65.9608 20.3288Z"
        fill="#64350F"
      />
      <path
        d="M41.7813 91.8224C47.8996 91.8224 52.8595 86.8324 52.8595 80.6768C52.8595 74.5213 47.8996 69.5312 41.7813 69.5312C35.663 69.5312 30.7031 74.5213 30.7031 80.6768C30.7031 86.8324 35.663 91.8224 41.7813 91.8224Z"
        fill="#FFC78D"
      />
      <path
        d="M37.7115 76.5826C38.1394 76.1521 38.8341 76.1521 39.2637 76.5826L41.7807 79.115L44.2977 76.5826C44.7256 76.1521 45.4203 76.1521 45.8499 76.5826C46.2777 77.0131 46.2777 77.7121 45.8499 78.1442L43.3329 80.6766L45.8499 83.2089C46.2777 83.6394 46.2777 84.3383 45.8499 84.7705C45.422 85.201 44.7273 85.201 44.2977 84.7705L41.7807 82.2381L39.2637 84.7705C38.8358 85.201 38.1411 85.201 37.7115 84.7705C37.2837 84.34 37.2837 83.6411 37.7115 83.2089L40.2285 80.6766L37.7115 78.1442C37.2837 77.7137 37.2837 77.0148 37.7115 76.5826Z"
        fill="#64350F"
      />
      <path
        d="M45.0709 85.1765C44.7554 85.1765 44.4584 85.0533 44.2353 84.8287L41.7787 82.3572L39.3221 84.8287C39.0989 85.0533 38.8019 85.1765 38.4864 85.1765C38.171 85.1765 37.8739 85.0533 37.6508 84.8287C37.191 84.3661 37.191 83.6115 37.6508 83.1473L40.1074 80.6757L37.6508 78.2042C37.1893 77.7399 37.1893 76.987 37.6508 76.5227C38.1122 76.0601 38.8606 76.0601 39.3221 76.5227L41.7787 78.9943L44.2353 76.5227C44.6967 76.0601 45.4451 76.0601 45.9065 76.5227C46.368 76.987 46.368 77.7399 45.9065 78.2042L43.45 80.6757L45.9065 83.1473C46.3663 83.6098 46.3663 84.3645 45.9065 84.8287C45.6834 85.0533 45.3864 85.1765 45.0709 85.1765ZM41.7787 82.1191L44.3544 84.7105C44.5457 84.903 44.8007 85.0094 45.0709 85.0094C45.3411 85.0094 45.5961 84.903 45.7874 84.7105C46.1817 84.3138 46.1817 83.6655 45.7874 83.2688L43.2117 80.6774L45.7874 78.086C46.1817 77.6876 46.1817 77.041 45.7874 76.6443C45.3931 76.2475 44.7487 76.2475 44.3544 76.6443L41.7787 79.2357L39.2029 76.6443C38.8086 76.2475 38.1642 76.2475 37.7699 76.6443C37.3756 77.0427 37.3756 77.6893 37.7699 78.086L40.3456 80.6774L37.7699 83.2688C37.3756 83.6655 37.3756 84.3138 37.7699 84.7105C37.9612 84.903 38.2163 85.0094 38.4864 85.0094C38.7566 85.0094 39.0116 84.903 39.2029 84.7105L41.7787 82.1191Z"
        fill="#64350F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7109 80.6763C29.7109 73.969 35.1158 68.5312 41.7825 68.5312C48.4492 68.5312 53.854 73.969 53.854 80.6763C53.854 87.3835 48.4492 92.8213 41.7825 92.8213C35.1158 92.8213 29.7109 87.3835 29.7109 80.6763ZM41.8009 70.1182C36.0739 70.1182 31.4309 74.7895 31.4309 80.5513C31.4309 86.3132 36.0739 90.9845 41.8009 90.9845C47.528 90.9845 52.171 86.3132 52.171 80.5513C52.171 74.7895 47.528 70.1182 41.8009 70.1182Z"
        fill="#64350F"
      />
    </g>
    <defs>
      <clipPath id="clip0_14501_7843">
        <rect width="88.768" height="146" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const GiftGroup = (
  <svg width="75" height="60" viewBox="0 0 75 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M72.4708 8.99946C73.7343 9.2687 74.5128 10.6401 74.2097 12.0625L65.7246 51.8806C65.4215 53.303 64.1515 54.2378 62.888 53.9686L54.7554 52.2355C53.9692 50.4633 52.2372 49.0305 50.0192 48.5579C47.8011 48.0852 45.6355 48.6874 44.1951 49.9852L35.7206 48.1793C34.4571 47.91 33.6786 46.5387 33.9817 45.1163L42.4668 5.29816C42.7699 3.87575 44.0399 2.94093 45.3034 3.21017L53.7778 5.01605C54.564 6.78833 56.296 8.22108 58.5141 8.69374C60.7321 9.16639 62.8977 8.56422 64.3382 7.26643L72.4708 8.99946Z"
      fill="#FED9B2"
    />
    <path
      d="M2.28706 8.99946C1.02355 9.2687 0.244999 10.6401 0.548109 12.0625L9.03323 51.8806C9.33634 53.303 10.6063 54.2378 11.8698 53.9686L20.0024 52.2355C20.7886 50.4633 22.5206 49.0305 24.7387 48.5579C26.9567 48.0852 29.1223 48.6874 30.5627 49.9852L39.0372 48.1793C40.3007 47.91 41.0792 46.5387 40.7761 45.1163L32.291 5.29816C31.9879 3.87575 30.7179 2.94093 29.4544 3.21017L20.98 5.01605C20.1938 6.78833 18.4618 8.22108 16.2437 8.69374C14.0257 9.16639 11.8601 8.56422 10.4196 7.26643L2.28706 8.99946Z"
      fill="#FED9B2"
    />
    <path
      d="M55.3508 1.7595e-06C57.0364 1.84168e-06 58.4029 1.52405 58.4029 3.40406L58.4029 56.0321C58.4029 57.9121 57.0364 59.4361 55.3508 59.4361H44.5011C43.0158 57.4073 40.4159 56.0625 37.4568 56.0625C34.4978 56.0625 31.8979 57.4073 30.4126 59.4361H19.1068C17.4212 59.4361 16.0547 57.9121 16.0547 56.0321L16.0547 3.40406C16.0547 1.52406 17.4212 -8.21779e-08 19.1068 0L30.4126 7.03801e-07C31.8978 2.02887 34.4978 3.37367 37.4568 3.37367C40.4159 3.37367 43.0158 2.02888 44.5011 1.4076e-06L55.3508 1.7595e-06Z"
      fill="#FAB573"
    />
    <path
      d="M36.4877 31.9141L26.1016 33.2007V41.3319L36.4877 46.2722L46.8738 41.3319V33.2007L36.4877 31.9141Z"
      fill="#FF5722"
    />
    <g opacity="0.3">
      <path
        d="M36.4955 31.9199L26.1094 33.2065V41.3377L36.4955 46.278L46.8816 41.3377V33.2065L36.4955 31.9199Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M36.4911 31.9199L36.3828 31.9334V46.2265L36.4911 46.278L46.8772 41.3377V33.2065L36.4911 31.9199Z"
        fill="#232323"
      />
    </g>
    <path
      d="M36.4907 31.9209C36.5042 31.9209 36.5172 31.9219 36.5306 31.9233L46.5925 33.1699C46.7546 33.1901 46.8768 33.3283 46.8768 33.4921V41.1312C46.8768 41.2564 46.8046 41.3705 46.6921 41.4239L36.6302 46.2101C36.5864 46.2308 36.5388 46.2414 36.4912 46.2414C36.4435 46.2414 36.3959 46.2308 36.3521 46.2101L26.2903 41.4239C26.1772 41.37 26.1051 41.2564 26.1051 41.1312V33.4921C26.1051 33.3283 26.2268 33.1901 26.3894 33.1699L36.4512 31.9233C36.4642 31.9219 36.4777 31.9209 36.4912 31.9209M36.4907 31.5039C36.4604 31.5039 36.4301 31.5058 36.3998 31.5097L26.3374 32.7563C25.967 32.8021 25.6875 33.1184 25.6875 33.4921V41.1312C25.6875 41.2718 25.7269 41.4085 25.802 41.5275C25.877 41.6464 25.9834 41.7408 26.1104 41.801L36.1722 46.5872C36.2708 46.6343 36.381 46.6589 36.4902 46.6589C36.5994 46.6589 36.7096 46.6339 36.8082 46.5872L46.8701 41.801C46.9966 41.7408 47.1034 41.6459 47.1784 41.5275C47.2535 41.4085 47.2929 41.2718 47.2929 41.1312V33.4921C47.2929 33.1184 47.0134 32.8021 46.643 32.7563L36.5811 31.5097C36.5508 31.5058 36.5205 31.5039 36.4902 31.5039H36.4907Z"
      fill="#64350F"
    />
    <path
      d="M36.4116 35.5089C38.6108 35.5089 40.3935 33.7239 40.3935 31.522C40.3935 29.3201 38.6108 27.5352 36.4116 27.5352C34.2125 27.5352 32.4297 29.3201 32.4297 31.522C32.4297 33.7239 34.2125 35.5089 36.4116 35.5089Z"
      fill="#FFC78D"
    />
    <path
      d="M35.6011 30.9499C35.6011 30.8377 35.6921 30.7467 35.8041 30.7467H37.6313C37.8555 30.7467 38.0373 30.5647 38.0373 30.3403C38.0373 30.1159 37.8555 29.9339 37.6313 29.9339H36.8192V29.5275C36.8192 29.3031 36.6374 29.1211 36.4132 29.1211C36.189 29.1211 36.0072 29.3031 36.0072 29.5275V29.9339H35.8041C35.2437 29.9339 34.7891 30.3889 34.7891 30.9499C34.7891 31.5108 35.2437 31.9658 35.8041 31.9658H37.0222C37.1343 31.9658 37.2253 32.0569 37.2253 32.169C37.2253 32.2812 37.1343 32.3722 37.0222 32.3722H35.1951C34.9709 32.3722 34.7891 32.5542 34.7891 32.7786C34.7891 33.003 34.9709 33.185 35.1951 33.185H36.0072V33.5914C36.0072 33.8158 36.189 33.9978 36.4132 33.9978C36.6374 33.9978 36.8192 33.8158 36.8192 33.5914V33.185H37.0222C37.5827 33.185 38.0373 32.73 38.0373 32.169C38.0373 31.6081 37.5827 31.1531 37.0222 31.1531H35.8041C35.6921 31.1531 35.6011 31.0621 35.6011 30.9499Z"
      fill="#64350F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4122 27.0859C33.9452 27.0859 31.9453 29.0876 31.9453 31.5567C31.9453 34.0259 33.9452 36.0276 36.4122 36.0276C38.8792 36.0276 40.879 34.0259 40.879 31.5567C40.879 29.0876 38.8792 27.0859 36.4122 27.0859ZM32.4832 31.5279C32.4832 29.3399 34.255 27.5665 36.441 27.5665C38.6271 27.5665 40.3989 29.3399 40.3989 31.5279C40.3989 33.7158 38.6271 35.4892 36.441 35.4892C34.255 35.4892 32.4832 33.7158 32.4832 31.5279Z"
      fill="#64350F"
    />
    <path
      d="M36.4877 35.2172L26.1016 33.2392V41.3333L36.4877 46.2736L46.8738 41.3333V33.2021L46.8507 33.1992L36.4877 35.2172Z"
      fill="#FF5722"
    />
    <path
      d="M36.4877 35.2172L26.1016 33.2392V41.3333L36.4877 46.2736L46.8738 41.3333V33.2021L46.8507 33.1992L36.4877 35.2172Z"
      fill="#EA7100"
    />
    <path
      d="M29.0625 42.7394L31.4434 43.8719L31.554 34.2764L29.1799 33.8242L29.0625 42.7394Z"
      fill="#FFC78D"
    />
    <path
      d="M31.4713 43.9215L29.0312 42.761L29.1491 33.791L31.5829 34.2547L31.4713 43.922V43.9215ZM29.0909 42.7235L31.4126 43.8276L31.5228 34.3033L29.2078 33.8623L29.0914 42.723L29.0909 42.7235Z"
      fill="#232323"
    />
    <path
      d="M43.429 42.9874L41.0481 44.1199L40.9375 34.5244L43.3116 34.0723L43.429 42.9874Z"
      fill="#FFC78D"
    />
    <path
      d="M41.0179 44.168L40.9062 34.5008L43.34 34.0371L43.4579 43.0071L43.4406 43.0153L41.0179 44.1676V44.168ZM40.9664 34.5499L41.0766 44.0742L43.3983 42.9701L43.2818 34.1093L40.9669 34.5504L40.9664 34.5499Z"
      fill="#232323"
    />
    <g opacity="0.6">
      <path
        d="M46.859 33.1992L36.4955 35.2172L26.1094 33.2387V41.3329L36.4955 46.2731L36.5123 46.265L26.3215 41.4176V33.9273C26.3215 33.8204 26.4187 33.7395 26.5241 33.7597L36.4955 35.6592L46.859 33.6412L46.8816 33.6441V33.2021L46.859 33.1992Z"
        fill="white"
      />
    </g>
    <path
      d="M36.4854 35.388C36.4748 35.388 36.4637 35.3871 36.4531 35.3851L26.067 33.37C25.9751 33.3522 25.915 33.2631 25.9328 33.1707C25.9506 33.0782 26.0396 33.0185 26.132 33.0363L36.4858 35.0452L46.8397 33.0363C46.9316 33.0185 47.0211 33.0787 47.0389 33.1707C47.0567 33.2627 46.9965 33.3522 46.9046 33.37L36.5186 35.3851C36.508 35.3871 36.4969 35.388 36.4863 35.388H36.4854Z"
      fill="#64350F"
    />
    <path
      d="M36.4901 46.4432C36.3963 46.4432 36.3203 46.3671 36.3203 46.2732V35.2168C36.3203 35.123 36.3963 35.0469 36.4901 35.0469C36.5839 35.0469 36.66 35.123 36.66 35.2168V46.2732C36.66 46.3671 36.5839 46.4432 36.4901 46.4432Z"
      fill="#64350F"
    />
    <path
      d="M46.7646 20.3078C46.7646 20.1936 46.6885 20.0935 46.5784 20.0632L36.3481 17.2285H36.3227L26.0924 20.0632C25.9827 20.0935 25.9062 20.1936 25.9062 20.3078V22.7302C25.9062 22.8593 26.0029 22.9676 26.1309 22.9825L36.1701 24.1358C36.1798 24.1367 36.1894 24.1372 36.199 24.1372H36.4708C36.4804 24.1372 36.4901 24.1367 36.4997 24.1358L46.5389 22.9825C46.6669 22.9676 46.7636 22.8593 46.7636 22.7302V20.3078H46.7646Z"
      fill="#EA7100"
    />
    <path
      d="M41.2058 18.5742C41.2058 18.5742 43.2283 20.8257 42.9017 22.1869L41.1173 21.6515L41.0938 18.1875L41.2063 18.5742H41.2058Z"
      fill="#FFC78D"
    />
    <g opacity="0.3">
      <path
        d="M26.1406 22.984L36.4584 24.3115L46.5289 23.1025L46.3509 22.8319L36.0894 20.9102L26.1406 22.984Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M46.5257 23.1037L46.3477 22.8331L36.1719 20.9277C36.2359 21.8676 36.3244 23.2419 36.3922 24.3046L36.4552 24.3127L46.5262 23.1037H46.5257Z"
        fill="#232323"
      />
    </g>
    <path
      d="M31.5721 18.9375L31.4764 21.92L29.8672 22.1626L31.1685 18.6602L31.5721 18.9375Z"
      fill="#FFC78D"
    />
    <path
      d="M36.3507 17.2319L46.5809 20.0666C46.6906 20.0969 46.7671 20.1971 46.7671 20.3112V22.7336C46.7671 22.8627 46.6704 22.971 46.5424 22.9859L36.5032 24.1392C36.4936 24.1401 36.484 24.1406 36.4743 24.1406H36.2025C36.1929 24.1406 36.1833 24.1401 36.1737 24.1392L26.1344 22.9859C26.0065 22.971 25.9098 22.8627 25.9098 22.7336V20.3112C25.9098 20.1971 25.9858 20.0969 26.0959 20.0666L36.3262 17.2319H36.3517M36.3507 16.8145H36.3252C36.2877 16.8145 36.2502 16.8197 36.2141 16.8294L25.9839 19.664C25.6942 19.7444 25.4922 20.0102 25.4922 20.3107V22.7332C25.4922 23.0745 25.7476 23.361 26.0863 23.4L36.1256 24.5533C36.1511 24.5561 36.1766 24.5576 36.2021 24.5576H36.4739C36.4994 24.5576 36.5249 24.5561 36.5504 24.5533L46.5896 23.4C46.9283 23.361 47.1837 23.0745 47.1837 22.7332V20.3107C47.1837 20.0102 46.9817 19.744 46.6921 19.664L36.4618 16.8294C36.4258 16.8193 36.3882 16.8145 36.3507 16.8145Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M26.1131 22.8825V20.46C26.1131 20.3459 26.1891 20.2457 26.2993 20.2154L36.5295 17.3808H36.555L46.7376 20.2019C46.7063 20.1384 46.6496 20.0892 46.5784 20.069L36.3482 17.2344H36.3227L26.0924 20.069C25.9827 20.0994 25.9062 20.1995 25.9062 20.3136V22.7361C25.9062 22.8651 26.0029 22.9735 26.1309 22.9884L26.1377 22.9894C26.1223 22.9566 26.1131 22.9205 26.1131 22.882V22.8825Z"
        fill="white"
      />
    </g>
    <path
      d="M41.1082 21.9495C41.0095 21.9495 40.9297 21.8696 40.9297 21.7709V18.4345C40.9297 18.3358 41.0095 18.2559 41.1082 18.2559C41.2068 18.2559 41.2867 18.3358 41.2867 18.4345V21.7709C41.2867 21.8696 41.2068 21.9495 41.1082 21.9495Z"
      fill="#64350F"
    />
    <g opacity="0.3">
      <path
        d="M36.4723 17.2539L36.3516 20.9052L47.0884 23.0089V19.8805L36.4723 17.2539Z"
        fill="#232323"
      />
    </g>
    <path
      d="M31.5535 21.9681C31.4549 21.9681 31.375 21.8881 31.375 21.7894V18.6259C31.375 18.5272 31.4549 18.4473 31.5535 18.4473C31.6521 18.4473 31.732 18.5272 31.732 18.6259V21.7894C31.732 21.8881 31.6521 21.9681 31.5535 21.9681Z"
      fill="#64350F"
    />
    <path
      d="M25.912 23.128C25.8326 23.128 25.7619 23.0721 25.7455 22.9917C25.7272 22.8998 25.7864 22.8102 25.8783 22.7914L36.1989 20.7142C36.2206 20.7099 36.2432 20.7099 36.2653 20.7142L46.802 22.7914C46.8939 22.8097 46.954 22.8988 46.9358 22.9908C46.9175 23.0827 46.8285 23.1429 46.7366 23.1246L36.2331 21.0541L25.9456 23.1246C25.9346 23.127 25.923 23.128 25.912 23.128Z"
      fill="#64350F"
    />
    <path
      d="M36.2716 22.4709C36.262 22.4709 36.2519 22.4699 36.2422 22.4685L32.3758 21.8257C32.2786 21.8093 32.2127 21.7173 32.2291 21.6201C32.2454 21.5228 32.3373 21.4568 32.4345 21.4732L36.2716 22.1112L40.1039 21.4732C40.2011 21.4568 40.293 21.5228 40.3093 21.6201C40.3257 21.7173 40.2598 21.8093 40.1626 21.8257L36.3009 22.4685C36.2913 22.4699 36.2812 22.4709 36.2716 22.4709Z"
      fill="#64350F"
    />
    <path
      d="M36.2883 21.0613C36.2883 21.0613 36.2874 21.0613 36.2864 21.0613C36.1878 21.0603 36.1084 20.9794 36.1094 20.8807L36.145 17.2299C36.1459 17.1312 36.2263 17.0518 36.3254 17.0527C36.424 17.0537 36.5034 17.1346 36.5024 17.2333L36.4668 20.8841C36.4659 20.9823 36.386 21.0613 36.2883 21.0613Z"
      fill="#64350F"
    />
    <path
      d="M31.6458 17.1742C31.6458 17.1742 27.4936 18.3457 27.8409 22.634L28.349 22.3803C28.4971 22.3061 28.6776 22.3379 28.7887 22.4607C28.9936 22.6875 29.3039 23.1719 29.5772 24.1782C29.5998 24.261 29.7215 24.2461 29.722 24.1599C29.7335 22.3114 30.2719 17.6807 35.3088 16.9643L34.4866 16.1934L31.6458 17.1747V17.1742Z"
      fill="#FFC78D"
    />
    <path
      d="M29.6527 24.4408C29.5262 24.4408 29.4155 24.3575 29.3814 24.2324C29.1076 23.2241 28.7988 22.7768 28.6395 22.6C28.5919 22.5476 28.513 22.5336 28.4471 22.5663L27.9391 22.8201C27.877 22.8509 27.8039 22.849 27.7438 22.8153C27.6836 22.7811 27.6442 22.7194 27.6384 22.6506C27.5561 21.6351 27.7134 20.7034 28.106 19.8814C28.4202 19.2232 28.8844 18.6358 29.4848 18.1355C30.4729 17.3121 31.4904 17.0035 31.5881 16.9751L34.4241 15.9957C34.4972 15.9706 34.578 15.988 34.6343 16.0404L35.4565 16.8113C35.5152 16.8662 35.5368 16.95 35.5128 17.0261C35.4887 17.1022 35.4228 17.1585 35.3434 17.1696C30.497 17.8591 29.9471 22.288 29.9356 24.1601C29.9346 24.3027 29.8317 24.4197 29.6902 24.438C29.6777 24.4394 29.6647 24.4404 29.6522 24.4404L29.6527 24.4408ZM28.5207 22.132C28.6804 22.132 28.8373 22.1975 28.9484 22.3203C29.1139 22.5033 29.3342 22.8292 29.5512 23.3993C29.5762 23.0753 29.6195 22.7074 29.6917 22.3179C29.847 21.4796 30.1025 20.7135 30.4508 20.0413C30.8886 19.1958 31.475 18.4966 32.1933 17.9631C32.9394 17.4089 33.8362 17.028 34.8643 16.8282L34.4409 16.4309L31.7185 17.3709C31.7146 17.3723 31.7108 17.3733 31.7069 17.3742C31.6886 17.3795 30.6846 17.6732 29.7393 18.4653C28.5597 19.4534 27.9877 20.7443 28.0353 22.3054L28.26 22.1932C28.3427 22.1518 28.4317 22.132 28.5197 22.132H28.5207Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M29.9285 18.5661C30.8777 17.7707 31.886 17.4779 31.8961 17.475C31.9 17.4741 31.9038 17.4726 31.9072 17.4717L34.57 16.552L34.4391 16.4297L31.7167 17.3696C31.7128 17.371 31.709 17.372 31.7056 17.373C31.6955 17.3759 30.6872 17.6686 29.738 18.4641C28.5584 19.4521 27.9864 20.743 28.034 22.3041L28.2216 22.2102C28.2259 20.7368 28.798 19.5123 29.928 18.5657L29.9285 18.5661Z"
        fill="white"
      />
    </g>
    <g opacity="0.6">
      <path
        d="M29.1378 22.4201C29.0675 22.3421 28.979 22.2877 28.8828 22.2578C28.9054 22.2761 28.9276 22.2959 28.9478 22.318C29.1133 22.501 29.3336 22.8274 29.5506 23.398C29.5578 23.307 29.5669 23.2117 29.577 23.1139C29.4159 22.774 29.2614 22.5568 29.1378 22.4201Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.23">
      <path
        d="M35.3085 16.9643L34.4864 16.1934L34.2819 16.2641L34.7553 16.7081C33.8297 16.8395 33.0566 17.1039 32.4105 17.4583L30.3091 17.6726L29.7674 18.1319L29.7751 18.1661C29.7751 18.1661 29.9685 18.1998 30.2971 18.2138C30.6776 18.2297 30.885 18.6664 30.6541 18.9688C29.6318 20.3078 29.2989 21.9464 29.2031 23.1218C29.3268 23.3861 29.4557 23.7304 29.5774 24.1777C29.6 24.2606 29.7217 24.2456 29.7222 24.1594C29.7309 22.7164 30.0614 19.5784 32.6015 17.9302C32.7603 17.8839 32.9214 17.8305 33.0855 17.7693L33.1639 17.61C33.7686 17.3081 34.4772 17.0822 35.3095 16.9638L35.3085 16.9643Z"
        fill="#232323"
      />
    </g>
    <path
      d="M41.2449 17.1113C41.2449 17.1113 45.4145 18.2198 45.1321 22.5129L44.6202 22.2668C44.4711 22.1951 44.2907 22.2293 44.1815 22.354C43.9804 22.5837 43.6768 23.0729 43.419 24.0831C43.3978 24.1664 43.2756 24.1529 43.2737 24.0672C43.2342 22.2192 42.6256 17.5972 37.5781 16.9572L38.3887 16.1738L41.2445 17.1118L41.2449 17.1113Z"
      fill="#FFC78D"
    />
    <path
      d="M43.3536 24.3468C43.3425 24.3468 43.3315 24.3463 43.3199 24.3448C43.1785 24.3285 43.0736 24.2134 43.0707 24.0709C43.0308 22.1992 42.414 17.7795 37.5575 17.1636C37.4781 17.1535 37.4113 17.0986 37.3858 17.0225C37.3603 16.9465 37.3809 16.8627 37.4387 16.8068L38.2493 16.0234C38.3051 15.9695 38.3854 15.9512 38.4591 15.9753L41.3095 16.9113C41.4076 16.9383 42.4299 17.2315 43.4301 18.0395C44.0382 18.5311 44.5111 19.1113 44.8353 19.7643C45.2404 20.5804 45.4117 21.5092 45.3448 22.5257C45.3405 22.595 45.3015 22.6572 45.2418 22.6923C45.1822 22.7275 45.1091 22.7303 45.0465 22.7L44.5346 22.454C44.4687 22.4222 44.3898 22.4371 44.3427 22.4906C44.1859 22.6697 43.8842 23.1213 43.6254 24.1339C43.5927 24.2615 43.4811 24.3468 43.3531 24.3468H43.3536ZM38.0314 16.8136C39.0619 16.998 39.9644 17.3649 40.7192 17.908C41.4456 18.4305 42.0426 19.121 42.4929 19.9593C42.8513 20.6266 43.1183 21.3884 43.2867 22.2243C43.3647 22.6124 43.4137 22.9802 43.4436 23.3033C43.6519 22.7299 43.8674 22.401 44.0295 22.2151C44.1993 22.0216 44.4808 21.9647 44.7151 22.0774L44.9416 22.1862C44.9657 20.6194 44.3701 19.3343 43.1684 18.3631C42.2067 17.5864 41.206 17.3143 41.1964 17.3114C41.1926 17.3105 41.1887 17.3095 41.1849 17.3081L38.4485 16.4096L38.0309 16.8131L38.0314 16.8136Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M42.6488 19.8419C41.7203 18.1143 40.2208 17.0574 38.1872 16.6957L38.473 16.4198L38.4562 16.4141L38.0391 16.8171C40.0726 17.1787 41.5721 18.2356 42.5006 19.9633C43.1255 21.1261 43.3642 22.3862 43.4512 23.3083C43.4936 23.1913 43.5364 23.0849 43.5792 22.9871C43.4777 22.0877 43.2304 20.9229 42.6493 19.8414L42.6488 19.8419Z"
        fill="white"
      />
      <path
        d="M44.8745 21.9596C44.6407 21.8474 44.3583 21.9037 44.1889 22.0973C44.1885 22.0978 44.188 22.0987 44.1875 22.0992C44.3491 22.0077 44.5512 21.9971 44.7258 22.0814L44.9524 22.1902C44.9534 22.1252 44.9529 22.0607 44.9519 21.9966L44.8745 21.9596Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.15">
      <path
        d="M43.2957 18.1976C43.3813 18.2106 43.4545 18.2256 43.5103 18.2429L42.5596 17.6911C42.5596 17.6911 42.5577 17.6901 42.5572 17.6896L42.5447 17.6824C41.8337 17.2678 41.2549 17.1138 41.2549 17.1138L38.3992 16.1758L38.0938 16.4709L40.8263 17.3685C40.8263 17.3685 41.0077 17.4166 41.2925 17.5322L39.7963 17.3531L40.6089 17.9959C40.6089 17.9959 41.0808 18.0498 41.6451 18.0912C42.2195 18.1336 42.7655 18.3671 43.185 18.7624C43.9962 19.5271 44.7149 20.6654 44.7269 22.315L45.1426 22.5148C45.284 20.362 44.3065 19.0104 43.2962 18.1976H43.2957Z"
        fill="#232323"
      />
    </g>
    <path
      d="M36.0554 15.9631C36.0554 15.9631 34.0974 13.5936 31.5357 13.0543C31.5357 13.0543 30.2675 12.6682 29.0619 13.7631C28.7338 14.0612 28.4721 14.4252 28.2792 14.8244C28.1503 15.0906 27.9853 15.4585 27.8621 15.8245C27.6177 16.5506 28.006 17.3475 28.7358 17.5781C29.1408 17.7062 29.7037 17.777 30.4864 17.7327C30.4864 17.7327 34.0767 17.5642 35.2857 17.374L36.0848 17.0995L36.055 15.9636L36.0554 15.9631Z"
      fill="#FFC78D"
    />
    <g opacity="0.6">
      <path
        d="M28.1495 16.2792C28.2726 15.9138 28.4377 15.5459 28.5666 15.2791C28.7595 14.88 29.0212 14.5159 29.3493 14.2179C30.5544 13.1229 31.823 13.5091 31.823 13.5091C33.8551 13.9367 35.5067 15.5156 36.1032 16.1507L36.1023 16.1174C36.1023 16.1174 34.1443 13.7475 31.5825 13.2086C31.5825 13.2086 30.3144 12.8225 29.1088 13.9174C28.7807 14.2155 28.519 14.5795 28.326 14.9787C28.1971 15.2449 28.0321 15.6128 27.9089 15.9788C27.7309 16.5079 27.8887 17.0742 28.2702 17.432C28.072 17.0963 28.0153 16.6774 28.1495 16.2792Z"
        fill="white"
      />
    </g>
    <path
      d="M32.0346 16.4752L30.4293 16.0337L29.0437 15.9952C28.5665 15.9066 28.0041 16.1574 27.7891 16.2662C27.7867 16.8494 28.1513 17.3963 28.7354 17.5808C29.1404 17.7088 29.7033 17.7796 30.486 17.7353C30.486 17.7353 34.0763 17.5668 35.2853 17.3766L35.713 17.2297V17.1854L34.6888 17.0217L32.0346 16.4752Z"
      fill="white"
    />
    <path
      d="M30.0386 17.7787C29.522 17.7787 29.0818 17.7219 28.7253 17.6097C28.4366 17.5187 28.1918 17.3391 28.0166 17.0907C27.8487 16.8518 27.7569 16.5596 27.7578 16.2668V16.2485L27.7742 16.2403C27.995 16.1281 28.5607 15.8773 29.0452 15.9659L30.4322 16.0044L32.0399 16.4469L34.6921 16.9934L35.7419 17.161L35.7404 17.252L35.2882 17.407C34.0918 17.5953 30.5216 17.7643 30.4856 17.7657C30.3307 17.7744 30.181 17.7787 30.0377 17.7787H30.0386ZM27.8175 16.2851C27.8228 16.8711 28.1927 17.3791 28.7431 17.5529C29.1914 17.6945 29.7764 17.746 30.4832 17.706C30.5192 17.7041 34.0812 17.5356 35.2771 17.3483L35.6779 17.2106L34.6816 17.0517L32.0265 16.5047H32.0255L30.4235 16.0636L29.0389 16.0251H29.0365C28.5805 15.9403 28.0436 16.1719 27.817 16.2851H27.8175Z"
      fill="#232323"
    />
    <path
      d="M30.0471 17.9535C29.5111 17.9535 29.0527 17.8943 28.6789 17.7763C28.2781 17.6497 27.952 17.3718 27.76 16.9934C27.5642 16.6072 27.5325 16.1681 27.6705 15.7573C27.7999 15.3736 27.9741 14.9884 28.0972 14.7332C28.3089 14.2945 28.5884 13.9165 28.9276 13.6084C29.5934 13.0031 30.2799 12.8293 30.7384 12.7898C31.1988 12.7503 31.5283 12.8336 31.5913 12.8514C32.1932 12.979 32.8114 13.215 33.4281 13.553C33.9203 13.8226 34.4134 14.1578 34.893 14.5487C35.7094 15.2137 36.2006 15.805 36.2213 15.83C36.2511 15.8662 36.268 15.9109 36.2689 15.9576L36.2987 17.0935C36.3011 17.1845 36.2444 17.2669 36.1583 17.2962L35.3592 17.5707C35.3476 17.5745 35.3361 17.5774 35.3241 17.5794C34.1204 17.7686 30.6643 17.9328 30.5031 17.94C30.3453 17.9487 30.1933 17.9535 30.0471 17.9535ZM30.9828 13.1962C30.9125 13.1962 30.8365 13.1991 30.7557 13.2068C30.1885 13.2597 29.6675 13.4986 29.2076 13.9165C28.9083 14.1886 28.6611 14.5242 28.4725 14.9142C28.3541 15.1588 28.1877 15.5267 28.0655 15.8897C27.963 16.1936 27.9866 16.5181 28.1314 16.8037C28.2724 17.081 28.5115 17.2852 28.8044 17.3776C29.2321 17.5129 29.7959 17.5615 30.4805 17.523C30.481 17.523 30.482 17.523 30.4825 17.523C30.518 17.5216 34.0401 17.3545 35.2413 17.1696L35.8783 16.9505L35.8542 16.0419C35.7157 15.8835 35.2716 15.3938 34.6226 14.8661C33.9034 14.281 32.7772 13.527 31.498 13.2578C31.4922 13.2564 31.486 13.2549 31.4802 13.253C31.4783 13.2525 31.2835 13.1962 30.9823 13.1962H30.9828Z"
      fill="#64350F"
    />
    <path
      d="M35.384 17.2868C34.774 17.2868 32.0612 16.6377 30.9176 16.3574C30.5915 16.2775 30.2874 16.2212 29.9882 16.1851C29.1622 16.0859 28.6397 16.1605 28.3472 16.2404C28.035 16.3257 27.9133 16.4345 27.9123 16.4355C27.9137 16.434 27.9147 16.433 27.9147 16.433L27.6641 16.1783C27.6804 16.1624 27.8344 16.018 28.2082 15.9082C28.6844 15.7686 29.2978 15.7421 30.031 15.8302C30.3447 15.8678 30.6622 15.9265 31.0028 16.0103C32.8319 16.4586 34.9452 16.9357 35.3903 16.9295C35.3912 16.9295 35.3922 16.9295 35.3927 16.9295C35.4903 16.9295 35.5697 17.008 35.5711 17.1057C35.5726 17.2044 35.4937 17.2858 35.3951 17.2868C35.3912 17.2868 35.3878 17.2868 35.384 17.2868Z"
      fill="#64350F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
      <path
        d="M35.1624 17.3921C35.1624 17.3921 35.5939 17.0911 33.9866 16.9178L31.4268 16.3154L29.3625 15.9688L28.2661 16.053C28.2661 16.053 27.6907 16.1204 27.7643 16.5119C27.7643 16.5119 28.039 17.6824 29.2845 17.7099L35.1629 17.3921H35.1624Z"
        fill="#FFC78D"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.15">
      <path
        d="M35.5453 15.5176C34.8915 16.0116 34.0481 16.1854 33.2558 15.9798C31.9362 15.638 29.8699 15.3105 28.2266 15.9919C28.2266 15.9919 29.1368 15.7169 32.6164 16.5586C33.3284 16.731 34.1415 16.7421 35.0834 17.0416C35.0834 17.0416 35.3865 16.1955 35.8681 15.8777L35.5458 15.5176H35.5453Z"
        fill="#232323"
      />
    </g>
    <path
      d="M36.7261 15.9564C36.7261 15.9564 38.686 13.5883 41.2488 13.0519C41.2488 13.0519 42.5174 12.6667 43.7215 13.7631C44.0496 14.0617 44.3104 14.4257 44.5033 14.8253C44.6317 15.0916 44.7967 15.46 44.9194 15.8254C45.1633 16.5515 44.7741 17.348 44.0439 17.5781C43.6388 17.7057 43.0759 17.776 42.2932 17.7312C42.2932 17.7312 38.7029 17.5593 37.4944 17.3682L36.6953 17.0928L36.7261 15.9569V15.9564Z"
      fill="#FFC78D"
    />
    <g opacity="0.6">
      <path
        d="M41.1396 13.559C41.1396 13.559 42.4082 13.1738 43.6124 14.2702C43.9405 14.5688 44.2012 14.9328 44.3942 15.3324C44.5226 15.5987 44.6876 15.9671 44.8103 16.3325C44.9003 16.6007 44.9036 16.8781 44.8358 17.1333C45.0398 16.7962 45.0989 16.3725 44.9642 15.97C44.8416 15.6045 44.6765 15.2361 44.5481 14.9699C44.3557 14.5702 44.0944 14.2062 43.7663 13.9077C42.5622 12.8113 41.2936 13.1965 41.2936 13.1965C38.7308 13.7329 36.7709 16.1009 36.7709 16.1009L36.7656 16.295C37.2679 15.7446 38.9921 14.0088 41.1396 13.559Z"
        fill="white"
      />
    </g>
    <path
      d="M40.8278 16.4838L42.4336 16.0437L43.8191 16.0067C44.2968 15.9186 44.8583 16.1699 45.0733 16.2792C45.0752 16.8623 44.7096 17.4088 44.1256 17.5928C43.7205 17.7204 43.1576 17.7907 42.3749 17.7459C42.3749 17.7459 38.7846 17.574 37.5761 17.3828L37.1484 17.2355V17.1912L38.1731 17.0284L40.8278 16.4843V16.4838Z"
      fill="white"
    />
    <path
      d="M42.7511 17.9542C42.6025 17.9542 42.4481 17.9494 42.2879 17.9402C42.1281 17.9325 38.6706 17.7655 37.4669 17.5748C37.4549 17.5728 37.4434 17.57 37.4318 17.5661L36.6327 17.2907C36.5466 17.2608 36.4899 17.179 36.4923 17.088L36.523 15.9521C36.5245 15.9054 36.5413 15.8606 36.5707 15.8245C36.5914 15.7995 37.083 15.2091 37.8999 14.5446C38.38 14.1541 38.8736 13.8195 39.3658 13.5503C39.983 13.2128 40.6012 12.9773 41.2035 12.8507C41.2665 12.8334 41.5961 12.7501 42.0565 12.79C42.5149 12.83 43.2014 13.0048 43.8668 13.61C44.2055 13.9182 44.4845 14.2971 44.6957 14.7358C44.8188 14.991 44.9925 15.3762 45.1214 15.7605C45.2595 16.1712 45.2273 16.6103 45.031 16.9965C44.8386 17.3745 44.5119 17.6523 44.1112 17.7785C43.7393 17.8955 43.2827 17.9547 42.7502 17.9547L42.7511 17.9542ZM37.5502 17.1655C38.7514 17.3518 42.2729 17.5218 42.3085 17.5237C42.309 17.5237 42.31 17.5237 42.3105 17.5237C42.9951 17.5632 43.5594 17.5146 43.987 17.3802C44.28 17.2878 44.5196 17.0841 44.6606 16.8068C44.8058 16.5212 44.8294 16.1967 44.7274 15.8929C44.6052 15.5293 44.4393 15.1615 44.3214 14.9168C44.1333 14.5263 43.886 14.1907 43.5873 13.9187C43.1278 13.5003 42.6068 13.2609 42.0396 13.207C41.6129 13.1666 41.317 13.2523 41.3142 13.2528C41.3084 13.2547 41.3031 13.2556 41.2973 13.2571C40.0143 13.5258 38.8852 14.2813 38.1635 14.8682C37.5165 15.3945 37.0758 15.8794 36.9382 16.0368L36.9137 16.9459L37.5506 17.1655H37.5502Z"
      fill="#64350F"
    />
    <path
      d="M37.3991 17.281C37.3947 17.281 37.3909 17.281 37.387 17.281C37.2884 17.2796 37.2095 17.1982 37.211 17.0995C37.2124 17.0008 37.2937 16.9218 37.3923 16.9233C37.3952 16.9233 37.3986 16.9233 37.4019 16.9233C37.8676 16.9233 39.9642 16.4519 41.7808 16.0084C42.1214 15.9251 42.4394 15.8668 42.7525 15.8293C43.4862 15.7421 44.0991 15.7691 44.5754 15.9092C44.9487 16.019 45.1026 16.1639 45.119 16.1798L44.8679 16.434C44.8679 16.434 44.8688 16.435 44.8703 16.4365C44.8693 16.4355 44.7476 16.3267 44.4354 16.241C44.1429 16.161 43.6209 16.0859 42.7944 16.1841C42.4952 16.2198 42.1916 16.2756 41.865 16.3556C40.7219 16.6348 38.01 17.2805 37.3986 17.2805L37.3991 17.281Z"
      fill="#64350F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
      <path
        d="M37.576 17.3829C37.576 17.3829 37.1449 17.0815 38.7522 16.9096L41.313 16.3096L43.3778 15.9648L44.4742 16.0501C44.4742 16.0501 45.0496 16.1184 44.9755 16.5094C44.9755 16.5094 44.6994 17.68 43.4538 17.706L37.576 17.3824V17.3829Z"
        fill="#FFC78D"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.13">
      <path
        d="M37.3545 15.5391C38.0078 16.0336 38.8507 16.2084 39.644 16.0037C40.9641 15.6633 43.0304 15.3378 44.6732 16.0211C44.6732 16.0211 43.7635 15.7451 40.2824 16.5835C39.5704 16.7549 38.7569 16.7655 37.8149 17.064C37.8149 17.064 37.5128 16.2175 37.0312 15.8992L37.3541 15.5391H37.3545Z"
        fill="#232323"
      />
    </g>
    <path
      d="M35.1257 17.2375C35.0156 17.2375 34.9246 17.1518 34.9179 17.042C34.9155 17.0001 34.9141 16.9649 34.9141 16.9308C34.9141 16.0727 35.5347 15.375 36.2972 15.375C37.0597 15.375 37.6803 16.0727 37.6803 16.9308C37.6803 16.9649 37.6788 17.0001 37.6764 17.042C37.6697 17.1518 37.5783 17.2375 37.4686 17.2375H35.1262H35.1257Z"
      fill="#FFC78D"
    />
    <path
      d="M36.3024 15.583C36.9513 15.583 37.4772 16.1863 37.4772 16.9303C37.4772 16.9635 37.4757 16.9957 37.4738 17.028H35.1314C35.1295 16.9957 35.128 16.963 35.128 16.9303C35.128 16.1863 35.6539 15.583 36.3028 15.583M36.3024 15.166C36.0801 15.166 35.8655 15.2156 35.6635 15.3138C35.473 15.4063 35.3027 15.5373 35.1579 15.7039C34.8697 16.0342 34.7109 16.4699 34.7109 16.9307C34.7109 16.9693 34.7124 17.0087 34.7153 17.055C34.7292 17.2745 34.9111 17.446 35.1314 17.446H37.4738C37.6937 17.446 37.876 17.2745 37.8899 17.055C37.8928 17.0087 37.8943 16.9693 37.8943 16.9307C37.8943 16.4699 37.7355 16.0342 37.4473 15.7039C37.3025 15.5377 37.1322 15.4063 36.9417 15.3138C36.7397 15.2161 36.5246 15.166 36.3028 15.166H36.3024Z"
      fill="#64350F"
    />
    <g opacity="0.6">
      <path
        d="M35.2675 17.0193C35.2675 16.2754 35.7933 15.6721 36.4423 15.6721C36.6304 15.6721 36.8084 15.7231 36.9662 15.8136C36.7786 15.6677 36.552 15.582 36.3076 15.582C35.6586 15.582 35.1328 16.1854 35.1328 16.9293C35.1328 16.9625 35.1343 16.9948 35.1362 17.027H35.2675C35.2675 17.0241 35.2675 17.0217 35.2675 17.0193Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.12">
      <path
        d="M37.0257 15.7832C37.0257 15.7832 37.1575 16.8377 35.0234 16.7905V17.1825H37.6405L37.6771 16.6355L37.3394 15.8728L37.0252 15.7832H37.0257Z"
        fill="#606060"
      />
    </g>
    <path
      d="M29.1826 43.0298C29.0902 43.0298 29.0156 42.9551 29.0156 42.8627V33.9972C29.0156 33.9047 29.0902 33.8301 29.1826 33.8301C29.2749 33.8301 29.3495 33.9047 29.3495 33.9972V42.8627C29.3495 42.9551 29.2749 43.0298 29.1826 43.0298Z"
      fill="#64350F"
    />
    <path
      d="M31.5497 44.1252C31.4574 44.1252 31.3828 44.0506 31.3828 43.9581V34.4132C31.3828 34.3207 31.4579 34.2461 31.5497 34.2461C31.6416 34.2461 31.7167 34.3212 31.7167 34.4132V43.9581C31.7167 44.0506 31.6416 44.1252 31.5497 44.1252Z"
      fill="#64350F"
    />
    <path
      d="M41.1122 44.34C41.0199 44.34 40.9453 44.2653 40.9453 44.1729V34.5186C40.9453 34.4262 41.0204 34.3516 41.1122 34.3516C41.2041 34.3516 41.2792 34.4262 41.2792 34.5186V44.1729C41.2792 44.2653 41.2041 44.34 41.1122 44.34Z"
      fill="#64350F"
    />
    <path
      d="M43.456 43.1061C43.3636 43.1061 43.2891 43.0315 43.2891 42.9391V33.9132C43.2891 33.8207 43.3641 33.7461 43.456 33.7461C43.5479 33.7461 43.6229 33.8207 43.6229 33.9132V42.9391C43.6229 43.0315 43.5479 43.1061 43.456 43.1061Z"
      fill="#64350F"
    />
    <path
      d="M27.4538 32.096C27.3809 32.096 27.3124 32.0511 27.2866 31.9793C27.0619 31.3572 26.7577 30.9555 26.4136 30.8081C26.3806 30.9292 26.3354 31.0484 26.278 31.1637C26.1663 31.3873 25.97 31.5475 25.7388 31.6045C25.4831 31.6673 25.2623 31.5879 25.1183 31.3803C25.0311 31.2553 25.0388 31.0862 25.1383 30.9164C25.2991 30.6434 25.682 30.3878 26.123 30.3935C26.1372 30.1276 26.083 29.8502 25.9603 29.5638C25.8111 29.2146 25.6051 28.9666 25.5548 28.909C25.547 28.9032 25.5393 28.8981 25.5315 28.8929C25.4502 29.1313 25.2984 29.3421 25.0905 29.4978C24.6056 29.8611 24.1232 29.92 23.6957 29.6682C23.3948 29.4907 23.267 29.1236 23.3916 28.7949C23.5291 28.4329 23.7642 28.1926 24.0715 28.0997C24.395 28.0023 24.7876 28.0715 25.2442 28.3054C25.228 27.6993 25.0421 27.2405 24.6895 26.9393C23.9779 26.3313 22.8008 26.5408 22.7885 26.5434C22.6923 26.5613 22.5993 26.4985 22.5812 26.4024C22.5631 26.3069 22.6264 26.2147 22.7226 26.1967C22.7775 26.1865 24.0767 25.9526 24.9194 26.6709C25.3953 27.0765 25.6232 27.6954 25.598 28.5117C25.6568 28.5501 25.7162 28.5905 25.7769 28.6334C25.7866 28.6405 25.7963 28.6488 25.804 28.6571C25.8156 28.6693 26.0862 28.9641 26.2812 29.4145C26.4336 29.7663 26.4969 30.1167 26.4723 30.4531C26.8481 30.5806 27.2892 30.9446 27.6198 31.8595C27.6527 31.9512 27.6049 32.0517 27.5126 32.0851C27.4926 32.0921 27.4726 32.0953 27.4525 32.0953L27.4538 32.096ZM25.4127 31.1829C25.4547 31.2419 25.516 31.2957 25.6536 31.2624C25.7827 31.2303 25.8944 31.1374 25.9596 31.0074C26.0023 30.9221 26.0365 30.835 26.0629 30.7466C26.0081 30.7504 25.9525 30.76 25.8964 30.7754C25.7517 30.8158 25.6129 30.8984 25.5154 31.0022C25.4256 31.0977 25.4121 31.1663 25.4127 31.1829ZM24.3744 28.4092C24.3027 28.4092 24.2362 28.4182 24.1749 28.4367C23.974 28.4976 23.8223 28.6597 23.7241 28.9192C23.6602 29.0877 23.7241 29.2761 23.8772 29.3658C24.1749 29.5407 24.5106 29.4907 24.8768 29.2171C25.0511 29.0864 25.1712 28.9019 25.2196 28.6943C24.8923 28.5059 24.6068 28.4092 24.3744 28.4092Z"
      fill="#64350F"
    />
    <path
      d="M45.883 31.5004C45.8692 31.5004 45.8548 31.4982 45.841 31.4933C45.7757 31.4704 45.7419 31.3996 45.7652 31.3353C45.9987 30.6942 46.3097 30.4399 46.5747 30.3506C46.557 30.1153 46.6024 29.8696 46.7098 29.6234C46.8475 29.308 47.0385 29.1016 47.0468 29.0934C47.0528 29.0874 47.0589 29.0815 47.0661 29.0766C47.1087 29.0466 47.1508 29.0183 47.1923 28.9916C47.1746 28.4202 47.3356 27.9867 47.6715 27.7029C48.2664 27.2001 49.1833 27.3635 49.222 27.3712C49.2901 27.3837 49.3349 27.4485 49.3216 27.5155C49.3089 27.5825 49.2431 27.6266 49.175 27.6135C49.1601 27.6108 48.3339 27.467 47.8336 27.8908C47.5852 28.1016 47.4535 28.4224 47.4424 28.8467C47.7645 28.6828 48.0417 28.6343 48.2697 28.7024C48.4866 28.7672 48.6526 28.9355 48.7495 29.1893C48.8375 29.4197 48.7473 29.6768 48.5348 29.801C48.2332 29.9775 47.8929 29.9361 47.5503 29.6817C47.4037 29.5728 47.2963 29.4251 47.2393 29.2579C47.2338 29.2617 47.2283 29.2656 47.2227 29.2694C47.1879 29.3091 47.0423 29.4829 46.9366 29.728C46.8503 29.9284 46.8121 30.1229 46.8215 30.3086C47.1331 30.3048 47.4031 30.4835 47.5166 30.6746C47.5874 30.7934 47.5924 30.9116 47.531 30.9993C47.4291 31.1447 47.2736 31.2003 47.0927 31.1567C46.9294 31.1169 46.7911 31.0047 46.7125 30.8478C46.6721 30.7672 46.64 30.6839 46.6168 30.5989C46.3739 30.7019 46.1592 30.9835 46.0003 31.4187C45.9821 31.4688 45.9339 31.5004 45.8825 31.5004H45.883ZM46.8652 30.5559C46.8835 30.618 46.9079 30.6785 46.9377 30.7384C46.9837 30.8293 47.0622 30.8947 47.1536 30.917C47.2504 30.9404 47.2941 30.9029 47.3234 30.8615C47.3306 30.8124 47.21 30.6398 46.9826 30.5766C46.9433 30.5657 46.904 30.5586 46.8652 30.5564V30.5559ZM47.4596 29.1185C47.4939 29.2634 47.578 29.393 47.7014 29.4845C47.9593 29.6762 48.1967 29.7117 48.4069 29.5886C48.5148 29.5254 48.5602 29.3941 48.5148 29.2759C48.4457 29.0945 48.3383 28.9807 48.1967 28.9382C48.0135 28.8832 47.7617 28.9453 47.4596 29.1185Z"
      fill="#64350F"
    />
    <path
      d="M44.7596 25.5661C44.7481 25.5661 44.7366 25.5646 44.7255 25.5608C44.6625 25.542 44.6269 25.4755 44.6456 25.4125L44.8568 24.7099C44.8756 24.6469 44.942 24.6112 45.005 24.63C45.068 24.6488 45.1036 24.7152 45.0849 24.7783L44.8737 25.4808C44.8583 25.5324 44.8106 25.5656 44.7596 25.5656V25.5661Z"
      fill="#64350F"
    />
    <path
      d="M44.1346 27.6403C44.1231 27.6403 44.1116 27.6388 44.1005 27.635C44.0375 27.6162 44.0019 27.5498 44.0206 27.4867L44.2318 26.7842C44.2506 26.7211 44.317 26.6854 44.38 26.7042C44.443 26.723 44.4786 26.7895 44.4599 26.8525L44.2487 27.5551C44.2333 27.6066 44.1856 27.6398 44.1346 27.6398V27.6403Z"
      fill="#64350F"
    />
    <path
      d="M45.1817 25.9135C45.1394 25.9135 45.098 25.8909 45.0768 25.8509C45.0455 25.7931 45.0672 25.7209 45.1254 25.6896L45.7705 25.3424C45.8283 25.3112 45.9004 25.3328 45.9317 25.3911C45.963 25.4489 45.9413 25.5211 45.8831 25.5524L45.238 25.8996C45.2202 25.9092 45.2009 25.914 45.1817 25.914V25.9135Z"
      fill="#64350F"
    />
    <path
      d="M43.2754 26.9389C43.2331 26.9389 43.1917 26.9163 43.1706 26.8763C43.1393 26.8185 43.1609 26.7463 43.2192 26.715L43.8643 26.3678C43.922 26.3365 43.9942 26.3582 44.0254 26.4165C44.0567 26.4743 44.0351 26.5465 43.9769 26.5778L43.3317 26.9249C43.3139 26.9346 43.2947 26.9394 43.2754 26.9394V26.9389Z"
      fill="#64350F"
    />
    <path
      d="M45.9459 26.6687C45.9344 26.6687 45.9228 26.6672 45.9118 26.6634L45.2099 26.452C45.1468 26.4332 45.1112 26.3668 45.13 26.3037C45.1488 26.2406 45.2152 26.205 45.2782 26.2238L45.9801 26.4351C46.0431 26.4539 46.0787 26.5204 46.0599 26.5834C46.0445 26.635 45.9969 26.6682 45.9459 26.6682V26.6687Z"
      fill="#64350F"
    />
    <path
      d="M43.8751 26.0456C43.8636 26.0456 43.852 26.0442 43.841 26.0403L43.1396 25.8289C43.0765 25.8102 43.0409 25.7437 43.0597 25.6806C43.0785 25.6176 43.1448 25.5819 43.2079 25.6007L43.9093 25.8121C43.9723 25.8309 44.0079 25.8973 43.9891 25.9604C43.9737 26.0119 43.9261 26.0451 43.8751 26.0451V26.0456Z"
      fill="#64350F"
    />
    <path
      d="M45.2395 27.5285C45.1972 27.5285 45.1558 27.5058 45.1346 27.4659L44.7877 26.8202C44.7565 26.7624 44.7781 26.6902 44.8363 26.6589C44.8941 26.6276 44.9662 26.6492 44.9975 26.7075L45.3444 27.3532C45.3756 27.411 45.354 27.4832 45.2958 27.5145C45.278 27.5241 45.2587 27.5289 45.2395 27.5289V27.5285Z"
      fill="#64350F"
    />
    <path
      d="M44.216 25.6222C44.1737 25.6222 44.1323 25.5996 44.1112 25.5596L43.7643 24.9139C43.733 24.8561 43.7547 24.7839 43.8129 24.7526C43.8706 24.7213 43.9428 24.743 43.9741 24.8012L44.3209 25.4469C44.3522 25.5047 44.3305 25.5769 44.2723 25.6082C44.2545 25.6179 44.2353 25.6227 44.216 25.6227V25.6222Z"
      fill="#64350F"
    />
    <path
      d="M31.0643 27.1886C31.0528 27.1886 31.0412 27.1872 31.0302 27.1833C30.9672 27.1646 30.9316 27.0981 30.9503 27.035L31.1403 26.4033C31.1591 26.3402 31.2255 26.3046 31.2885 26.3234C31.3515 26.3421 31.3871 26.4086 31.3684 26.4717L31.1784 27.1034C31.163 27.1549 31.1153 27.1882 31.0643 27.1882V27.1886Z"
      fill="#64350F"
    />
    <path
      d="M30.5018 29.0558C30.4903 29.0558 30.4787 29.0544 30.4677 29.0505C30.4047 29.0318 30.3691 28.9653 30.3878 28.9022L30.5778 28.2705C30.5966 28.2074 30.663 28.1718 30.726 28.1906C30.789 28.2093 30.8246 28.2758 30.8059 28.3389L30.6159 28.9706C30.6005 29.0221 30.5528 29.0553 30.5018 29.0553V29.0558Z"
      fill="#64350F"
    />
    <path
      d="M31.4395 27.5019C31.3972 27.5019 31.3558 27.4793 31.3346 27.4393C31.3034 27.3815 31.325 27.3093 31.3832 27.278L31.9639 26.9655C32.0216 26.9342 32.0938 26.9559 32.125 27.0141C32.1563 27.0719 32.1347 27.1441 32.0765 27.1754L31.4958 27.4879C31.478 27.4976 31.4587 27.5024 31.4395 27.5024V27.5019Z"
      fill="#64350F"
    />
    <path
      d="M29.7208 28.4257C29.6784 28.4257 29.637 28.4031 29.6159 28.3631C29.5846 28.3053 29.6063 28.2331 29.6645 28.2018L30.2451 27.8893C30.3029 27.858 30.375 27.8797 30.4063 27.938C30.4376 27.9957 30.4159 28.068 30.3577 28.0993L29.777 28.4118C29.7592 28.4214 29.74 28.4262 29.7208 28.4262V28.4257Z"
      fill="#64350F"
    />
    <path
      d="M32.1252 28.1807C32.1137 28.1807 32.1021 28.1792 32.091 28.1754L31.4599 27.9852C31.3968 27.9664 31.3612 27.9 31.38 27.8369C31.3988 27.7738 31.4652 27.7382 31.5282 27.757L32.1594 27.9472C32.2224 27.9659 32.258 28.0324 32.2392 28.0955C32.2238 28.147 32.1762 28.1802 32.1252 28.1802V28.1807Z"
      fill="#64350F"
    />
    <path
      d="M30.258 27.6201C30.2465 27.6201 30.2349 27.6187 30.2239 27.6148L29.5927 27.4246C29.5297 27.4059 29.4941 27.3394 29.5128 27.2763C29.5316 27.2133 29.598 27.1776 29.661 27.1964L30.2922 27.3866C30.3552 27.4054 30.3908 27.4718 30.372 27.5349C30.3566 27.5864 30.309 27.6197 30.258 27.6197V27.6201Z"
      fill="#64350F"
    />
    <path
      d="M31.4861 28.9503C31.4438 28.9503 31.4024 28.9276 31.3812 28.8877L31.069 28.3065C31.0377 28.2487 31.0594 28.1765 31.1176 28.1452C31.1753 28.1139 31.2475 28.1356 31.2788 28.1938L31.591 28.775C31.6222 28.8328 31.6006 28.905 31.5424 28.9363C31.5246 28.9459 31.5053 28.9507 31.4861 28.9507V28.9503Z"
      fill="#64350F"
    />
    <path
      d="M30.5642 27.2354C30.5219 27.2354 30.4805 27.2128 30.4593 27.1728L30.1471 26.5916C30.1159 26.5339 30.1375 26.4616 30.1957 26.4303C30.2534 26.399 30.3256 26.4207 30.3569 26.479L30.6691 27.0602C30.7004 27.1179 30.6787 27.1902 30.6205 27.2215C30.6027 27.2311 30.5835 27.2359 30.5642 27.2359V27.2354Z"
      fill="#64350F"
    />
    <path
      d="M40.4769 27.9224C40.4562 27.9224 40.4355 27.9171 40.4172 27.9065L39.9968 27.6624C39.9463 27.633 39.9246 27.5714 39.9453 27.5165C40.1093 27.0875 40.4148 26.7461 40.829 26.5294C41.1408 26.3662 41.4035 26.3305 41.4145 26.3291C41.4198 26.3286 41.4251 26.3281 41.4299 26.3281C41.4838 26.3281 41.5324 26.3652 41.5454 26.4186L41.6652 26.9045C41.6733 26.9368 41.6671 26.9709 41.6488 26.9984C41.6305 27.0263 41.6017 27.0451 41.5689 27.0504C40.8531 27.1688 40.5909 27.8386 40.588 27.8454C40.5755 27.8781 40.5495 27.9036 40.5163 27.9152C40.5033 27.92 40.4899 27.9219 40.4764 27.9219L40.4769 27.9224Z"
      fill="#64350F"
    />
    <path
      d="M41.4318 26.4473L41.5516 26.9332C40.7603 27.0641 40.4793 27.8033 40.4793 27.8033L40.0588 27.5591C40.4346 26.5773 41.4323 26.4473 41.4323 26.4473M41.4323 26.209C41.4222 26.209 41.4116 26.2095 41.4011 26.2109C41.389 26.2124 41.1057 26.2509 40.7757 26.4242C40.3359 26.6549 40.0107 27.0179 39.8361 27.4744C39.7942 27.5837 39.838 27.707 39.939 27.7657L40.3595 28.0098C40.3966 28.0315 40.4384 28.0426 40.4807 28.0426C40.5072 28.0426 40.5337 28.0382 40.5596 28.0286C40.6256 28.005 40.677 27.9545 40.7016 27.8885C40.7059 27.8784 40.7699 27.7228 40.9108 27.5587C41.0961 27.3434 41.325 27.212 41.5906 27.1681C41.656 27.1571 41.7142 27.1195 41.7508 27.0641C41.7874 27.0088 41.7989 26.9404 41.783 26.8759L41.6632 26.39C41.6368 26.2827 41.5401 26.209 41.4323 26.209Z"
      fill="#64350F"
    />
    <path
      d="M27.3576 26.1279C27.3365 26.1279 27.3153 26.1221 27.297 26.1111C27.2686 26.0942 27.2484 26.0658 27.2412 26.0335C27.1782 25.7393 27.0199 25.4918 26.7698 25.2978C26.5788 25.15 26.4036 25.0903 26.4017 25.0898C26.3685 25.0787 26.3421 25.0537 26.3291 25.0214C26.3161 24.9891 26.3175 24.9526 26.3334 24.9217L26.5576 24.4898C26.5788 24.4494 26.6197 24.4258 26.6634 24.4258C26.6759 24.4258 26.6884 24.4277 26.701 24.432C27.1368 24.5765 27.4914 24.8659 27.7271 25.2699C27.9046 25.5737 27.9523 25.8352 27.9542 25.8462C27.9648 25.9059 27.9287 25.9642 27.871 25.9815L27.3918 26.1236C27.3807 26.127 27.3692 26.1284 27.3581 26.1284L27.3576 26.1279Z"
      fill="#FFC78D"
    />
    <path
      d="M26.6658 24.5528C27.6631 24.8831 27.8392 25.8741 27.8392 25.8741L27.36 26.0161C27.1926 25.2308 26.4416 24.9842 26.4416 24.9842L26.6658 24.5523M26.6658 24.3145C26.5787 24.3145 26.4965 24.3621 26.4546 24.443L26.2304 24.8749C26.1982 24.9375 26.1939 25.0107 26.2208 25.0757C26.2478 25.1407 26.3002 25.1898 26.3671 25.211C26.3776 25.2149 26.5354 25.2717 26.7057 25.4051C26.929 25.5803 27.0709 25.8028 27.1272 26.0662C27.1411 26.1312 27.1815 26.1875 27.2383 26.2212C27.2753 26.2434 27.3177 26.2545 27.36 26.2545C27.3826 26.2545 27.4057 26.2511 27.4278 26.2448L27.907 26.1028C28.0234 26.0681 28.0951 25.9521 28.0739 25.8327C28.072 25.8206 28.0201 25.5394 27.8324 25.2173C27.5818 24.7883 27.2046 24.4801 26.7409 24.3265C26.7163 24.3183 26.6908 24.3145 26.6663 24.3145H26.6658Z"
      fill="#64350F"
    />
    <path
      d="M49.3683 32.2568C49.3202 32.2568 49.275 32.2274 49.2572 32.1803L49.0782 31.7132C49.0662 31.6824 49.0681 31.6477 49.0825 31.6179C49.0975 31.588 49.1234 31.5659 49.1557 31.5562C49.4438 31.4705 49.6781 31.2933 49.8518 31.0285C49.9841 30.8267 50.0298 30.6471 50.0303 30.6452C50.0389 30.6115 50.0615 30.5826 50.0928 30.5672C50.1097 30.559 50.1279 30.5547 50.1462 30.5547C50.1621 30.5547 50.1775 30.5576 50.1924 30.5643L50.6398 30.7545C50.6937 30.7771 50.723 30.8359 50.7091 30.8927C50.5994 31.3386 50.3382 31.7151 49.9543 31.9819C49.6651 32.1827 49.4087 32.2506 49.3981 32.2534C49.388 32.2559 49.3779 32.2573 49.3683 32.2573V32.2568Z"
      fill="#FFC78D"
    />
    <path
      d="M50.1555 30.7178L50.6029 30.908C50.3513 31.9288 49.3776 32.1816 49.3776 32.1816L49.1987 31.7145C49.9679 31.4863 50.1551 30.7178 50.1551 30.7178M50.1536 30.4785C50.1175 30.4785 50.081 30.4867 50.0478 30.5036C49.9852 30.5353 49.9405 30.5917 49.9241 30.66C49.9213 30.6706 49.877 30.8329 49.7572 31.0135C49.5999 31.2499 49.3892 31.4088 49.1313 31.4854C49.0678 31.5041 49.0149 31.5489 48.9855 31.6086C48.9562 31.6683 48.9528 31.7372 48.9769 31.7993L49.1558 32.2664C49.1919 32.3603 49.2814 32.4195 49.3781 32.4195C49.3978 32.4195 49.418 32.4171 49.4382 32.4118C49.4498 32.4089 49.7264 32.3352 50.0324 32.1229C50.4403 31.8397 50.7179 31.4391 50.8348 30.9644C50.8627 30.8507 50.804 30.7337 50.6968 30.688L50.2494 30.4978C50.219 30.4848 50.1868 30.4785 50.1546 30.4785H50.1536Z"
      fill="#64350F"
    />
    <g opacity="0.4">
      <path
        d="M32.9922 21.75L36.2717 22.2956L39.7668 21.6527L36.2289 20.8828L32.9922 21.5892V21.75Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M36.5703 46.5533L47.1075 41.6082V33.3301L36.6069 35.3177L36.5703 46.5533Z"
        fill="#232323"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M47.1856 33.1953L46.1744 33.4163C46.1744 33.4163 46.515 41.4411 36.9005 45.6818C36.8159 45.716 36.7326 45.7502 36.6508 45.7839L36.6484 46.484L47.1856 41.394V33.1958V33.1953Z"
        fill="#232323"
      />
    </g>
  </svg>
)

export const Svg = {
  GiftOpen,
  Gift,
  GiftSuccess,
  GiftFail,
  GiftGroup,
}
