import { useEffect } from 'react'

import { PageTitles } from 'common/enums/PageTitles'
import BaseBridge from 'config/bridge/BaseBridge'
import useBoundState from 'store'
import { typeIcons } from 'store/navbar/types'

interface NavBarProps {
  navbarTitle: PageTitles
  actionIcons: typeIcons
  backListener?: () => void
  backButtonCallback?: () => void
  helpButtonCallback?: () => void
  backTicName?: string
}

export const useNavBar = ({
  navbarTitle,
  actionIcons,
  backListener,
  backButtonCallback,
  helpButtonCallback,
  backTicName,
}: NavBarProps) => {
  const setNavbarTitle = useBoundState((state) => state.setNavbarTitle)
  const setActionIcon = useBoundState((state) => state.setActionIcons)
  const setBackListener = useBoundState((state) => state.setBackListener)
  const setBackButtonCallback = useBoundState((state) => state.setBackButtonCallback)
  const setHelpButtonCallback = useBoundState((state) => state.setHelpButtonCallback)
  const setBackTicName = useBoundState((state) => state.setBackTicName)

  useEffect(() => {
    setNavbarTitle(navbarTitle)

    if (backButtonCallback) {
      setBackButtonCallback(backButtonCallback)
    }

    if (helpButtonCallback) {
      setHelpButtonCallback(helpButtonCallback)
    }

    if (backListener) {
      setBackListener(backListener)
      BaseBridge.addBackListener(backListener)
    }

    if (actionIcons) {
      setActionIcon(actionIcons)
    }

    if (backTicName) {
      setBackTicName(backTicName)
    }
  }, [])
}
