import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'

export const AnalyticsTicketDetailsHeader = (refFigma: string, actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.HEADER, {
    ref_figma: refFigma,
    screen: 'raspadinha detalhes',
    content_action: 'toque',
    action_id: actionId,
    product: 'capitalização',
  })
}

export const AnalyticsTicketDetailsAccordion = (status: string, result: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '35',
    screen: 'raspadinha detalhes',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: 'ticket',
    status,
    result,
  })
}

export const AnalyticsTicketDetailsRaffle = (itemName: string, position: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '38',
    screen: 'raspadinha detalhes',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: 'sorteios',
    item_name: itemName,
    position,
  })
}

export const AnalyticsTicketDetailsActionButtons = (refFigma: string, actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: refFigma,
    screen: 'raspadinha detalhes',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: actionId,
  })
}

export const AnalyticsTicketRaffleDetails = (
  actionId: string,
  value: string,
  luckyNumber: string,
  result?: string,
) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '54',
    screen: 'detalhes',
    ref_type: 'feedback',
    flow: 'raspadinha',
    content_action: 'dado carregado',
    action_id: actionId,
    value,
    lucky_number: luckyNumber,
    result: result ?? '',
  })
}

export const AnalyticsTicketRaffleDetailsWin = (
  actionId: string,
  value: string,
  luckyNumber: string,
) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '56',
    screen: 'detalhes',
    ref_type: 'feedback',
    flow: 'raspadinha',
    content_action: 'dado carregado',
    action_id: actionId,
    value,
    lucky_number: luckyNumber,
    currency: 'BRL',
  })
}
