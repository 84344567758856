import BaseBridge from 'config/bridge/BaseBridge'
import NewRelicBrowser from 'new-relic-browser'

import packageJson from '../../../package.json'

// A variável newrelic deve possuir o mesmo nome da variável definida no script do NewRelic
declare const newrelic: typeof NewRelicBrowser

const newrelicOn = true

class NewRelicUtils {
  static setCustomAttribute(name: string, value: string | number): void {
    if (newrelicOn && newrelic) {
      newrelic.setCustomAttribute(name, value)
    }
  }

  static noticeError(error: Error, customAttributes?: { [key: string]: string | number }) {
    if (newrelicOn && newrelic && process.env.REACT_APP_NEW_RELIC_LOG === 'true') {
      const { errorCodeRef, errorLogRef } = error as unknown as {
        errorCodeRef: string
        errorLogRef: string
      }

      const attributes = { errorCodeRef, errorLogRef, ...customAttributes }

      newrelic.noticeError(error, attributes)
    }
  }

  static setInitSettings() {
    if (newrelicOn && newrelic && process.env.REACT_APP_NEW_RELIC_LOG === 'true') {
      const spaVersion = packageJson?.version || 'unknown'

      NewRelicUtils.setCustomAttribute('spaVersion', spaVersion)

      BaseBridge.getAppInfo().then((resp) => {
        const deviceName =
          (resp as unknown as { nomeAparelho: string })?.nomeAparelho ||
          (resp as unknown as { deviceName: string })?.deviceName ||
          'unknown'
        const superAppVersion =
          (resp as unknown as { versao: string })?.versao ||
          (resp as unknown as { version: string })?.version ||
          'unknown'

        const deviceType =
          (resp as unknown as { nomeTipo: string })?.nomeTipo ||
          (resp as unknown as { deviceType: string })?.deviceType ||
          'unknown'

        NewRelicUtils.setCustomAttribute('deviceType', deviceType)

        NewRelicUtils.setCustomAttribute('deviceName', deviceName)

        NewRelicUtils.setCustomAttribute('superAppVersion', superAppVersion)
      })

      BaseBridge.getUserInfo().then((resp) => {
        NewRelicUtils.setCustomAttribute('userAccount', resp.account)
      })
    }
  }
}

export default NewRelicUtils
