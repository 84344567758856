import { ListDescription, Separator } from 'components'
import styled from 'styled-components'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import { uuid } from '@interco/lib-util'

const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);

  overflow: hidden;
`

const ShimmerHeader = styled.div`
  padding: 24px 0 32px;
`

const ShimmerBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`

const ShimmerFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > div {
    padding: 0 24px;
  }
`

export const HomeShimmer = () => (
  <ShimmerContainer>
    <ShimmerHeader>
      <Flex direction="column">
        <Skeleton width="100%" height="224px" />
      </Flex>
    </ShimmerHeader>

    <ShimmerBody>
      <Flex direction="column" gap="16px" style={{ marginBottom: '16px' }}>
        <Skeleton width="34%" height="16px" />
        <Skeleton width="96%" height="56px" />
      </Flex>

      {Array.from({ length: 2 }).map(() => (
        <ListDescription
          key={uuid()}
          margin="0 0 16px"
          iconLeft={<Skeleton width="8px" height="8px" style={{ marginTop: '4px' }} />}
          style={{ width: '100%', alignItems: 'flex-start' }}
        >
          <Flex direction="column" gap="4px">
            <Skeleton width="80%" height="16px" />
            <Skeleton width="64%" height="16px" />
          </Flex>
        </ListDescription>
      ))}

      <Flex direction="column" gap="16px" style={{ margin: '16px 0 24px' }}>
        <Skeleton width="64%" height="16px" />
        <ListDescription
          key={uuid()}
          margin="0 0 16px"
          iconRight={<Skeleton width="24px" height="24px" />}
          withBorder
          style={{ width: '100%' }}
        >
          <Skeleton width="80%" height="16px" />
        </ListDescription>
      </Flex>
    </ShimmerBody>

    <ShimmerFooter>
      <Separator borderWidth="1px" />

      <div>
        <Skeleton width="100%" height="48px" />
        <Flex direction="column" gap="4px">
          <Skeleton width="80%" height="16px" />
          <Skeleton width="64%" height="16px" />
        </Flex>
      </div>
    </ShimmerFooter>
  </ShimmerContainer>
)
