import BaseBridge from 'config/bridge/BaseBridge'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'

import { ErrorPage } from './ErrorPage/ErrorPage'

export const ErrorView = () => {
  const history = useHistory()

  const hasError = useBoundState((state) => state.hasError)
  const errorDetails = useBoundState((state) => state.details)
  const resetError = useBoundState((state) => state.resetError)

  const goBack = () => BaseBridge.requestGoBack()

  const done = () => {
    if (errorDetails.route === TypesRoutes.REQUEST_GO_BACK) {
      goBack()
    } else if (errorDetails.route) {
      history.push(errorDetails.route)
      resetError()
    } else {
      history.goBack()
      resetError()
    }
  }

  const errorView = (
    <ErrorPage
      title={errorDetails.title}
      subTitle={errorDetails.subTitle}
      nameButton={errorDetails.nameButton}
      disabledButton={errorDetails.disabledButton}
      disabledHome={errorDetails.disabledHome}
      errorIcon={errorDetails.icon}
      goHome={goBack}
      done={done}
    />
  )

  return <>{hasError && errorView}</>
}
