import { LoadingGame } from 'components'

import { Text } from '@interco/inter-ui/components/Text'

import * as S from './ReceiptProcessing.styles'

export const ReceiptProcessing = () => (
  <S.Container>
    <S.Header>
      <Text colorWeight={500} variant="headline-h1" as="h1" semiBold>
        Estamos quase lá!
      </Text>
      <Text variant="body-3" as="p">
        Aguarde só um momento enquanto o pagamento está sendo realizado
      </Text>
    </S.Header>
    <LoadingGame />
  </S.Container>
)
