import { useEffect } from 'react'

import { ActionIconEnum } from 'common/enums/ActionIcon'
import { SectionTagTypeEnum, SectionTypeEnum } from 'common/enums/AfterSales'
import { PageTitles } from 'common/enums/PageTitles'
import { ListDescription, Separator } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/mixedRoutes'
import useBoundState from 'store'
import { IAfterSalesTicket } from 'store/afterSales/types'
import { Icons } from 'utils/icons'
import { DeepLinksEnum } from 'common/enums/DeepLink'

import { Button } from '@interco/inter-ui/components/Button'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Loading } from '@interco/inter-ui/components/Loading'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'

import { AfterSalesShimmer } from './AfterSales.shimmer'
import * as S from './AfterSales.styles'
import { AnalyticsTicketFlow, AnalyticsTicketHeader } from './AfterSales.tags'

export const AfterSales = () => {
  const history = useHistory()

  const isLoading = useBoundState((state) => state.isLoading)
  const tickets = useBoundState((state) => state.tickets)
  const getTickets = useBoundState((state) => state.getTickets)
  const getTicketDetails = useBoundState((state) => state.getTicketDetails)
  const queryParams = useBoundState((state) => state.queryParams)

  const IS_DEEP_LINK_LANDING_PAGE = queryParams === DeepLinksEnum.LANDING_PAGE

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.FAQ,
    },
    backListener: IS_DEEP_LINK_LANDING_PAGE
      ? () => history.push(TypesRoutes.LANDING_PAGE_HOME)
      : () => BaseBridge.requestGoBack(),
    backButtonCallback: () => AnalyticsTicketHeader('42', 'back'),
    helpButtonCallback: () => AnalyticsTicketHeader('43', 'ajuda'),
    backTicName: 'go_back_btn_after_sales',
  })

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'after_sales_screen_loaded',
      module: 'capitalizacao',
      actions: ['enter_after_sales'],
      params: {},
    })
  }, [])

  useEffect(() => {
    const EMPTY_TICKETS = Object.keys(tickets).length === 0

    if (EMPTY_TICKETS) {
      getTickets({
        onTransmission: (noHasAfterSales) => {
          noHasAfterSales && history.push(TypesRoutes.SUBSCRIPTION)
        },
      })
    }
  }, [])

  const chevronRight = <Icons.ChevronRight color="var(--primary500)" width={24} height={24} />

  const chooseIconLeft = (sectionTitle: SectionTypeEnum) => {
    let iconProps = { svgColor: 'var(--primary500)', bgColor: 'var(--primary100)' }
    switch (sectionTitle) {
      case SectionTypeEnum.AVAILABLE:
        break
      case SectionTypeEnum.ACTIVE:
        iconProps = { svgColor: 'var(--gray500)', bgColor: 'var(--gray100)' }
        break
      case SectionTypeEnum.COMPLETED:
        iconProps = { svgColor: 'var(--gray300)', bgColor: 'var(--gray100)' }
        break
      default:
        break
    }

    return (
      <S.IconWrap $bgColor={iconProps.bgColor}>
        <Icons.Gift color={iconProps.svgColor} width={24} height={24} />
      </S.IconWrap>
    )
  }

  const renderAvailableTicketSection = (ticketsList: IAfterSalesTicket[]) => {
    if (ticketsList && ticketsList.length > 0) {
      const sectionTitle =
        ticketsList.length === 1 ? SectionTypeEnum.AVAILABLE : SectionTypeEnum.AVAILABLE_MORE_THAN_1
      const title = ticketsList.length === 1 ? '1 Sortezinha' : `${ticketsList.length} Sortezinhas`

      // As this tag is used for 1 or more ticket, we can't inform value and luckyNumber
      AnalyticsTicketFlow(SectionTagTypeEnum.AVAILABLE, '-', '-', '')

      const ticketListDescription = (
        <ListDescription
          margin="16px 0 0"
          iconLeft={chooseIconLeft(SectionTypeEnum.AVAILABLE)}
          iconRight={chevronRight}
          withBorder
          onClick={() => history.push(TypesRoutes.START)}
        >
          <Flex direction="column">
            <Text variant="body-2" colorWeight={500} bold>
              {title}
            </Text>
          </Flex>
        </ListDescription>
      )

      const processingTicketListDescription = (
        <ListDescription
          margin="16px 0 0"
          iconLeft={
            <S.IconWrap $bgColor="var(--primary100)">
              <Loading width={24} height={24} />
            </S.IconWrap>
          }
          withBorder
        >
          <Flex direction="column">
            <Text variant="body-2" colorWeight={500} bold>
              {tickets.processing === 1 ? 'Sortezinha' : `${tickets.processing} Sortezinhas`}
            </Text>
            <Text variant="caption-1">{SectionTypeEnum.PROCESSING}</Text>
          </Flex>
        </ListDescription>
      )

      return (
        <S.Content>
          <Text variant="body-3">{sectionTitle}</Text>
          {ticketListDescription}
          {tickets.processing && processingTicketListDescription}
        </S.Content>
      )
    }

    return <></>
  }

  const renderTicketSection = (
    ticketsList: IAfterSalesTicket[],
    sectionTitle: SectionTypeEnum,
    sectionTag: SectionTagTypeEnum,
  ) => {
    if (ticketsList && ticketsList.length > 0) {
      const ticketsMap = ticketsList.map((ticket) => {
        const textWeight = sectionTitle === SectionTypeEnum.COMPLETED ? 400 : 500

        const redirect = (ticketId: string) => {
          AnalyticsTicketFlow(
            sectionTag,
            `${ticket.prizeAmount}`,
            `${ticket.luckyNumber}`,
            ticket.ticketAwarded ? 'você ganhou' : 'não foi dessa vez',
          )

          getTicketDetails({
            ticketId,
            history,
            pathname: TypesRoutes.AFTER_SALES_DETAILS,
          })
        }

        return (
          <ListDescription
            key={ticket.ticketId}
            margin="16px 0 0"
            iconLeft={chooseIconLeft(sectionTitle)}
            iconRight={chevronRight}
            withBorder
            onClick={() => redirect(ticket.ticketId)}
          >
            <Flex direction="column">
              {ticket.ticketAwarded && (
                <S.StatusTag>
                  <Tag theme="green">Você ganhou</Tag>
                </S.StatusTag>
              )}
              <Text variant="body-2" colorWeight={textWeight} bold>
                Sortezinha
              </Text>
              <Text variant="body-3" colorWeight={textWeight}>
                {`Número da sorte ${ticket.luckyNumber} `}
              </Text>
            </Flex>
          </ListDescription>
        )
      })

      return (
        <S.Content>
          <Text variant="body-3">{sectionTitle}</Text>
          {ticketsMap}
        </S.Content>
      )
    }

    return <></>
  }

  const handleRedirectSales = () => {
    history.push(TypesRoutes.LANDING_PAGE_HOME)
  }

  const renderStickFooter = (
    <>
      <Separator borderWidth="1px" />

      <S.Footer>
        <Button data-testid="pay-button" fullWidth onClick={handleRedirectSales}>
          Comprar uma Sortezinha
        </Button>
      </S.Footer>
    </>
  )

  return isLoading ? (
    <AfterSalesShimmer />
  ) : (
    <S.Container data-testid="after-sales-container">
      <S.Tickets>
        {renderAvailableTicketSection(tickets.available)}
        {renderTicketSection(tickets.active, SectionTypeEnum.ACTIVE, SectionTagTypeEnum.ACTIVE)}
        {renderTicketSection(
          tickets.completed,
          SectionTypeEnum.COMPLETED,
          SectionTagTypeEnum.COMPLETED,
        )}
      </S.Tickets>
      {/* TODO: Mostrar sempre o renderStickFooter quando prateleira for liberada */}
      {IS_DEEP_LINK_LANDING_PAGE && renderStickFooter}
    </S.Container>
  )
}
