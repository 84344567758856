import NewRelicUtils from 'config/monitoring/NewRelicUtils'

import InterWebviewBridge, {
  interWbAuth,
  interWbNavigate,
  interWbSession,
  IWbAppInfo,
  IWbISafeResponse,
  IWbTac,
  IWbTic,
  IWbUserInfo,
  WbSessionActions,
} from '@interco/inter-webview-bridge'

import BridgeMock from './BridgeMock.json'

interface IRequestISafe {
  category: string
  checkType: string
  feature: string
  value: number
}

export class BaseBridge {
  private static backNavigationHandler: (() => void) | null = null

  private static addUrlProtocol(url: string) {
    const HTTPS_PROTOCOL = 'https://'
    const HTTP_PROTOCOL = 'http://'
    if (url.startsWith(HTTPS_PROTOCOL) || url.startsWith(HTTP_PROTOCOL)) {
      return url
    }
    return HTTPS_PROTOCOL + url
  }

  static isBrowser() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'BROWSER'
  }

  static isIOS() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'IOS'
  }

  static isAndroid() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'ANDROID'
  }

  static async openDeepLink(deepLink: string) {
    if (this.isBrowser()) {
      window.open(BaseBridge.addUrlProtocol(deepLink), '_blank')?.focus()
      return
    }
    try {
      await interWbNavigate.openDeepLink(deepLink)
    } catch (e) {
      NewRelicUtils.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openDeepLink',
      })
    }
  }

  static callPhoneNumber(phoneNumber: string) {
    if (!BaseBridge.isBrowser()) {
      interWbNavigate.callPhoneNumber(phoneNumber)
    }
  }

  static async requestISafe(data: IRequestISafe) {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.requestISafe as IWbISafeResponse
    }

    try {
      return await interWbAuth.requestISafe(data.category, data.checkType, data.feature, data.value)
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.requestISafe',
      })

      return Promise.reject(error)
    }
  }

  static addBackListener(event: () => void) {
    if (!BaseBridge.isBrowser()) {
      const iWb = InterWebviewBridge.getInstance()

      if (BaseBridge.backNavigationHandler) {
        iWb.removeWebviewEventListener(WbSessionActions.GO_BACK, BaseBridge.backNavigationHandler)
      }

      iWb.addWebviewEventListener(WbSessionActions.GO_BACK, event)

      BaseBridge.backNavigationHandler = event
    }
  }

  static requestGoBack() {
    if (!BaseBridge.isBrowser()) {
      interWbNavigate.requestGoBack()
    }
  }

  static async requestBabi() {
    if (!BaseBridge.isBrowser()) {
      try {
        interWbNavigate.requestBabi()
      } catch (error) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'BaseBridge.requestBabi',
        })
      }
    }
  }

  static async getUserInfo() {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.getUserInfo as IWbUserInfo
    }

    try {
      return await interWbSession.getUserInfo()
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.getUserInfo',
      })

      return Promise.reject(error)
    }
  }

  static async getAppInfo() {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.getAppInfo as IWbAppInfo
    }

    try {
      return await interWbSession.getAppInfo()
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.getAppInfo',
      })

      return Promise.reject(error)
    }
  }

  static async openPdf(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BaseBridge.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await interWbNavigate.openPdf(title, url)
    } catch (e) {
      NewRelicUtils.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openPdf.displayPdf',
      })
    }
  }

  static requestAnalytics(name: string, params?: Record<string, string>) {
    if (!BaseBridge.isBrowser()) {
      interWbSession.requestAnalytics(name, params)
    }
  }

  static hideNativeToolbar() {
    if (!BaseBridge.isBrowser()) {
      interWbSession.editToolbar('name', false)
    }
  }

  static async ticWb(ticProps: IWbTic): Promise<void> {
    if (!this.isBrowser()) {
      await interWbSession.tic(ticProps)
    }
  }

  static async tacWb(tacProps: IWbTac): Promise<void> {
    if (!this.isBrowser()) {
      await interWbSession.tac(tacProps)
    }
  }

  static async openBrowser(url: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BaseBridge.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await interWbNavigate.openNativeBrowser(url)
    } catch (e) {
      NewRelicUtils.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openPdf.displayPdf',
      })
    }
  }
}

export default BaseBridge
