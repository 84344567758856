import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 24px;
`

export const Faq = styled.div`
  padding: 0 24px;

  div[role='region'] div {
    margin-top: 0;

    span {
      display: block;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
export const Header = styled.div<{ $expanded: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

  > :first-child {
    flex: 1;
    margin-right: 8px;
  }

  svg {
    transition: all 0.35s;
    ${(props) => props.$expanded && `transform: rotate(180deg);`}
  }
`

export const List = styled.ul`
  list-style: disc;
  padding-left: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Footer = styled.div`
  padding: 0 24px;

  button {
    margin-top: 24px;
  }
`
