import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'

export const AnalyticsTagQuestion = (contentId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.FAQ_DUVIDA, {
    screen: 'faq',
    content_id: contentId,
    flow: 'raspadinha',
    item_name: 'raspadinha',
    product: 'capitalização',
  })
}

export const AnalyticsFaqHeader = () => {
  BaseBridge.requestAnalytics(TagEventsEnum.HEADER, {
    ref_figma: '59',
    screen: 'faq',
    content_action: 'toque',
    action_id: 'back',
    flow: 'raspadinha',
    product: 'capitalização',
  })
}

export const AnalyticsFaqBackButton = () => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '60',
    screen: 'faq',
    ref_type: 'botão',
    content_action: 'toque',
    action_id: 'voltar',
    flow: 'raspadinha',
  })
}
