import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 16px;

  .slider {
    .slick-slider {
      .slick-list {
        overflow: visible;
      }

      .slick-slide {
        display: block;
        padding: 0 4px;
      }

      .slick-dots {
        position: relative;
        bottom: auto;
        margin-top: 16px;

        li {
          transition: all 0.3s linear;
          transform-origin: center;
          button {
            margin-top: 0;
          }
        }
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  button {
    margin-top: 25px;
  }
`

export const RaffleCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 276px;
  padding: 12px;
  border: 1px solid var(--gray200);
  border-radius: 8px;

  h3,
  p {
    margin-top: 8px;
  }
`
export const RaffleCardGift = styled.div<{
  $bgColorCard: string
  $giftColor: boolean
}>`
  width: 100%;
  height: 128px;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${({ $bgColorCard }) => $bgColorCard};

  svg {
    ${({ $giftColor }) => $giftColor && `filter: grayscale(1);`}
  }
`

export const HeaderWin = styled.div`
  position: relative;
  margin: 120px 0 32px;
  text-align: center;
`

export const Scratchcard = styled.div`
  position: absolute;
  bottom: 150%;
  left: 50%;
  width: 160px;
  height: 200px;
  transform: translateX(-50%);
  padding: 32px;

  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDE2MCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNDguNDY4IDUuOTIwNjVlLTA2QzE1NC44MzcgNi4xOTcxOGUtMDYgMTYwIDUuMTI4MzcgMTYwIDExLjQ1NDVMMTYwIDE4OC41NDVDMTYwIDE5NC44NzIgMTU0LjgzNyAyMDAgMTQ4LjQ2OCAyMDBIMTA3LjQ3NkMxMDEuODY0IDE5My4xNzMgOTIuMDQxNSAxODguNjQ4IDgwLjg2MTUgMTg4LjY0OEM2OS42ODE2IDE4OC42NDggNTkuODU4NiAxOTMuMTczIDU0LjI0NjkgMjAwSDExLjUzMTVDNS4xNjI4NSAyMDAgLTIuNzgzODRlLTA3IDE5NC44NzIgMCAxODguNTQ2TDEuMTY0NzRlLTA1IDExLjQ1NDVDMS4xOTI1N2UtMDUgNS4xMjgzOCA1LjE2Mjg1IC0yLjc2NTI1ZS0wNyAxMS41MzE1IDBMNTQuMjQ2OSAyLjM2ODI2ZS0wNkM1OS44NTg2IDYuODI3MDggNjkuNjgxNiAxMS4zNTIyIDgwLjg2MTUgMTEuMzUyMkM5Mi4wNDE1IDExLjM1MjIgMTAxLjg2NSA2LjgyNzA4IDEwNy40NzYgNC43MzY1MmUtMDZMMTQ4LjQ2OCA1LjkyMDY1ZS0wNloiIGZpbGw9IiNGQUI1NzMiLz4KPC9zdmc+Cg==')
    no-repeat top left;
  background-size: contain;
`
export const TagContent = styled.div`
  > div > span {
    display: flex;
    margin: 0;
  }
`

export const AlertContent = styled.div`
  > div {
    align-items: center;
    margin-top: 16px;
  }
`
