import { defaultError } from 'common/mocks/ResponseError'
import BaseBridge from 'config/bridge/BaseBridge'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import TicketDetailsService from 'service/TicketDetailsService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { sortRaffles } from 'utils/commons'
import { StateCreator } from 'zustand'

import { IRegistrationForm, ITicketDetails, TicketDetailsState } from './types'

export const createTicketDetailsSlice: StateCreator<
  TicketDetailsState,
  [],
  [],
  TicketDetailsState
> = (set) => ({
  currentTicketDetails: {} as ITicketDetails,
  registrationForm: {} as IRegistrationForm,

  getTicketDetails: async (payload) => {
    useBoundState.setState({ isLoading: true })

    await TicketDetailsService.getTicketDetails(payload.ticketId)
      .then((response) => {
        if (response.raffles.length > 0) {
          response.raffles = sortRaffles(response.raffles)
        }

        set({
          currentTicketDetails: {
            ...response,
            ticketId: payload.ticketId,
          },
        })

        redirectRoute(payload)
      })
      .catch((error) => {
        const errorDetails: ErrorDetails = {
          ...defaultError,
          route: TypesRoutes.AFTER_SALES,
        }

        errorHandling(error as Error, 'AvailableTickets.getAvailableTickets', errorDetails)
      })
      .finally(() => {
        useBoundState.setState({ isLoading: false })
      })
  },

  getUrlTitle: async (ticketId) => {
    useBoundState.setState({ isLoading: true })

    await TicketDetailsService.getUrlTitle(ticketId)
      .then((response) => {
        if (response.capitalizationPdfUrl) {
          BaseBridge.openDeepLink(response.capitalizationPdfUrl)
        }
      })
      .catch((error) => {
        const errorDetails: ErrorDetails = {
          ...defaultError,
          route: TypesRoutes.AFTER_SALES_DETAILS,
        }

        errorHandling(error as Error, 'AvailableTickets.getUrlTitle', errorDetails)
      })
      .finally(() => {
        useBoundState.setState({ isLoading: false })
      })
  },

  getRegistrationForm: async (payload) => {
    useBoundState.setState({ isLoading: true })

    await TicketDetailsService.getRegistrationForm()
      .then((response) => {
        set({ registrationForm: response })

        if (payload) redirectRoute(payload)
      })
      .catch((error) => {
        const errorDetails: ErrorDetails = {
          ...defaultError,
          route: TypesRoutes.REGISTRATION_FORM,
        }
        errorHandling(error as Error, 'AfterSalesService.getRegistrationForm', errorDetails)
      })
      .finally(() => useBoundState.setState({ isLoading: false }))
  },
})
