import styled, { keyframes } from 'styled-components'

const animateGift = keyframes`
  0% { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  100% { transform: scale(1, 1); }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 54px);
  overflow-y: scroll;
  overflow-x: hidden;

  > :first-child {
    margin: 24px 0 8px;
  }
`

export const Slider = styled.div`
  margin-top: 32px;

  .slider {
    max-width: 430px;

    .slick-slider {
      .slick-list {
        overflow: visible;
      }

      .slick-slide {
        flex-direction: column;
        transition: all 0.5s ease;
        transform: scale(0.8);
        pointer-events: none;

        svg {
          width: 93px;
          height: 100px;
          opacity: 0.24;
          transition: all 0.5s ease;
        }

        span {
          display: block;
          width: 100%;
          margin-bottom: 4px;
          color: var(--auxiliary-lime-100);
          text-align: center;
          transition: all 0.5s ease;

          &:before {
            content: '';
            display: block;
            width: 100%;
            margin: 24px 0;
            border-top: 2px dashed var(--primary500);
            opacity: 0.5;
            transition: all 0.5s ease;
          }
        }

        h3 {
          color: var(--auxiliary-lime-100);
        }

        &:after {
          content: '';
          width: 100%;
          height: 70px;
          margin-top: 48px;
          background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjA1IiBoZWlnaHQ9IjcxIiB2aWV3Qm94PSIwIDAgMjA1IDcxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBvcGFjaXR5PSIwLjA4IiBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzE3MzM0XzYyNzI4KSI+CjxwYXRoIGQ9Ik0xNjguNDI2IDEwQzE3NS4yMDMgMTAgMTgwLjY5NiAxMS4wMTQ2IDE4MC42OTYgMTIuMjY2MUwxOTQuNDUzIDU4LjczMzlDMTk0LjQ1MyA1OS45ODU0IDE4OC45NiA2MSAxODIuMTgzIDYxSDEzMS42ODhDMTI1LjcxNyA1OS42NDk0IDExNS4yNjYgNTguNzU0MSAxMDMuMzcgNTguNzU0MUM5MS40NzQyIDU4Ljc1NDEgODEuMDIyMyA1OS42NDk0IDc1LjA1MTQgNjFIMjIuNzIyOUMxNS45NDY1IDYxIDEwLjQ1MzEgNTkuOTg1NCAxMC40NTMxIDU4LjczMzlMMjQuMjEwMSAxMi4yNjYxQzI0LjIxMDEgMTEuMDE0NiAyOS43MDM1IDEwIDM2LjQ3OTkgMTBMNzUuMDUxMyAxMEM4MS4wMjIzIDExLjM1MDYgOTEuNDc0MiAxMi4yNDU5IDEwMy4zNyAxMi4yNDU5QzExNS4yNjYgMTIuMjQ1OSAxMjUuNzE3IDExLjM1MDYgMTMxLjY4OCAxMEwxNjguNDI2IDEwWiIgZmlsbD0iIzZBNkM3MiIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2ZfMTczMzRfNjI3MjgiIHg9IjAuNDUzMTI1IiB5PSIwIiB3aWR0aD0iMjA0IiBoZWlnaHQ9IjcxIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9InNoYXBlIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjUiIHJlc3VsdD0iZWZmZWN0MV9mb3JlZ3JvdW5kQmx1cl8xNzMzNF82MjcyOCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K')
            no-repeat top center;
          background-size: contain;
          transition: all 0.5s ease;
          transform: scale(0);
          opacity: 0;
        }
      }

      .slick-active {
        pointer-events: auto;
        transform: scale(1);

        > div > div > div {
          background-size: cover;
        }

        svg {
          animation: ${animateGift} 1s infinite alternate;
          opacity: 1;
        }

        &:after {
          opacity: 1;
          transform: scale(1);
        }

        span::before {
          border-top: 2px dashed var(--primary400);
        }
      }

      .slick-dots {
        bottom: 74px;

        li {
          width: 24px;
          height: 2px;
          background-color: var(--carousel-color-no-active-dots);

          &.slick-active {
            width: 24px;
          }
        }
      }
    }
  }
`

export const SliderContent = styled.div`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 198px;
    height: 315px;
  }
`

export const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 0 24px 24px;
`
