import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;
`

export const Header = styled.div`
  margin-top: 24px;

  > :first-child {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }
`

export const Body = styled.div`
  margin-top: 24px;
`
export const List = styled.ul`
  margin: 16px 0;
  padding-left: 16px;
  list-style-type: auto;

  ul {
    padding-left: 16px;
    list-style-type: disc;
  }

  li {
    margin-bottom: 8px;
  }
`
export const Footer = styled.div`
  margin-top: 24px;

  span {
    display: block;
    margin-bottom: 16px;
  }

  i {
    display: block;
  }
`
