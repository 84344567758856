import styled from 'styled-components'

export const ErrorContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--neutral-theme);
  z-index: 999;
  text-align: center;
`
export const Container = styled.div`
  height: 100%;
  margin: 0 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-top: 18px;
    font-weight: 600;
  }

  .foot-button {
    margin: auto 0 24px;
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  h1 {
    margin: 24px 0 8px;
    font-weight: 600;
  }
`

export const BtnBack = styled.button`
  position: absolute;
  top: 16px;
  right: 24px;
  background-color: transparent;
`
