import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'

export const AnalyticsTicketHeader = (refFigma: string, actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.HEADER, {
    ref_figma: refFigma,
    screen: 'tickets',
    content_action: 'toque',
    action_id: actionId,
    product: 'capitalização',
  })
}

export const AnalyticsTicketFlow = (
  status: string,
  value: string,
  luckyNumber: string,
  result: string,
) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '46',
    screen: 'tickets',
    ref_type: 'botão',
    content_action: 'toque',
    action_id: 'ticket',
    flow: 'raspadinha',
    status,
    value,
    lucky_number: luckyNumber,
    result,
  })
}
