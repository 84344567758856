import React from 'react'

import { ErrorIconEnum } from 'common/enums/ErrorIcon'
import useBoundState from 'store'
import { AlertSign, ErrorSign } from 'styles/commons'
import { Icons } from 'utils/icons'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { BtnBack, Container, Content, ErrorContainer } from './ErrorPage.styles'

interface ErrorPageProps {
  title: string | JSX.Element
  subTitle: string | JSX.Element
  nameButton?: string
  disabledButton?: boolean
  disabledHome?: boolean
  errorIcon?: ErrorIconEnum
  goHome: () => void
  done: () => void
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  title,
  subTitle,
  nameButton,
  disabledButton,
  disabledHome,
  errorIcon = ErrorIconEnum.ERROR,
  goHome,
  done,
}) => {
  const navbarTitle = useBoundState((state) => state.title)
  const homeIcon = <Icons.Home width={24} height={24} color="var(--primary500)" />

  const errorIconDictionary: Record<ErrorIconEnum, React.ReactElement> = {
    [ErrorIconEnum.WARNING]: (
      <AlertSign>
        <Icons.Warning width={32} height={32} color="var(--neutral-theme)" />
      </AlertSign>
    ),
    [ErrorIconEnum.ERROR]: (
      <ErrorSign>
        <Icons.Canceled width={32} height={32} color="var(--neutral-theme)" />
      </ErrorSign>
    ),
  }

  const button = (
    <Button className="foot-button" data-testid="done-button" onClick={done} variant="primary">
      {nameButton || 'Entendi'}
    </Button>
  )

  return (
    <ErrorContainer data-testid="error-container">
      <Container>
        <BtnBack
          type="button"
          onClick={goHome}
          aria-label="Botão voltar erro"
          data-testid="home-button"
        >
          {!disabledHome && homeIcon}
        </BtnBack>
        <Text variant="headline-h3" as="h3">
          {navbarTitle}
        </Text>
        <Content>
          {errorIconDictionary[errorIcon]}
          <Text variant="headline-h1" as="h1">
            {title}
          </Text>
          <Text variant="body-3">{subTitle}</Text>
        </Content>
        {!disabledButton && button}
      </Container>
    </ErrorContainer>
  )
}
