import { rafflesEnum } from 'common/enums/AfterSales'
import { IRaffle } from 'components/RafflesCarousel/RafflesCarousel.types'

import { formatToBrazilianDate } from '@interco/lib-util'

export const convertCurrentDateTimeToString = (strTimestamp: string) =>
  formatToBrazilianDate(new Date(strTimestamp))

export const convertYearMonthDayToString = (dateString: string) => {
  const dateArr = dateString.split('-')
  return dateArr.length === 3 ? `${`${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`}` : ''
}

/**
 * Converte uma data para o formato brasileiro (dd/mm/yyyy) e adiciona o dia da semana com a primeira letra maiúscula e sem o sufixo "feira".
 * @param str - A string de data no formato ISO (yyyy-mm-dd).
 * @returns A string formatada com a data no formato brasileiro e o dia da semana.
 */
export const formatWeekDayDate = (str: string): string => {
  const date = new Date(str)

  // Formata o dia da semana
  let weekDay = date.toLocaleDateString('pt-BR', {
    weekday: 'long',
  })

  // Remove o sufixo "feira" dos dias da semana
  weekDay = weekDay.replace('-feira', '')

  // Converte a primeira letra para maiúscula
  weekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1)

  // Formata a data
  const formattedDate = date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

  // Combina o dia da semana e a data formatada
  const localeDate = `${weekDay}, ${formattedDate}`

  return localeDate
}

/**
 * Define o scroll no topo da página.
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export const sortRaffles = (raffles: IRaffle[]) => {
  const nextRaffleIndex = raffles.findIndex((raffleId) => raffleId.status === rafflesEnum.NEXT)

  let sortedRaffles = raffles

  if (nextRaffleIndex > 1) {
    sortedRaffles = [
      ...raffles.slice(nextRaffleIndex - 1),
      ...raffles.slice(0, nextRaffleIndex - 1),
    ]
  }
  return sortedRaffles
}

/**
 * Converte a primeira letra de cada palavra em maiúscula, exceto palavras com uma ou duas letras.
 * @param str - A string a ser convertida.
 * @returns A string com a primeira letra de cada palavra em maiúscula, exceto palavras com uma ou duas letras.
 */
export const capitalizeWords = (str: string): string => {
  try {
    const words = str
      .toLowerCase()
      .split(' ')
      .map((word) => (word.length > 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
      .join(' ')

    return words
  } catch {
    return str
  }
}

/**
 * Adiciona máscara de CPF a uma string.
 * @param cpf - A string a ser mascarada.
 * @returns A string formatada como CPF ou a string original se não for válida.
 */
export const maskCPF = (cpf: string): string => {
  const cpfPattern = /^\d{11}$/
  if (!cpfPattern.test(cpf)) {
    return cpf // Retorna a string original se não for um CPF válido
  }

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}
