import React, { useRef, useEffect, useCallback, memo } from 'react'

import { Icons } from 'utils/icons'

import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'

import * as S from './CustomAccordion.styles'

export const ChevronUpIcon = () => (
  <Icons.ChevronUp data-testid="icon-up" height={25} width={25} color="var(--primary500)" />
)

export const ChevronDownIcon = () => (
  <Icons.ChevronDown data-testid="icon-down" height={25} width={25} color="var(--primary500)" />
)

export type VariantProps = 'default' | 'containerized'

type Timeout = ReturnType<typeof setTimeout>

type CommonProps = {
  expanded: boolean
  onClick: () => void
  variant?: VariantProps
}

type DefaultAccordionProps = {
  labelComponent?: never
  title: string
  maxHeightWhenClosed?: string
}

type CustomAccordionProps = {
  labelComponent: React.ReactNode
  title?: never
  backgroundColor?: string
  maxHeightWhenClosed?: string
}

export type AccordionProps = (Omit<DefaultHTMLAttrs, 'onChange'> & CommonProps) &
  (DefaultAccordionProps | CustomAccordionProps)

export const Component = ({
  children,
  expanded = false,
  title,
  onClick,
  labelComponent,
  variant = 'default',
  maxHeightWhenClosed,
  ...attrs
}: PropsWithRequiredChildren<AccordionProps>) => {
  const detailsRef = useRef<HTMLDivElement>(null)
  const timeout = useRef<Timeout>()
  const DEFAULT_ACCORDION_TRANSITION_DELAY = 300

  const handleClick = useCallback(() => {
    timeout.current && clearTimeout(timeout.current)
    onClick()
  }, [onClick])

  const changeDetailsMaxHeight = useCallback(
    (
      detailsElement: HTMLDivElement,
      maxHeight: string,
      delay = DEFAULT_ACCORDION_TRANSITION_DELAY,
    ) => {
      const { style } = detailsElement
      style.maxHeight = `${detailsElement.scrollHeight}px`
      return setTimeout(() => {
        style.maxHeight = maxHeight
      }, delay)
    },
    [],
  )

  useEffect(() => {
    const detailsElement = detailsRef.current as HTMLDivElement

    if (expanded) {
      timeout.current = changeDetailsMaxHeight(detailsElement, 'inherit')
    } else if (detailsElement.style.maxHeight !== '') {
      timeout.current = changeDetailsMaxHeight(detailsElement, maxHeightWhenClosed || '0px', 50)
    }
  }, [changeDetailsMaxHeight, expanded, maxHeightWhenClosed])

  return (
    <S.Container variant={variant} {...attrs}>
      {title && (
        <S.Summary onClick={handleClick} role="button">
          <Text variant="body-3" bold colorWeight={500}>
            {title}
          </Text>
          <Spacing ml="xs">{expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</Spacing>
        </S.Summary>
      )}

      {labelComponent && (
        <S.CustomLabelContainer onClick={handleClick} role="button">
          {labelComponent}
        </S.CustomLabelContainer>
      )}

      <S.Details ref={detailsRef} $maxHeightWhenClosed={maxHeightWhenClosed} role="region">
        <S.DetailPanel variant={variant}>{children}</S.DetailPanel>
      </S.Details>
    </S.Container>
  )
}
export const CustomAccordion = memo(Component)
