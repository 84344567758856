import { defaultError } from 'common/mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import AfterSalesService from 'service/AfterSalesService'
import EligibilityService from 'service/EligibilityService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { LandingPageState } from './types'

export const createLandingPageSlice: StateCreator<
  LandingPageState,
  [],
  [],
  LandingPageState
> = () => ({
  getLandingPage: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const eligibilityPromise = EligibilityService.getEligibility()
    const afterSalesPromise = AfterSalesService.getTickets()

    try {
      const [eligibilityResponse, afterSalesResponse] = await Promise.all([
        eligibilityPromise,
        afterSalesPromise,
      ])

      useBoundState.setState({
        eligibility: eligibilityResponse,
        tickets: afterSalesResponse,
      })

      redirectRoute(payload)
    } catch (error) {
      const errorDetails: ErrorDetails = {
        ...defaultError,
        route: TypesRoutes.REQUEST_GO_BACK,
      }

      errorHandling(
        error as Error,
        'EligibilityService/AfterSalesService.getLandingPage',
        errorDetails,
      )
    } finally {
      useBoundState.getState().setIsLoading(false)
    }
  },
})
