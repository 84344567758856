import { useCallback, useEffect } from 'react'

import { DeepLinksEnum } from 'common/enums/DeepLink'
import { PageTitles } from 'common/enums/PageTitles'
import { LoadingGame } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'
import { HomeShimmer } from 'pages/landingPage/Home/Home.shimmer'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { PathQueryParams } from 'utils/pathQueryParams'

export const Start = () => {
  const history = useHistory()
  const handlePathQueryParams = PathQueryParams()

  const queryParams = useBoundState((state) => state.queryParams)
  const tickets = useBoundState((state) => state.tickets)

  const getAvailableTickets = useBoundState((state) => state.getAvailableTickets)
  const getProcessingTickets = useBoundState((state) => state.getProcessingTickets)

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {},
  })

  const HAS_TICKETS_STATE = Object.keys(tickets).length !== 0

  const navigateTo = useCallback(
    (route: TypesRoutes) => {
      history.push(route)
    },
    [history],
  )

  const handleGame = useCallback(() => {
    BaseBridge.ticWb({
      name: 'load_game',
      module: 'capitalizacao',
      action: 'enter_game',
      params: {},
    })

    navigateTo(TypesRoutes.GAME)
  }, [navigateTo])

  const handleMyTickets = useCallback(() => {
    BaseBridge.ticWb({
      name: 'load_carousel',
      module: 'capitalizacao',
      action: 'enter_tickets_carousel',
      params: {},
    })

    navigateTo(TypesRoutes.MY_TICKETS)
  }, [navigateTo])

  const handleTransmission = useCallback(
    (hasOneMoreTickets: boolean) => {
      hasOneMoreTickets ? handleMyTickets() : handleGame()
    },
    [handleGame, handleMyTickets],
  )

  const openApplication = useCallback(() => {
    const getTickets = HAS_TICKETS_STATE ? getAvailableTickets : getProcessingTickets

    getTickets({ onTransmission: handleTransmission })
  }, [HAS_TICKETS_STATE, getAvailableTickets, getProcessingTickets, handleTransmission])

  useEffect(() => {
    if (!handlePathQueryParams()) {
      openApplication()
    }
  }, [])

  return queryParams === DeepLinksEnum.LANDING_PAGE ? <HomeShimmer /> : <LoadingGame />
}
