import ArrowLeft from '@interco/icons/core/action-navigation/ic_arrow_left'
import ChevronDown from '@interco/icons/core/action-navigation/ic_chevron_down'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import ChevronUp from '@interco/icons/core/action-navigation/ic_chevron_up'
import Clock from '@interco/icons/core/action-navigation/ic_clock'
import Edit from '@interco/icons/core/action-navigation/ic_edit'
import Home from '@interco/icons/core/action-navigation/ic_house'
import CreditCard from '@interco/icons/core/finance/ic_credit_card'
import Money from '@interco/icons/core/finance/ic_money_check'
import Wallet from '@interco/icons/core/finance/ic_wallet'
import Loading from '@interco/icons/core/misc/ic_loading'
import Gift from '@interco/icons/core/shopping/ic_gift'
import Ticket from '@interco/icons/core/shopping/ic_ticket_vertical'
import CheckCircle from '@interco/icons/core/status/ic_check_circle'
import Canceled from '@interco/icons/core/status/ic_close_circle'
import Help from '@interco/icons/core/status/ic_help_circle'
import Pending from '@interco/icons/core/status/ic_info_circle_fill'
import Warning from '@interco/icons/core/status/ic_warning_circle'

export const Icons = {
  ArrowLeft,
  Canceled,
  CreditCard,
  ChevronRight,
  ChevronDown,
  CheckCircle,
  ChevronUp,
  Clock,
  Edit,
  Gift,
  Home,
  Help,
  Loading,
  Money,
  Pending,
  Wallet,
  Warning,
  Ticket,
}
