import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { IRegistrationForm, ITicketDetails, IUrlTitle } from 'store/ticketDetails/types'

export default class TicketDetailsService {
  static async getTicketDetails(ticketId: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    }

    const response = await BaseService.doExecute<ITicketDetails>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/tickets/details?ticketId=${ticketId}`,
      headers,
    })

    return response.data
  }

  static async getUrlTitle(ticketId: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    }

    const response = await BaseService.doExecute<IUrlTitle>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/tickets/generate-url?ticketId=${ticketId}`,
      headers,
    })

    return response.data
  }

  static async getRegistrationForm() {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    }

    const response = await BaseService.doExecute<IRegistrationForm>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/registration-form/profile`,
      headers,
    })

    return response.data
  }
}
