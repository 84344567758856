import { create } from 'zustand'

import { createAfterSalesSlice } from './afterSales'
import { AfterSalesState } from './afterSales/types'
import { createCheckoutSlice } from './checkout'
import { CheckoutState } from './checkout/types'
import { createCompleteScratchcardSlice } from './completeScratchcard'
import { CompleteScratchcardState } from './completeScratchcard/types'
import { createEligibilitySlice } from './eligibility'
import { EligibilityState } from './eligibility/types'
import { createErrorSlice } from './error'
import { ErrorState } from './error/types'
import { createLandingPageSlice } from './landingPage'
import { LandingPageState } from './landingPage/types'
import { createLoadingSlice } from './loading'
import { LoadingState } from './loading/types'
import { createNavbarSlice } from './navbar'
import { NavbarState } from './navbar/types'
import { createPageTransitionSlice } from './pageTransition'
import { PageTransitionState } from './pageTransition/types'
import { createQueryParamsStateSlice } from './queryParams'
import { QueryParamsState } from './queryParams/types'
import { createTicketDetailsSlice } from './ticketDetails'
import { TicketDetailsState } from './ticketDetails/types'
import { createTicketsSlice } from './tickets'
import { TicketsState } from './tickets/types'

type RootState = LoadingState &
  ErrorState &
  NavbarState &
  PageTransitionState &
  TicketsState &
  TicketDetailsState &
  AfterSalesState &
  CheckoutState &
  CompleteScratchcardState &
  QueryParamsState &
  EligibilityState &
  LandingPageState

const useBoundState = create<RootState>()((...a) => ({
  ...createErrorSlice(...a),
  ...createLoadingSlice(...a),
  ...createNavbarSlice(...a),
  ...createPageTransitionSlice(...a),
  ...createTicketDetailsSlice(...a),
  ...createTicketsSlice(...a),
  ...createAfterSalesSlice(...a),
  ...createCheckoutSlice(...a),
  ...createCompleteScratchcardSlice(...a),
  ...createQueryParamsStateSlice(...a),
  ...createEligibilitySlice(...a),
  ...createLandingPageSlice(...a),
}))

export default useBoundState
