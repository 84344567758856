import { useCallback, useEffect, useState } from 'react'

import { PageDirections } from 'common/enums/pageDirections'
import NewRelicUtils from 'config/monitoring/NewRelicUtils'
import { FallbackError } from 'pages/shared/Error/FallbackError'
import { ErrorBoundary } from 'react-error-boundary'
import { Route, Switch, useHistory, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import useBoundState from 'store'
import { scrollToTop } from 'utils/commons'

import { mixedRoutes, TypesRoutes } from './mixedRoutes/mixedRoutes'

const Routes = () => {
  const history = useHistory()

  const goTo = useBoundState((state) => state.direction)

  const [direction, setDirection] = useState('pageSliderLeft')

  const scrollTop = () => {
    scrollToTop()
  }

  useEffect(() => {
    setDirection(goTo === PageDirections.BACKWARD ? 'pageSliderRight' : 'pageSliderLeft')
  }, [goTo])

  useEffect(() => {
    const checkPath = () => {
      const exceptionPaths = [
        TypesRoutes.START,
        TypesRoutes.REGISTRATION_FORM,
        TypesRoutes.AFTER_SALES,
        TypesRoutes.LANDING_PAGE_HOME,
      ]

      const { pathname } = history.location

      if (!exceptionPaths.includes(pathname as TypesRoutes)) {
        history.push(TypesRoutes.START)
      }
    }

    checkPath()
  }, [history])

  const onErrorBoundary = useCallback((error: Error, info: { componentStack: string }) => {
    NewRelicUtils.noticeError(error, {
      errorCodeRef: info.componentStack,
    })
  }, [])

  const goBackToHistory = useCallback(() => {
    history.goBack()
    history.go(-2)
  }, [history])

  return (
    <ErrorBoundary
      FallbackComponent={FallbackError}
      onReset={goBackToHistory}
      onError={onErrorBoundary}
    >
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              onEnter={scrollTop}
              timeout={500}
              key={location.key}
              classNames={direction}
            >
              <Switch location={location}>
                {mixedRoutes.map((page) => (
                  <Route
                    key={location.pathname}
                    path={page.route}
                    exact
                    component={page.component}
                  />
                ))}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </ErrorBoundary>
  )
}

export default withRouter(Routes)
