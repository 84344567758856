import { ErrorIconEnum } from 'common/enums/ErrorIcon'
import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'
import NewRelicUtils from 'config/monitoring/NewRelicUtils'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import TicketService from 'service/TicketService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { handleProcessingErrors } from './handleProcessingErrors'
import {
  IAvailableTicket,
  IAvailableTicketRequest,
  ICompleteTicketResponse,
  IProcessingTicketsResponse,
  IScratchRequest,
  TicketsState,
} from './types'

export const createTicketsSlice: StateCreator<TicketsState, [], [], TicketsState> = (set) => ({
  availableTickets: [],
  currentTicket: {} as IAvailableTicket,
  completeTicket: {} as ICompleteTicketResponse,
  revealedPrize: 0,
  isAllScratch: false,

  getProcessingTickets: async (payload) => {
    try {
      let response = {} as IProcessingTicketsResponse
      let counter = 0
      const MAX_ATTEMPTS = 3

      do {
        response = (await TicketService.processingTickets()) as IProcessingTicketsResponse
        counter += 1

        if ((!response.status || response.status === 'PROCESSING') && counter < MAX_ATTEMPTS) {
          await new Promise((resolve) => setTimeout(resolve, 2000))
        }
      } while ((!response.status || response.status === 'PROCESSING') && counter < MAX_ATTEMPTS)

      if (!response.status || response.status === 'PROCESSING') {
        const errorDetails: ErrorDetails = {
          title: 'Sortezinha em processamento',
          subTitle: 'Volte mais tarde no menu Sortezinhas.',
          icon: ErrorIconEnum.WARNING,
          route: TypesRoutes.REQUEST_GO_BACK,
        }

        BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_ACESSO, {
          ref_figma: '8',
          screen: 'erro processamento',
          flow: 'raspadinha',
          content_action: 'dado carregado',
          action_id: 'raspadinha em processamento',
        })

        useBoundState.getState().setHasError(errorDetails)
      }

      if (response.status) {
        if (response.status === 'ACTIVE') {
          const { getAvailableTickets } = useBoundState.getState()
          getAvailableTickets(payload)
        }

        handleProcessingErrors(response.status)
      }
    } catch (error) {
      BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_ACESSO, {
        ref_figma: '6',
        screen: 'erro genérico',
        flow: 'raspadinha',
        content_action: 'dado carregado',
        action_id: 'houve um erro por aqui',
      })

      errorHandling(error as Error, 'ProcessingTickets.getProcessingTickets', undefined, true)
    }
  },

  getAvailableTickets: async (payload) => {
    useBoundState.setState({
      isLoading: true,
      isAllScratch: false,
      completeTicket: {} as ICompleteTicketResponse,
    })

    const { onTransmission } = payload as IAvailableTicketRequest

    await TicketService.availableTickets()
      .then((response) => {
        set({ availableTickets: [...response.available] })

        if (response.available.length !== 0) {
          if (response.available.length === 1) {
            set({ currentTicket: response.available[0] })
          }

          if (onTransmission) {
            onTransmission(response.available.length > 1)
          }
        } else {
          const errorDetails: ErrorDetails = {
            title: 'Sortezinha não encontrada',
            subTitle:
              'Não foi possível carregar as Sortezinhas disponíveis. Por favor, tente novamente mais tarde.',
            icon: ErrorIconEnum.WARNING,
            route: TypesRoutes.REQUEST_GO_BACK,
          }

          useBoundState.setState({ hasError: true, details: errorDetails })
        }
      })
      .catch((error) => {
        errorHandling(error as Error, 'AvailableTickets.getAvailableTickets')
      })
      .finally(() => useBoundState.setState({ isLoading: false }))
  },

  getScratchTicket: async (payload) => {
    const { ticketId, cardId } = payload as IScratchRequest

    await TicketService.scratchTicket({ ticketId, cardId }).catch((error) => {
      NewRelicUtils.noticeError(error, { errorCodeRef: 'ScratchTicket.scratchTicket' })
    })
  },

  getCompleteTicket: async () => {
    useBoundState.setState({ isLoading: true, isAllScratch: true })

    const { ticketId } = useBoundState.getState().currentTicket

    await TicketService.completeTicket(ticketId)
      .then((response) => {
        set({ completeTicket: response })
      })
      .catch((error) => {
        useBoundState.setState({ isAllScratch: false })
        errorHandling(error as Error, 'CompleteTicket.getCompleteTickets')
      })
      .finally(() => useBoundState.setState({ isLoading: false }))
  },

  setCurrentTicket: (currentTicket) => set(() => ({ currentTicket })),
  setAvailableTickets: (availableTickets) => set(() => ({ availableTickets })),
})
