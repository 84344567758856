import { useEffect, useState } from 'react'

import { Svg } from 'assets/svg/svg'
import { ScratchCard } from 'components/ScratchCard/ScratchCard'

import { Loading } from '@interco/inter-ui/components/Loading'

import * as S from './LoadingGame.styles'

export const LoadingGame = () => {
  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsAnimated(!isAnimated)
    }, 100)
  }, [])

  return (
    <S.Content data-testid="loading-game-container">
      <S.Elipse />
      <S.List className={isAnimated ? 'animated' : ''}>
        <ScratchCard scale={400}>{Svg.Gift}</ScratchCard>
        <ScratchCard scale={500}>
          {Svg.Gift}
          <S.Loading>
            <Loading width={32} height={32} color="var(--neutral-theme)" />
          </S.Loading>
        </ScratchCard>
        <ScratchCard scale={400}>{Svg.Gift}</ScratchCard>
      </S.List>
    </S.Content>
  )
}
