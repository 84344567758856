import styled from 'styled-components'

export const Container = styled.div<{
  $scale: number
}>`
  ${({ $scale }) =>
    $scale === 500 &&
    `background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEyIiBoZWlnaHQ9IjQ5NCIgdmlld0JveD0iMCAwIDMxMiA0OTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yODkuNTE0IDEuNDY2NTZlLTA1QzMwMS45MzIgMS41MzUwNmUtMDUgMzEyIDEyLjcwMzEgMzEyIDI4LjM3MzFMMzEyIDQ2NS42MjdDMzEyIDQ4MS4yOTcgMzAxLjkzMiA0OTQgMjg5LjUxNCA0OTRIMjA5LjU3OUMxOTguNjM2IDQ3Ny4wODkgMTc5LjQ4MSA0NjUuODggMTU3LjY4IDQ2NS44OEMxMzUuODc5IDQ2NS44OCAxMTYuNzI0IDQ3Ny4wODkgMTA1Ljc4MiA0OTRIMjIuNDg2NUMxMC4wNjc2IDQ5NCAtNS40Mjg0OWUtMDcgNDgxLjI5NyAwIDQ2NS42MjdMMi4yNzEyNGUtMDUgMjguMzczMkMyLjMyNTUyZS0wNSAxMi43MDMxIDEwLjA2NzYgLTYuODQ5NTllLTA3IDIyLjQ4NjUgMEwxMDUuNzgxIDUuODY2MjRlLTA2QzExNi43MjQgMTYuOTEwOCAxMzUuODc5IDI4LjExOTggMTU3LjY4IDI4LjExOThDMTc5LjQ4MSAyOC4xMTk4IDE5OC42MzYgMTYuOTEwOCAyMDkuNTc5IDEuMTczMjVlLTA1TDI4OS41MTQgMS40NjY1NmUtMDVaIiBmaWxsPSIjRUE3MTAwIi8+Cjwvc3ZnPgo=')`};

  ${({ $scale }) =>
    $scale === 400 &&
    `background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEyIiBoZWlnaHQ9IjQ5NCIgdmlld0JveD0iMCAwIDMxMiA0OTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yODkuNTE0IDEuNDY2NTZlLTA1QzMwMS45MzIgMS41MzUwNmUtMDUgMzEyIDEyLjcwMzEgMzEyIDI4LjM3MzFMMzEyIDQ2NS42MjdDMzEyIDQ4MS4yOTcgMzAxLjkzMiA0OTQgMjg5LjUxNCA0OTRIMjA5LjU3OUMxOTguNjM2IDQ3Ny4wODkgMTc5LjQ4MSA0NjUuODggMTU3LjY4IDQ2NS44OEMxMzUuODc5IDQ2NS44OCAxMTYuNzI0IDQ3Ny4wODkgMTA1Ljc4MiA0OTRIMjIuNDg2NUMxMC4wNjc2IDQ5NCAtNS40Mjg0OWUtMDcgNDgxLjI5NyAwIDQ2NS42MjdMMi4yNzEyNGUtMDUgMjguMzczMkMyLjMyNTUyZS0wNSAxMi43MDMxIDEwLjA2NzYgLTYuODQ5NTllLTA3IDIyLjQ4NjUgMEwxMDUuNzgxIDUuODY2MjRlLTA2QzExNi43MjQgMTYuOTEwOCAxMzUuODc5IDI4LjExOTggMTU3LjY4IDI4LjExOThDMTc5LjQ4MSAyOC4xMTk4IDE5OC42MzYgMTYuOTEwOCAyMDkuNTc5IDEuMTczMjVlLTA1TDI4OS41MTQgMS40NjY1NmUtMDVaIiBmaWxsPSIjRjVBMTUxIi8+Cjwvc3ZnPgo=');`};

  ${({ $scale }) =>
    $scale === 300 &&
    `background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTM3IiBoZWlnaHQ9IjIyMiIgdmlld0JveD0iMCAwIDEzNyAyMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMjcuMTE2IDUuMDkzMjZlLTA2QzEzMi41NjkgNS4zMzExNGUtMDYgMTM2Ljk4OSA0LjQxMTY5IDEzNi45ODkgOS44NTM3OEwxMzYuOTg5IDIxMS45MTFDMTM2Ljk4OSAyMTcuMzUzIDEzMi41NjkgMjIxLjc2NSAxMjcuMTE2IDIyMS43NjVIOTIuMDE5MkM4Ny4yMTQ2IDIxNS44OTIgNzguODA0MyAyMTEuOTk5IDY5LjIzMjIgMjExLjk5OUM1OS42NjAyIDIxMS45OTkgNTEuMjQ5OSAyMTUuODkyIDQ2LjQ0NTMgMjIxLjc2NUg5Ljg3MzA5QzQuNDIwMzQgMjIxLjc2NSAtMi4zODM0N2UtMDcgMjE3LjM1MyAwIDIxMS45MTFMOS45NzIyN2UtMDYgOS44NTM3OUMxLjAyMTA2ZS0wNSA0LjQxMTcgNC40MjAzNCAtMi4zNzg4MWUtMDcgOS44NzMxIDBMNDYuNDQ1MiAyLjAzNzNlLTA2QzUxLjI0OTkgNS44NzMwMSA1OS42NjAyIDkuNzY1OCA2OS4yMzIyIDkuNzY1OEM3OC44MDQzIDkuNzY1OCA4Ny4yMTQ2IDUuODczMDEgOTIuMDE5MiA0LjA3NDYxZS0wNkwxMjcuMTE2IDUuMDkzMjZlLTA2WiIgZmlsbD0iI0ZBQjU3MyIvPgo8L3N2Zz4K')`};

  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s ease;
`
