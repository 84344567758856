import { useEffect, useState } from 'react'

import { Svg } from 'assets/svg/svg'
import BaseBridge from 'config/bridge/BaseBridge'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { AnalyticsGameDeposit, AnalyticsGameFeedback } from '../Game.tags'
import * as S from './GameResult.styles'
import { IGameResultProps, IRenderStatus } from './GameResult.types'

export const GameResult = ({ ticketAwarded }: IGameResultProps) => {
  const history = useHistory()

  const isLoading = useBoundState((state) => state.isLoading)
  const { ticketId, prizeAmount } = useBoundState((state) => state.currentTicket)
  const { title, subtitle } = useBoundState((state) => state.completeTicket)
  const getCompleteScratchcard = useBoundState((state) => state.getCompleteScratchcard)

  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    ticketAwarded ? AnalyticsGameFeedback(title, `${prizeAmount}`) : AnalyticsGameFeedback(title)

    BaseBridge.addBackListener(() => {
      handleResult()
    })

    const timeOut = setTimeout(() => {
      setIsAnimated(true)
    }, 1500)

    return () => {
      clearTimeout(timeOut)
    }
  }, [])

  const renderSuccess: IRenderStatus = {
    gift: Svg.GiftSuccess,
    buttonTitle: 'Depositar na minha conta',
  }

  const renderFailure: IRenderStatus = {
    gift: Svg.GiftOpen,
    buttonTitle: 'Conferir sorteios',
  }

  const handleResult = () => {
    const actionId = ticketAwarded ? renderSuccess.buttonTitle : renderFailure.buttonTitle
    AnalyticsGameDeposit(actionId)

    BaseBridge.ticWb({
      name: 'game_result_btn',
      module: 'capitalizacao',
      action: 'enter_after_sales_details',
      params: {},
    })

    getCompleteScratchcard({
      ticketId,
      history,
      pathname: TypesRoutes.AFTER_SALES_DETAILS,
    })
  }

  return (
    <S.ResultContainer data-testid="game-result" className={isAnimated ? 'animated' : ''}>
      <S.ResultContent className={isAnimated ? 'animated' : ''}>
        <S.Scratchcard>{ticketAwarded ? renderSuccess.gift : renderFailure.gift}</S.Scratchcard>
        <Text variant="headline-h1" as="h1" semiBold>
          {title}
        </Text>
        <Text variant="body-3">{subtitle}</Text>
        <Button
          data-testid="game-result-button"
          fullWidth
          onClick={handleResult}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {ticketAwarded ? renderSuccess.buttonTitle : renderFailure.buttonTitle}
        </Button>
      </S.ResultContent>
    </S.ResultContainer>
  )
}
