import Gift from 'assets/svg/gift.svg'

import { Grid } from '@interco/inter-ui/components/Grid'

import { Card } from './Card/Card'
import { IScratchCardsGridProps } from './ScratchCardsGrid.types'

export const ScratchCardsGrid = ({ cards }: IScratchCardsGridProps) => (
  <>
    {cards && (
      <Grid data-testid="grid-scratch-cards" templateColumns="repeat(2, 1fr)" gap="8px">
        {cards.map(({ ...props }) => (
          <Card key={props.id} image={Gift} width="128px" height="128px" {...props} />
        ))}
      </Grid>
    )}
  </>
)
