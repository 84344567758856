import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'

// TODO: Fazer o gerenciamento de estado do UTM_CAMPAIGN
export const AnalyticsMyTicketsOpen = (quantity: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_ACESSO, {
    ref_figma: '11',
    screen: 'minhas raspadinhas',
    flow: 'raspadinha',
    content_action: 'dado carregado',
    utm_campaign: 'TODO',
    quantity,
  })
}

export const AnalyticsMyTicketHeader = (refFigma: string, actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.HEADER, {
    ref_figma: refFigma,
    screen: 'minhas raspadinhas',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: actionId,
    product: 'capitalização',
  })
}

export const AnalyticsMyTicketCarousel = (actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '13',
    screen: 'minhas raspadinhas',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: actionId,
  })
}

export const AnalyticsMyTicketUse = (selectedItem: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '14',
    screen: 'minhas raspadinhas',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: 'usar sortezinha',
    selected_item: selectedItem,
  })
}
