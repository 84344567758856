import styled from 'styled-components'

import { ISeparatorProps } from './Separator.types'

export const Container = styled.div<{
  $borderWidth: string
  type: ISeparatorProps['type']
  color: string
  $margin?: string
}>`
  width: 100%;
  border-top: ${(props) => `${props.$borderWidth} ${props.type} ${props.color}`};
  ${(props) => props.$margin && `margin: ${props.$margin};`}
`
