import { StateCreator } from 'zustand'

import { QueryParamsState } from './types'

export const createQueryParamsStateSlice: StateCreator<
  QueryParamsState,
  [],
  [],
  QueryParamsState
> = (set) => ({
  queryParams: '',

  setQueryParams: (queryParams) => set(() => ({ queryParams })),
  resetQueryParams: () => set(() => ({ queryParams: '' })),
})
