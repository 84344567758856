import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'

export const AnalyticsSubscriptionHeader = (refFigma: string, actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.HEADER, {
    ref_figma: refFigma,
    screen: 'sortezinhas',
    content_action: 'toque',
    action_id: actionId,
    product: 'capitalização',
  })
}

export const AnalyticsSubscriptionAccess = () => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_ACESSO, {
    ref_figma: '50',
    screen: 'sortezinhas',
    flow: 'raspadinha',
    content_action: 'dado carregado',
    action_id: 'recarregue sua sorte',
  })
}

export const AnalyticsSubscriptionConfirm = () => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '51',
    screen: 'sortezinhas',
    ref_type: 'botão',
    content_action: 'toque',
    action_id: 'quero uma sortezinha',
    flow: 'raspadinha',
  })
}
