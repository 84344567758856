import styled from 'styled-components'

export const Container = styled.div<{ $blockScroll: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
  overflow: ${({ $blockScroll }) => ($blockScroll ? 'hidden' : 'auto')};
`

export const Header = styled.div`
  margin: 24px;
  text-align: center;

  > :first-child {
    margin-bottom: 8px;
  }
`

export const ScratchCardContent = styled.div`
  margin: 0 auto 32px;

  > div {
    padding: 48px 32px;
  }

  p {
    margin-top: 16px;
    text-align: center;
    color: var(--primary200);
  }
`

export const Footer = styled.div`
  margin-top: auto;
  padding: 0 24px 24px;
`
