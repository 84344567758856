import { ListDescription } from 'components'
import styled from 'styled-components'

import { uuid } from '@interco/inter-ui'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  padding: 24px;
  overflow: hidden;
`

export const AfterSalesShimmer = () => {
  const renderCards = Array.from({ length: 2 }).map(() => (
    <ListDescription
      key={uuid()}
      margin="0 0 16px 0"
      iconLeft={<Skeleton width="40px" height="40px" />}
      iconRight={<Skeleton width="24px" height="24px" />}
      withBorder
      style={{ width: '100%' }}
    >
      <Skeleton width="100%" height="24px" />
    </ListDescription>
  ))

  return (
    <ShimmerContainer>
      <Flex direction="column" gap="16px" alignItems="flex-start">
        <Skeleton width="32%" height="16px" />

        <ListDescription
          iconLeft={<Skeleton width="40px" height="40px" />}
          iconRight={<Skeleton width="24px" height="24px" />}
          withBorder
          style={{ width: '100%' }}
        >
          <Skeleton width="100%" height="24px" />
        </ListDescription>

        <ListDescription />
      </Flex>

      <Flex direction="column" gap="16px" alignItems="flex-start" style={{ marginTop: '40px' }}>
        <Skeleton width="64%" height="16px" />

        {renderCards}

        <ListDescription />
      </Flex>

      <Flex direction="column" gap="16px" alignItems="flex-start" style={{ marginTop: '40px' }}>
        <Skeleton width="64%" height="16px" />

        {renderCards}

        <ListDescription />
      </Flex>
    </ShimmerContainer>
  )
}
