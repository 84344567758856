import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: calc(100vh - 54px);
`

export const Header = styled.div`
  margin: 24px 0;

  h2 {
    margin-top: 40px;
  }
`

export const Data = styled.div`
  margin-bottom: 24px;

  div {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const LuckCard = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  background-color: var(--gray100);
  border-radius: 8px;

  button {
    padding: 0;
  }
`

export const TagContent = styled.div`
  > div > span {
    display: flex;
    margin: 0;
  }
`

export const Footer = styled.div`
  margin-top: auto;

  button {
    margin-bottom: 16px;
  }
`
