import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import BaseBridge from 'config/bridge/BaseBridge'

export const AnalyticsGameHeader = (refFigma: string, actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.HEADER, {
    ref_figma: refFigma,
    screen: 'game',
    content_action: 'toque',
    action_id: actionId,
    product: 'capitalização',
  })
}

export const AnalyticsGameUse = (position: string, value: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '17',
    screen: 'game',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: 'raspe aqui',
    position,
    value,
    currency: 'BRL',
  })
}

export const AnalyticsGameAll = () => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '23',
    screen: 'game',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: 'raspar todos',
  })
}

export const AnalyticsGameFeedback = (actionId: string, value?: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_SUCESSO, {
    ref_figma: '30',
    screen: 'game feedback',
    ref_type: 'sucesso',
    flow: 'raspadinha',
    content_action: 'dado carregado',
    action_id: actionId,
    value: value ?? '',
    currency: 'BRL',
  })
}

export const AnalyticsGameDeposit = (actionId: string) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '31',
    screen: 'game feedback',
    ref_type: 'botão',
    flow: 'raspadinha',
    content_action: 'toque',
    action_id: actionId,
  })
}
