import { defaultError } from 'common/mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import EligibilityService from 'service/EligibilityService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { EligibilityState, IEligibility } from './types'

export const createEligibilitySlice: StateCreator<EligibilityState, [], [], EligibilityState> = (
  set,
) => ({
  eligibility: {} as IEligibility,

  getEligibility: async () => {
    useBoundState.setState({ isLoading: true })

    await EligibilityService.getEligibility()
      .then((response) => {
        set({ eligibility: response })
      })
      .catch((error) => {
        const errorDetails: ErrorDetails = {
          ...defaultError,
          route: TypesRoutes.REQUEST_GO_BACK,
        }

        errorHandling(error as Error, 'EligibilityService.getEligibility', errorDetails)
      })
      .finally(() => {
        useBoundState.setState({ isLoading: false })
      })
  },
})
